(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('PromoMaterialDialogController', PromoMaterialDialogController);

    PromoMaterialDialogController.$inject = ['$timeout', '$scope', 'AlertService', '$uibModalInstance', 'entity', 'PromoMaterial', 'ProductCategorySearch'];

    function PromoMaterialDialogController ($timeout, $scope, AlertService, $uibModalInstance, entity, PromoMaterial, ProductCategorySearch) {
        var vm = this;
        vm.promoMaterial = entity;
        vm.getProductCategory = getProductCategory;
        
        function getProductCategory(value) {
            vm.loadingProduct = true;

            return ProductCategorySearch.query({
                query: "*"+value+"*"
            }).$promise.then(function(response){
                vm.loadingProduct = false;
                return response;
            });
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sceaApp:promoMaterialUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.promoMaterial.productCategoryId = vm.selectedProduct.id;
            vm.isSaving = true;
            if (vm.promoMaterial.id !== null) {
                PromoMaterial.update(vm.promoMaterial, onSaveSuccess, onSaveError);
            } else {
                PromoMaterial.save(vm.promoMaterial, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
