(function () {
    'use_strict';

    angular
        .module('sceaApp')
        .controller('PlanogramFormController', PlanogramFormController);

    PlanogramFormController.$inject = ['$scope', '$state', '$q', 'Calendar', 'ProductCategory', 'Outlet', 'OutletSearch', 'entity', 'Planogram', '$uibModal', 'limitToFilter', 'ProductSearch'];

    function PlanogramFormController($scope, $state, $q, Calendar, ProductCategory, Outlet, OutletSearch, entity, Planogram, $uibModal, limitToFilter, ProductSearch) {
        var vm = this;

        // method
        vm.getOutlet = getOutlet;
        vm.datePickerOpenStatus = false;
        vm.openCalendar = openCalendar;
        vm.onSelectOutlet = onSelectOutlet;
        vm.onSelectFixture = onSelectFixture;
        vm.onSelectSubFixture = onSelectSubFixture;
        vm.onSelectFixtureProduct = onSelectFixtureProduct;
        vm.onSelectEquipmentCategories = onSelectEquipmentCategories;
        vm.onSelectEquipmentSubCategories = onSelectEquipmentSubCategories;
        vm.savePlanogramMaster = savePlanogramMaster;
        vm.openFixtureDialog = openFixtureDialog;
        vm.openSubFixtureDialog = openSubFixtureDialog;
        vm.openMaterialDialog = openMaterialDialog;
        vm.openImportFixtureDialog = openImportFixtureDialog;
        vm.openImportEquipmentDialog = openImportEquipmentDialog;
        vm.putFixture = putFixture;
        vm.putEquipment = putEquipment;
        vm.deleteFixture = deleteFixture;
        vm.deleteFixtureProduct = deleteFixtureProduct;
        vm.deleteEquipmentProduct = deleteEquipmentProduct;
        vm.loadEquipmentCategories = loadEquipmentCategories;
        vm.getProductFixture = getProductFixture;

        // init function
        (function () {

            // object planogram master
            vm.planogram = entity;

            // kondisi edit
            if (vm.planogram.id) {
                vm.isEditing = true;

                getOutletById(vm.planogram.outletId);

                Planogram.getFixturePlanogram({
                    id: vm.planogram.id,
                }, function (data) {
                    angular.forEach(data, function (e) {
                        e.saved = true;
                    });
                    vm.fixtures = data;
                });
            }

            vm.dateOptions = {
                dateDisabled: disabled1,
                startingDay: 1,
                minDate: new Date(),
                showButtonBar: false
            };

        })();

        function openCalendar() {
            vm.datePickerOpenStatus = true;
        }

        function openFixtureDialog() {
            $uibModal.open({
                animation: true,
                templateUrl: 'app/entities/planogram/fixture/planogram-fixture-dialog.html',
                size: 'sm',
                controller: 'PlanogramFixtureDialog',
                controllerAs: 'vm',
                resolve: {
                    resFixture: function () {
                        return vm.fixtures;
                    }
                }
            }).result.then(function (res) {
                var redRes = res
                    .reduce(function (acc, idx) {
                        if (idx.status) {
                            acc.push({
                                categoryId: idx.categoryId,
                                categoryName: idx.categoryCategoryName,
                                fixtureId: idx.id,
                                fixtureName: idx.fixtureName,
                                id: null,
                                planogramId: vm.planogram.id,
                                target: 0
                            });
                        }
                        return acc;
                    }, []);
                vm.fixtures = vm.fixtures.concat(redRes);
            }, function () {

            });
        }

        function openSubFixtureDialog() {
            $uibModal.open({
                animation: true,
                templateUrl: 'app/entities/planogram/fixture/planogram-subfixture-dialog.html',
                size: 'sm',
                controller: 'PlanogramSubFixtureDialog',
                controllerAs: 'vm',
                resolve: {
                    resFixture: function () {
                        return { id: vm.selectedFixtureId, name: vm.selectedFixtureName }
                    },
                    resSubFixture: function () {
                        return vm.subFixtures;
                    }
                }
            }).result.then(function (res) {
                var redRes = res
                    .reduce(function (acc, idx) {
                        if (idx.status) {
                            acc.push({
                                categoryId: idx.categoryId,
                                categoryName: idx.categoryCategoryName,
                                fixtureId: idx.id,
                                fixtureName: idx.fixtureName,
                                id: null,
                                planogramId: vm.planogram.id,
                                target: 0
                            });
                        }
                        return acc;
                    }, []);
                vm.subFixtures = vm.subFixtures.concat(redRes);
            }, function () {

            });
        }

        function openImportFixtureDialog() {
            $uibModal.open({
                templateUrl: 'app/entities/planogram/import/planogram-import-fixture.html',
                controller: 'PlanogramImportFixtureController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    planogramId: function () {
                        return vm.planogram.id;
                    }
                }
            }).result.then(function () {
                Planogram.getFixturePlanogram({
                    id: vm.planogram.id,
                }, function (data) {
                    angular.forEach(data, function (e) {
                        e.saved = true;
                    });
                    vm.fixtures = data;
                });
            }, function () {
                $state.go('^');
            });
        }

        function openImportEquipmentDialog() {
            $uibModal.open({
                templateUrl: 'app/entities/planogram/import/planogram-import-equipment.html',
                controller: 'PlanogramImportEquipmentController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    planogramId: function () {
                        return vm.planogram.id;
                    }
                }
            }).result.then(function () {
                loadEquipmentCategories();
            }, function () {
                $state.go('^');
            });
        }

        // load fixture categories
        function loadFixtureCategories(id) {
            Planogram.getProductCategoriesFixturePlanogram({
                id: id
            }, function (data) {
                vm.fixtureCategory = data;
            });
        }

        // load equipment categories
        function loadEquipmentCategories() {
            if (vm.planogram.id) {
                Planogram.getEquipmentsCategory({
                    id: vm.planogram.id,
                    root: true
                }, function (data) {
                    vm.equipmentCategories = data
                });

                getCategories();
            }
        }

        // disable weekend selection
        function disabled1(data) {
            var date = data.date, mode = data.mode;
            return mode === 'day'
                && (date.getDay() === 0 || date.getDay() === 2
                    || date.getDay() === 3 || date.getDay() === 4
                    || date.getDay() === 5 || date.getDay() === 6);
        }

        function getOutletById(id) {
            Outlet.get({ id: id }, function (data) {
                vm.outlet = data;
            });
        }

        // get outlet from db
        function getOutlet(value) {
            vm.loadingOutlet = true;
            return OutletSearch.query({
                query: '*' + value + '*'
            }).$promise.then(function (response) {
                vm.loadingOutlet = false;
                return limitToFilter(response, 5);
            }, function (response) {
                vm.loadingOutlet = false;
                AlertService.error(response.data.message);
            });
        }

        function getProductFixture(value) {
            var querySplit = value.split(/-|\//);
            var query = '';

            if (querySplit.length > 0) {
                query += '(';

                angular.forEach(querySplit, function (eachSplit, index) {
                    query += eachSplit;

                    if (index != querySplit.length - 1) {
                        query += ' AND '
                    }
                });

                query += '*)';
            } else {
                query = value;
            }

            vm.loadingProductFixture = true;
            return ProductSearch.query({
                query: query + ' AND deleted:false'
            }).$promise.then(function (response) {
                vm.loadingSku = false;
                var filteredResponse = response
                    .filter(function (each) {
                        var anyProducts = vm.fixtureProducts
                            .find(function (source) {
                                return source.id === each.id;
                            });
                        return !anyProducts;
                    });
                return limitToFilter(filteredResponse, 5);
            }, function (response) {
                vm.loadingSku = false;
            });
        }

        // on select outlet
        function onSelectOutlet(item) {
            vm.planogram.outletId = item.id;
            vm.planogram.outletOutletName = item.outletOutletName;
        }

        function onSelectFixture(param) {
            vm.selectedFixtureId = param.fixtureId;
            vm.selectedFixtureName = param.fixtureName;
            vm.selectedSubFixtureId = null;

            Planogram.getFixturePlanogram({
                id: vm.planogram.id,
                parentId: param.fixtureId
            }, function (data) {
                angular.forEach(data, function (e) {
                    e.saved = true;
                });
                vm.subFixtures = data;
            });
        }

        function onSelectSubFixture(id) {

            vm.selectedSubFixtureId = id;

            Planogram.getProductFixturesPlanogram({
                id: vm.planogram.id,
                fixtureId: id
            }, function (data) {
                vm.fixtureProducts = data;
            });
        }

        function onSelectFixtureProduct(item) {

            Planogram.updateProductFixture({
                id: vm.planogram.id
            }, {
                    fixtureId: vm.selectedSubFixtureId,
                    productId: item.id
                }, function (data) {
                    vm.fixtureProducts = vm.fixtureProducts.concat(item);
                    vm.productOpt = null;
                }, function (err) {
                });
        }

        function savePlanogramMaster(param) {
            vm.isSaving = true;
            if (param == 'edit') {
                vm.isEditing = false;
            } else {
                if (vm.planogram.id) {
                    Planogram.updatePlanogram(vm.planogram, onSaveSuccess, onSaveError);
                } else {
                    Planogram.savePlanogram(vm.planogram, onSaveSuccess, onSaveError);
                }
            }
        }

        // for fixture db interaction
        function putFixture(param) {
            Planogram.updatePlanogramFixture({
                id: vm.planogram.id
            }, {
                    fixtureId: param.fixtureId,
                    target: param.target
                }, function (data) {
                    param.saved = true;
                }, function (err) {
                    param.saved = false;
                })
        }

        function putEquipment(param) {
            Planogram.updatePlanogramEquipment({
                id: vm.planogram.id
            }, {
                    equipmentId: param.equipmentId,
                    target: param.target
                }, function (data) {
                    param.saved = true;
                }, function (err) {
                    param.saved = false;
                });
        }

        function deleteFixture(param, index, lvl) {
            Planogram.deletePlanogramFixture({
                id: vm.planogram.id,
                fixtureId: param.fixtureId
            }, function () {
                if (lvl === 1) {
                    vm.fixtures.splice(index, 1);
                    vm.selectedFixtureId = null;
                    vm.selectedSubFixtureId = null;
                } else {
                    vm.selectedSubFixtureId = null;
                    vm.subFixtures.splice(index, 1);
                }
            })
        }

        function deleteFixtureProduct(product, index) {
            Planogram.deleteFixtureProduct({
                id: vm.planogram.id,
                fixtureId: vm.selectedSubFixtureId,
                productId: product.id
            }, function () {
                vm.fixtureProducts.splice(index, 1);
            });

        }

        function deleteEquipmentProduct(material, index) {
            Planogram.deleteEquipmentProduct({
                id: vm.planogram.id,
                equipmentId: material.equipmentId
            }, function () {
                vm.materials.splice(index, 1);
                if(!vm.materials.length){

                    var catKeyIndex = findIndexById(vm.categoryEquipmentTree, vm.selectedCategory.id);
                    var subKeyIndex = findIndexById(vm.categoryEquipmentTree[catKeyIndex].childs, vm.selectedSubCategory.id);
                    vm.categoryEquipmentTree[catKeyIndex].childs.splice(subKeyIndex, 1);
                    vm.selectedSubCategory = null;
                    if(!vm.categoryEquipmentTree[catKeyIndex].childs.length){
                        vm.categoryEquipmentTree.splice(catKeyIndex,1);
                        vm.selectedCategory = null;
                    }
                }
            });
        }

        function onSaveSuccess(result) {
            vm.isEditing = true;
            $state.go('planogram.edit', { id: result.id });
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        /* FLAG */
        function getCategories() {
            Planogram.getEquipmentsCategory({
                id: vm.planogram.id,
            }, function (data) {
                vm.categoryEquipmentTree = generateTree(data, null);
            });
        }
        
        function getMaterials(planogramId, categoryId) {
            return Planogram.getProductEquipmentsPlanogram({
                id: planogramId,
                categoryId: categoryId
            });
        }

        function generateTree(data, parentId) {
            return data.reduce(function (result, each) {
                if (each.parentId == parentId) {
                    each.childs = generateTree(data, each.id);

                    result.push(each);
                }

                return result;
            }, []);
        }

        function onSelectEquipmentCategories(category) {
            vm.selectedCategory = category;
            vm.selectedSubCategory = null;
        }

        function onSelectEquipmentSubCategories(subCategory) {
            vm.selectedSubCategory = subCategory;

            vm.materials = getMaterials(vm.planogram.id, subCategory.id);
        }

        function findIndexById(array, id) {
            var data = array.find(function(each) {
                return each.id === id;
            });

            return array.indexOf(data);
        }

        function openMaterialDialog() {
            $uibModal.open({
                animation: true,
                templateUrl: 'app/entities/planogram/fixture/planogram-material-dialog.html',
                size: 'sm',
                controller: 'PlanogramMaterialDialog',
                controllerAs: 'vm',
                resolve: {
                    planogramId: function () {
                        return vm.planogram.id;
                    }
                }
            }).result.then(function (result) {
                var isCategoryExist = vm.categoryEquipmentTree.find(function(each) {
                        return each.id === result.category.id;
                    });

                /* push category when not found */
                isCategoryExist ||
                    vm.categoryEquipmentTree.push(result.category);

                /* set category */
                var indexCategory = findIndexById(vm.categoryEquipmentTree, result.category.id);
                
                vm.selectedCategory = vm.categoryEquipmentTree[indexCategory];

                var isSubCategoryExist = vm.selectedCategory.childs.find(function(each) {
                        return each.id === result.subCategory.id;
                    });

                /* push subCategory when not found */
                isSubCategoryExist ||
                    vm.categoryEquipmentTree[indexCategory].childs.push(result.subCategory);

                /* set subCategory */
                var indexSubCategory = findIndexById(vm.selectedCategory.childs, result.subCategory.id);
                
                vm.selectedSubCategory = vm.selectedCategory.childs[indexSubCategory];

                /* set materials */
                var existingMaterials = getMaterials(vm.planogram.id, result.subCategory.id);

                existingMaterials.$promise.then(function(data) {
                    vm.materials = [].concat(data, result.materials);
                });
            }, function () {

            });
        }
    }
})();