(function() {
    'use strict';
    angular
        .module('sceaApp')
        .factory('RisProductTarget', RisProductTarget);

    RisProductTarget.$inject = ['$resource'];

    function RisProductTarget ($resource) {
        var resourceUrl =  'api/ris-product-targets/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
