(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('PrincipalDeleteController',PrincipalDeleteController);

    PrincipalDeleteController.$inject = ['$uibModalInstance', 'entity', 'PrincipalName'];

    function PrincipalDeleteController($uibModalInstance, entity, Principal) {
        var vm = this;
        vm.principal = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            Principal.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
