(function() {
    'use strict';
    angular
        .module('sceaApp')
        .factory('ExcelReport', ExcelReport);

    ExcelReport.$inject = ['$resource'];

    function ExcelReport ($resource) {
        var resourceUrl =  'api/excel-report';

        return $resource(resourceUrl, {}, {
            'reportCallPlan': { 
            	method:'POST',
            	url: 'api/excel-report/call-plan',
            	data: '',
                headers: {
                    "Content-Type": "application/json"
                },
                responseType: 'arraybuffer',
                transformResponse: function (data, headersGetter) {
                    var contentDisposition = headersGetter('Content-Disposition');
                    var filename = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition)[1];
                    var blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"});
                    saveAs(blob, filename, true);
                    return true;
                }
            },
            'reportOtherBrandDisplay': { 
            	method:'POST',
            	url: 'api/excel-report/display-other-brand',
            	data: '',
                headers: {
                    "Content-Type": "application/json"
                },
                responseType: 'arraybuffer',
                transformResponse: function (data, headersGetter) {
                    var contentDisposition = headersGetter('Content-Disposition');
                    var filename = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition)[1];
                    var blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"});
                    saveAs(blob, filename, true);
                    return true;
                }
            },
            'reportPOP': { 
            	method:'POST',
            	url: 'api/excel-report/row-data-pop',
            	data: '',
                headers: {
                    "Content-Type": "application/json"
                },
                responseType: 'arraybuffer',
                transformResponse: function (data, headersGetter) {
                    var contentDisposition = headersGetter('Content-Disposition');
                    var filename = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition)[1];
                    var blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"});
                    saveAs(blob, filename, true);
                    return true;
                }
            },
            'reportStoreCompleteness': { 
            	method:'POST',
            	url: 'api/excel-report/store-completeness',
            	data: '',
                headers: {
                    "Content-Type": "application/json"
                },
                responseType: 'arraybuffer',
                transformResponse: function (data, headersGetter) {
                    var contentDisposition = headersGetter('Content-Disposition');
                    var filename = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition)[1];
                    var blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"});
                    saveAs(blob, filename, true);
                    return true;
                }
            },
            'exportProjectList':{
            	url: 'api/excel-report/project-instances',
                method: 'GET',
                responseType: 'arraybuffer',
                transformResponse: function (data, headersGetter) {
                    var contentDisposition = headersGetter('Content-Disposition');
                    var filename = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition)[1];
                    var blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"});
                    saveAs(blob, filename, true);
                    return true;
                }
            }
        });
    }
})();
