(function() {
    'use strict';

    angular
        .module('sceaApp')
        .factory('OtherJobBrandSearch', OtherJobBrandSearch);

    OtherJobBrandSearch.$inject = ['$resource'];

    function OtherJobBrandSearch($resource) {
        var resourceUrl =  'api/_search/other-job-brands/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
