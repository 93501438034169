(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('PopInvDialogController', PopInvDialogController);

    PopInvDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'PopInv', 'Pop'];

    function PopInvDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, PopInv, Pop) {
        var vm = this;
        vm.popInv = entity;
        vm.pops = Pop.query({filter: 'popinv-is-null'});
        $q.all([vm.popInv.$promise, vm.pops.$promise]).then(function() {
            if (!vm.popInv.popId) {
                return $q.reject();
            }
            return Pop.get({id : vm.popInv.popId}).$promise;
        }).then(function(pop) {
            vm.pops.push(pop);
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sceaApp:popInvUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.popInv.id !== null) {
                PopInv.update(vm.popInv, onSaveSuccess, onSaveError);
            } else {
                PopInv.save(vm.popInv, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
