(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('AdjustedAccumulationDeleteController',AdjustedAccumulationDeleteController);

    AdjustedAccumulationDeleteController.$inject = ['$uibModalInstance', 'entity', 'AdjustedAccumulation'];

    function AdjustedAccumulationDeleteController($uibModalInstance, entity, AdjustedAccumulation) {
        var vm = this;
        vm.adjustedAccumulation = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            AdjustedAccumulation.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
