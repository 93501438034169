(function() {
    'use strict';
    angular
        .module('sceaApp')
        .factory('TicketOrder', TicketOrder);

    TicketOrder.$inject = ['$resource', 'DateUtils'];

    function TicketOrder ($resource, DateUtils) {
        var resourceUrl =  'api/ticket-orders/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                    data.targetDate = DateUtils.convertDateTimeFromServer(data.targetDate);
                    data.expiredDate = DateUtils.convertDateTimeFromServer(data.expiredDate);
                    data.periodStart = DateUtils.convertDateTimeFromServer(data.periodStart);
                    data.periodEnd = DateUtils.convertDateTimeFromServer(data.periodEnd);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'queryOutlets': {
            	url: 'api/ticket-orders/outlets',
            	method: 'GET', 
            	isArray: true
            },
            'querySpvSummaries': {
            	url: 'api/ticket-orders/spv/summaries',
            	method: 'GET', 
            	isArray: true
            },
            'queryVdeSummaries': {
            	url: 'api/ticket-orders/vde/summaries',
            	method: 'GET', 
            	isArray: true
            },
            'getDashboardsAsm': {
            	url: 'api/ticket-orders/vde/dashboards/total',
            	method: 'GET', 
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });
    }
})();
