(function() {
	'use strict';
	angular.module('sceaApp').factory('SceaProcessInstance', SceaProcessInstance);

	SceaProcessInstance.$inject = [ '$resource' ];

	function SceaProcessInstance($resource) {
		return $resource(null, {}, {
			'getProcessInstance': {
				url: 'api/projects/instances',
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
			},
			'getHistoricProcessInstance': {
				url: 'api/projects/historic-instances',
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
			},
			'getRuntimeTask': {
				url: 'api/runtime/tasks',
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
			},
			'getHistoricTask': {
				url: 'api/history/historic-task-instances',
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
			},
			'submitForm': {
				url: 'api/projects/form-data',
                method: 'POST'
			}
		});
	}
})();
