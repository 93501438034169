(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('VisitAuditDialogController', VisitAuditDialogController)
        .config(['markedProvider', 'hljsServiceProvider', function(markedProvider, hljsServiceProvider) {
        	markedProvider
				.setOptions({
					gfm : true,
					tables : true,
					sanitize : true,
					highlight : function(code, lang) {
						if (lang) {
							return hljs.highlight(lang,
									code, true).value;
						} else {
							return hljs
									.highlightAuto(code).value;
						}
					}
			});

			hljsServiceProvider.setOptions({
				tabReplace : '    '
			});
        }]);

    VisitAuditDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'marked'];

    function VisitAuditDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, marked) {
        var vm = this;
        vm.visitAudit = entity;
        vm.noteActive = 1;
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
