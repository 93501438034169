(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('SelloutDeleteController',SelloutDeleteController);

    SelloutDeleteController.$inject = ['$uibModalInstance', 'entity', 'Sellout'];

    function SelloutDeleteController($uibModalInstance, entity, Sellout) {
        var vm = this;
        vm.sellout = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            Sellout.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
