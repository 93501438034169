(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('UploadRetailMappingController', UploadRetailMappingController);

    UploadRetailMappingController.$inject = ['$scope', '$state', '$timeout', 'DataUtils', 'Upload'];

    function UploadRetailMappingController ($scope, $state, $timeout, DataUtils, Upload) {
        var vm = this;

        vm.listOrderData = {};

        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.setlistOrderData = setlistOrderData;
        vm.upload = upload;

        function setlistOrderData(file) {
            if(file) {
                vm.file = file;
                vm.isUploading = false;
                vm.isUploadFinished = false;

            	vm.listOrderData.dataContentType = file.type;
            	vm.listOrderData.fileName = file.name;
            	vm.listOrderData.size = file.size;
            }
        }

        function upload(file) {
            vm.isUploading = true;

            Upload.upload({
                url: 'api/outlets/retail-mapping-user/import',
                data: {file: file}
            }).then(function (response) {
                $timeout(function () {
                    file.result = 'Success ' + response.config.data.file.name + 'uploaded. Response: ' + response.data;
                });
            }, function (response) {
                vm.logs = response.data;

                if (response.status > 0)
                    file.error = response.status + ': ' + response.data;
            }, function (evt) {
                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                file.progress = 'Progress: ' + progressPercentage + '% ';
            });

            vm.isUploadFinished = true;
        }
    }
})();
