(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('ProductInchDeleteController',ProductInchDeleteController);

    ProductInchDeleteController.$inject = ['$uibModalInstance', 'entity', 'ProductInch'];

    function ProductInchDeleteController($uibModalInstance, entity, ProductInch) {
        var vm = this;
        vm.productInch = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            ProductInch.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
