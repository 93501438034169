(function () {
    'use strict';
    angular
        .module('sceaApp')
        .factory('ParentData', ParentData);

    ParentData.$inject = ['$localStorage'];

    function ParentData($localStorage) {

        var services = {
            setParentData : setParentData,
            getParentData : getParentData,
            clearParentData : clearParentData
        }

        if(!$localStorage.parentData) {
            $localStorage.parentData = [];
        }

        function setParentData(parents) {
            $localStorage.parentData = parents;
        }

        function getParentData() {
            return $localStorage.parentData;
        }

        function clearParentData() {
            delete $localStorage.parentData;
        }

        return services;
    }
})();
