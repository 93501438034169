(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('InternetDeleteController',InternetDeleteController);

    InternetDeleteController.$inject = ['$uibModalInstance', 'entity', 'Internet'];

    function InternetDeleteController($uibModalInstance, entity, Internet) {
        var vm = this;
        vm.internet = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            Internet.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
