(function() {
    'use strict';

    angular
        .module('sceaApp')
        .factory('InternetAnswerSearch', InternetAnswerSearch);

    InternetAnswerSearch.$inject = ['$resource'];

    function InternetAnswerSearch($resource) {
        var resourceUrl =  'api/_search/internet-answers/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
