(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('CallPlanCreationController', CallPlanCreationController);

    CallPlanCreationController.$inject = ['$rootScope', '$scope', '$state', '$filter', 'Principal', 'Calendar', 'User', 'TicketOrder'];

    function CallPlanCreationController ($rootScope, $scope, $state, $filter, Principal, Calendar, User, TicketOrder) {
        var vm = this;
        var monthNow, yearNow;
        vm.pageVdo = 1;
		
        vm.getVdeSummaries = getVdeSummaries;
        
        
		function init() {
			vm.months = Calendar.queryMonths();
			
			monthNow = moment().month() + 1;
			monthNow = monthNow.toString();
			vm.month = monthNow.toString();
			
			yearNow = moment().year();
			vm.years = [yearNow-1, yearNow];
			
			yearNow = yearNow.toString();
			vm.year = yearNow.toString();
			
            Principal.identity().then(function(account) {
            	var filters = $filter('filter')(account.authorities, 'VDO', true);
            	
            	if(filters.length > 0) {
                    User.get({login : account.login}, function(data) {
                    	vm.vdos = [data];
                    	vm.vdo = data;
                    	
        				getVdeSummaries(vm.vdo.id, yearNow, monthNow);
                    });
            	} else {
        			getVdo();
            	}
            });
		}
		init();

		function getVdo() {
			User.query({
				roleName: 'VDO'
			}, function(data, headers) {
				vm.vdos = data;
				vm.vdo = vm.vdos[0];
				
				getVdeSummaries(vm.vdo.id, yearNow, monthNow);

            	var totalNow = vm.vdos.length;
                var totalItems = headers('X-Total-Count');

                if(totalNow != totalItems) {
                	getMoreVdo(vm.pageVdo);
                }
			});
		}
		
        function getMoreVdo(page) {
        	User.query({
				roleName: 'VDO',
                page: page
            }, function(data, headers) {
            	angular.forEach(data, function(vdo) {
            		vm.vdos.push(vdo);
            	});

            	var totalNow = vm.vdos.length;
                var totalItems = headers('X-Total-Count');

                if(totalNow != totalItems) {
                	vm.pageVdo++;
                	getMoreVdo(vm.pageVdo);
                }
            });
        }

		function getVdeSummaries(vdoId, year, month) {
	        TicketOrder.queryVdeSummaries({
	        	vdoId: vdoId,
	        	year: year,
	    		month: month
	        }, function(data) {
	        	vm.vdeSummaries = data;
	        });			
		}
    }
})();
