(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('PopPairVersionDetailController', PopPairVersionDetailController);

    PopPairVersionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', '$filter', 'AlertService', 'ParseLinks', 'entity', 'PopPairVersion', 'ProductCategory', 'ProductSearch', 'Product'];

    function PopPairVersionDetailController($scope, $rootScope, $stateParams, $filter, AlertService, ParseLinks, entity, PopPairVersion, ProductCategory, ProductSearch, Product) {
        var vm = this;

        vm.getAllProduct = getAllProduct;
        vm.selectProduct = selectProduct;
        
        var unsubscribe = $rootScope.$on('sceaApp:popPairVersionUpdate', function(event, result) {
            vm.popPairVersion = result;
        });
        $scope.$on('$destroy', unsubscribe);

        (function () {
            vm.popPairVersion = entity;
            vm.page = 1;

            getAllProduct();
        })();
        
        function getAllProduct() {
            vm.product = null;

            PopPairVersion.getProducts({
                id: vm.popPairVersion.id,
                page: vm.page - 1,
                size: 20
            }, onSuccess, onError);
                
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.products = data;

                selectProduct(data[0]);
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        
        function selectProduct(product) {
            vm.product = product;

            if (vm.currentProduct !== vm.product) {
                getPops(product);
                vm.currentProduct = product;
            }
        }

        function getPops(product) {
            vm.pops = {
                table1: { title: 'normal', list: [] },
                table2: { title: 'conditional1', list: [] },
                table3: { title: 'conditional2', list: [] },
                table4: { title: 'conditional3', list: [] }
            }

            PopPairVersion.getProductPops({
                popPairVersionId: vm.popPairVersion.id,
                productId: product.id
            }, function (data) {
                angular.forEach(data, function (pop) {
                    if (pop.conditional1) {
                        vm.pops.table2.list.push(pop);
                    } else if (pop.conditional2) {
                        vm.pops.table3.list.push(pop);
                    } else if (pop.conditional3) {
                        vm.pops.table4.list.push(pop);
                    } else {
                        vm.pops.table1.list.push(pop);
                    }
                });

                for (var key in vm.pops) {
                    vm.pops[key].list = $filter('orderBy')(vm.pops[key].list, 'id', false);
                }
            });
        }
    }
})();
