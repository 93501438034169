(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('VisitCycleDetailController', VisitCycleDetailController);

    VisitCycleDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'VisitCycle'];

    function VisitCycleDetailController($scope, $rootScope, $stateParams, entity, VisitCycle) {
        var vm = this;
        vm.visitCycle = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:visitCycleUpdate', function(event, result) {
            vm.visitCycle = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
