(function(){
	'use strict';
	angular.module('sceaApp').factory('Deployment', Deployment);
	
	Deployment.$inject = ['$resource'];
	
	function Deployment($resource) {
		var service = $resource('bpm-rest/repository/deployments/:id',{}, {
			'query': {method: 'GET', isArray: false},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': { method:'POST' },
            'update': { method:'PUT' },
            'delete':{ method:'DELETE'}
		});
		return service;
	}
})();