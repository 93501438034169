(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('TPopEquipmentDeleteController',TPopEquipmentDeleteController);

    TPopEquipmentDeleteController.$inject = ['$uibModalInstance', 'entity', 'TPopEquipment'];

    function TPopEquipmentDeleteController($uibModalInstance, entity, TPopEquipment) {
        var vm = this;
        vm.tPopEquipment = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            TPopEquipment.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
