(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('RetailMappingPhotoCategoryDialogController', RetailMappingPhotoCategoryDialogController);

    RetailMappingPhotoCategoryDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'RetailMappingPhotoCategory'];

    function RetailMappingPhotoCategoryDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, RetailMappingPhotoCategory) {
        var vm = this;
        vm.retailMappingPhotoCategory = entity;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sceaApp:retailMappingPhotoCategoryUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.retailMappingPhotoCategory.id !== null) {
                RetailMappingPhotoCategory.update(vm.retailMappingPhotoCategory, onSaveSuccess, onSaveError);
            } else {
                RetailMappingPhotoCategory.save(vm.retailMappingPhotoCategory, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
