(function() {
  'use strict';

  angular
    .module('sceaApp')
    .controller('PopFulfillmentController', PopFulfillmentController);

  PopFulfillmentController.$inject = ['$scope', '$state', '$localStorage', '$filter', 'AlertService', 'OrderPop', 'OrderPopSearch', 'Pop'];

  function PopFulfillmentController ($scope, $state, $localStorage, $filter, AlertService, OrderPop, OrderPopSearch, Pop) {
    var vm = this;

    vm.back = back;
    vm.clearOutletOrder = clearOutletOrder;
    vm.checkStock = checkStock;
    vm.loadMoreOutletOrder = loadMoreOutletOrder;
    vm.next = next;
    vm.save = save;
    vm.searchOutletOrder = searchOutletOrder;
    vm.selectOutletOrder = selectOutletOrder;
    vm.generatePickingList = generatePickingList;

    vm.searchFields = ['code', 'city', 'name'];
    vm.selectField = selectField;

    function init() {
	  vm.today = new Date();
    	
      vm.pageOutletOrders = 1;
      vm.outletOrderPops = [];
      vm.badgeOrders = 0;
      getOutletOrders();

      vm.stocks = {};
    }
    init();

		    
    function selectField(item) {
		vm.selectedField = item;
	}

    function getOutletOrders() {
		if (vm.currentSearch) {
			OrderPopSearch.queryOutlets({
				field : vm.selectedField,
				query : vm.currentSearch,
				page : vm.pageOutletOrders - 1
			}, onSuccess, onError);
		} else {
			OrderPop.queryOutlets({
				page : vm.pageOutletOrders - 1
			}, onSuccess, onError);
		}

		function onSuccess(data, headers) {
			vm.outletOrderPops = data;

			var totalItems = headers('X-Total-Count');

			if (data.length == totalItems) {
				vm.disableLoadOutletOrders = true;
			} else {
				vm.disableLoadOutletOrders = false;
			}
		}

		function onError(error) {
			AlertService.error(error.data.message);
		}
	}

    function searchOutletOrder(value) {
		vm.page = 1;

		if (!vm.selectedField) {
			vm.selectedField = 'name';
		}

		vm.currentSearch = value;

		getOutletOrders();
	}

	function clearOutletOrder() {
		vm.page = 1;
		vm.currentSearch = null;
		vm.searchOrder = null;
		vm.selectedField = null;
		getOutletOrders();
	}

	function loadMoreOutletOrder() {
        vm.disableLoadOutletOrders = true;
        
		OrderPop.queryOutlets({
			page : vm.pageOutletOrders
		}, function(data, headers) {
			angular.forEach(data, function(outletOrder) {
				vm.outletOrderPops.push(outletOrder);
			});
			
			var totalNow = vm.outletOrderPops.length + data.length;
			var totalItems = headers('X-Total-Count');
			
            if(totalNow == totalItems) {
				vm.disableLoadOutletOrders = true;
            } else {
				vm.disableLoadOutletOrders = false;
            }
            
			vm.pageOutletOrders++;
		});
	}

			
    function selectOutletOrder(order) {
		if (order.selected) {
			vm.badgeOrders++;
		} else {
			if (vm.badgeOrders > 0) {
				vm.badgeOrders--;
			}
		}
	}

	function back() {
		vm.active = 0;
		vm.searchPop = '';
		vm.disableLoadOutletOrders = false;
		vm.stocks = {};
	}

	function next() {
		vm.active = 1;
		vm.disableLoadOutletOrders = true;

		getOrders();
	}
		
    function getOrders() {
    	vm.onProcess = true;
    	
    	vm.selectedOutlets = [];
		var outletIds = [];
		angular.forEach(vm.outletOrderPops, function(order) {
			if (order.selected) {
				vm.selectedOutlets.push(order);
				outletIds.push(order.id);
			}
		});

		OrderPop.query({
			status : 'REQUESTED',
			outletIds : outletIds
		}, function(data) {
			angular.forEach(data, function(orderPop) {
		    	vm.onProcess = true;
		    	
				orderPop.sentQty = orderPop.requestQty;
				orderPop.currentRequested = orderPop.sentQty;
				
				Pop.get({
					id : orderPop.popId
				}, function(data) {
					if(data.id) {
						orderPop.containPop = true;
						
						if (!vm.stocks[orderPop.popId]) {
							vm.stocks[orderPop.popId] = data;
							vm.stocks[orderPop.popId].remaining = data.stock - orderPop.sentQty;
						} else {
							vm.stocks[orderPop.popId].remaining -= orderPop.sentQty;
						}
					} else {
						orderPop.containPop = false;
					}

			    	vm.onProcess = false;
				});
			});

			vm.orderPops = data;
		});
	}
    
    function generatePickingList() {
    	angular.forEach(vm.orderPops, function(orderPop) {
			if(vm.stocks[orderPop.popId][orderPop.outletId]) {
				vm.stocks[orderPop.popId][orderPop.outletId] += orderPop.sentQty;
			} else {
				vm.stocks[orderPop.popId][orderPop.outletId] = orderPop.sentQty;
			}
    	});
    	
        var blob = new Blob([document.getElementById('exportable').innerHTML], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
        });
        saveAs(blob, "POP Refill.xls");
    }
		
    function checkStock(orderPop) {
    	// is there any stock?
    	if(vm.stocks[orderPop.popId].remaining > 0) {
    		// comparing fulfill qty with request qty
			if (orderPop.sentQty > orderPop.requestQty) {
				orderPop.sentQty = orderPop.requestQty;
			} else if (orderPop.sentQty < 0) {
				orderPop.sentQty = 0;
			}

    		// comparing fulfill qty with stock
			var currentRemaining = vm.stocks[orderPop.popId].remaining + orderPop.currentRequested;
		
			if (orderPop.sentQty > currentRemaining) {
				orderPop.sentQty = currentRemaining;
				
				vm.stocks[orderPop.popId].remaining = 0;
			} else {
				vm.stocks[orderPop.popId].remaining = currentRemaining- orderPop.sentQty;
			}
		
			orderPop.currentRequested = orderPop.sentQty;
    	} else {
    		orderPop.sentQty = 0;
    	}
	}

	function save() {
		for ( var key in vm.stocks) {
			vm.stocks[key].stock = vm.stocks[key].remaining;

			Pop.update(vm.stocks[key]);
		}

		angular.forEach(vm.orderPops, function(orderPop) {
			if (orderPop.sentQty == 0) {
				orderPop.requestStatus = 'NOTSENT';
			} else {
				orderPop.requestStatus = 'SENT';
			}

			OrderPop.update(orderPop, function(data) {
				$state.go('pop-fulfillment', null, {
					reload : true
				});
			});
		});
	}
  }
})();
