(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('AreaLevelDeleteController',AreaLevelDeleteController);

    AreaLevelDeleteController.$inject = ['$uibModalInstance', 'entity', 'AreaLevel'];

    function AreaLevelDeleteController($uibModalInstance, entity, AreaLevel) {
        var vm = this;
        vm.areaLevel = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            AreaLevel.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
