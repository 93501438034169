(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('ExportDialogController', ExportDialogController);

    ExportDialogController.$inject = ['$filter', '$timeout', '$scope', '$state', '$stateParams', '$uibModalInstance', 'ExcelReport', 'isHistoric', 'orderByFilter', 'Project', 'project'];

    function ExportDialogController ($filter, $timeout, $scope, $state, $stateParams, $uibModalInstance, ExcelReport, isHistoric, orderByFilter, Project, project) {
        var vm = this;
        var projectMap = {};
    	vm.project = project.id.toString();
        vm.isHistoric = isHistoric.toString();
        vm.date = {
        	from: null,
        	to: null	
        }
        Project.query(function(result) {
            vm.projects = orderByFilter(result, 'id', false);
        	angular.forEach(result, function(project) {
        		projectMap[project.id] = {};
        		projectMap[project.id].name = project.projectName;
        	})
        });
        
        vm.save = function() {
        	vm.isSaving = true;
        	
        	var obj = {};
        	if(vm.project != 'allProject') {
            	obj.allProjects = false;
            	obj.businessKey = projectMap[vm.project].name;
            	obj.projectId = parseInt(vm.project);  
        	} else {
            	obj.allProjects = true;    		
        	}
        	obj.isHistoric = vm.isHistoric;
        	obj.startTimeFrom = $filter('date')(vm.date.from, 'yyyy-MM-dd');
        	obj.startTimeTo = $filter('date')(vm.date.to, 'yyyy-MM-dd');
        	
        	ExcelReport.exportProjectList(obj, onSaveSuccess, onSaveError);
        };

        var onSaveSuccess = function (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });
    }
})();
