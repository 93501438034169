(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('DroppointDialogController', DroppointDialogController);

    DroppointDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity',
        'Droppoint', 'User', 'UserSearch', 'AlertService'];

    function DroppointDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Droppoint, User,
        UserSearch, AlertService) {

        var vm = this;
        vm.droppoint = entity;
        //vm.users = User.query();
        vm.getUser = getUser;
        vm.loadingUser = false;

        if (vm.droppoint.$promise) {
            vm.droppoint.$promise.then(function(data) {
                if (data.picLogin != null) {
                    User.get({login: data.picLogin}, function(vdo) {
                        vm.vdo = vdo;
                    });
                }
            });
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sceaApp:droppointUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        function getUser(role, value) {
            vm.loadingUser = true;
            return UserSearch.query({
                roleName: role,
                query: value
            }).$promise.then(function (response) {
                vm.loadingUser = false;
                return response;
            }, function (response) {
                vm.loadingUser = false;
                AlertService.error(response.data.message);
            });
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.vdo) {
                vm.droppoint.picId = vm.vdo.id;
            } else {
                vm.droppoint.picId = null;
            }
            if (vm.droppoint.id !== null) {
                Droppoint.update(vm.droppoint, onSaveSuccess, onSaveError);
            } else {
                Droppoint.save(vm.droppoint, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
