(function() {
    'use strict';

    angular
        .module('sceaApp')
        .factory('InternetQaSearch', InternetQaSearch);

    InternetQaSearch.$inject = ['$resource'];

    function InternetQaSearch($resource) {
        var resourceUrl =  'api/_search/internet-qas/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
