(function() {
    'use strict';

    angular
        .module('sceaApp')
        .factory('RisProductTargetSearch', RisProductTargetSearch);

    RisProductTargetSearch.$inject = ['$resource'];

    function RisProductTargetSearch($resource) {
        var resourceUrl =  'api/_search/ris-product-targets/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
