(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('TFileImageOtherJobDetailController', TFileImageOtherJobDetailController);

    TFileImageOtherJobDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'TFileImageOtherJob'];

    function TFileImageOtherJobDetailController($scope, $rootScope, $stateParams, entity, TFileImageOtherJob) {
        var vm = this;
        vm.tFileImageOtherJob = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:tFileImageOtherJobUpdate', function(event, result) {
            vm.tFileImageOtherJob = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
