(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('VisitCycleDialogController', VisitCycleDialogController)
        .directive('capitalize', function() {
			return {
				require : 'ngModel',
				link : function(scope, element, attrs, modelCtrl) {
					var capitalize = function(inputValue) {
						if (inputValue == undefined)
							inputValue = '';
						var capitalized = inputValue.toUpperCase();
						if (capitalized !== inputValue) {
							modelCtrl.$setViewValue(capitalized);
							modelCtrl.$render();
						}
						return capitalized;
					}
					modelCtrl.$parsers.push(capitalize);
					capitalize(scope[attrs.ngModel]); // capitalize initial value
				}
			};
		});

    VisitCycleDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'VisitCycle'];

    function VisitCycleDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, VisitCycle) {
        var vm = this;
        vm.visitCycle = entity;
        vm.buttonCancel = false;
        vm.buttonSave = false;
        
        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sceaApp:visitCycleUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            vm.buttonCancel = true;
            vm.buttonSave = true;
            if (vm.visitCycle.id !== null) {
                VisitCycle.update(vm.visitCycle, onSaveSuccess, onSaveError);
            } else {
                VisitCycle.save(vm.visitCycle, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
