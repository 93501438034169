(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('TicketOrderDeleteController',TicketOrderDeleteController);

    TicketOrderDeleteController.$inject = ['$uibModalInstance', 'entity', 'TicketOrder'];

    function TicketOrderDeleteController($uibModalInstance, entity, TicketOrder) {
        var vm = this;
        vm.ticketOrder = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            TicketOrder.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
