(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('AreaDetailController', AreaDetailController);

    AreaDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Area', 'AreaLevel'];

    function AreaDetailController($scope, $rootScope, $stateParams, entity, Area, AreaLevel) {
        var vm = this;
        vm.area = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:areaUpdate', function(event, result) {
            vm.area = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
