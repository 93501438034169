(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('OtherJobController', OtherJobController);

    OtherJobController.$inject = ['$scope', '$state', 'OtherJob', 'OtherJobSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

    function OtherJobController ($scope, $state, OtherJob, OtherJobSearch, ParseLinks, AlertService, pagingParams, paginationConstants) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.clear = clear;
        vm.search = search;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.searchStatus = pagingParams.status;
        vm.currentStatus = pagingParams.status;
        vm.getInOutletTime = getInOutletTime;
        vm.loadAll();

        function loadAll () {
        	if(vm.searchStatus == null) {
        		vm.searchStatus = 'needApproval';
        	}

            if (pagingParams.search) {
            	var queryStatus = ' AND status:';
            	if(pagingParams.status == 'needApproval') {
            		queryStatus += '1';
            	} else if(pagingParams.status == 'approved') {
            		queryStatus += '2';
            	} else if(pagingParams.status =='rejected') {
            		queryStatus += '3';
            	}

            	var query = '(outlet.outletName:"*'+pagingParams.search+'*" OR '+'employee.firstName:"*'+pagingParams.search+'*")';

                OtherJobSearch.query({
                    query: query + queryStatus,
                    page: pagingParams.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                OtherJob.query({
                    page: pagingParams.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.otherJobs = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                status: vm.currentStatus
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.currentStatus = vm.searchStatus;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.currentStatus = null;
            vm.transition();
        }

        function getInOutletTime(otherJob) {
        	var diff = moment(otherJob.surveyEnd).diff(moment(otherJob.surveyStart)),
        		duration = moment.duration(diff),
        		days = duration.days(),
        		stringDays = '',
        		time,
        		hours = duration.hours(),
        		mins = duration.minutes(),
        		secs = duration.seconds();

        	if(days > 0) {
        		stringDays = days + ' Day(s) ';
        	}

        	time = stringDays + hours + ":" + mins + ":" + secs;

        	return time;
        }
    }
})();
