(function() {
	'use strict';
	angular.module('sceaApp').factory('History', History);

	History.$inject = [ '$resource' ];

	function History($resource) {
        var resourceUrl =  'bpm-rest/history/historic-process-instances/:processInstanceId';

		return $resource(resourceUrl, {}, {
			'query': { method: 'GET', isArray: false },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': { method: 'POST' },
            'update': { method: 'PUT' },
            'delete':{ method: 'DELETE' },

			'getHistoricTask' : {
				url: 'bpm-rest/history/historic-task-instances',
				method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
			},
			'getHistoricDetail' : {
				url: 'bpm-rest/history/historic-detail',
				method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
			}
		});
	}
})();
