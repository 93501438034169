(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('OtherJobStoreCompletenessDetailController', OtherJobStoreCompletenessDetailController);

    OtherJobStoreCompletenessDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'OtherJobStoreCompleteness', 'StoreCompleteness', 'OtherJob'];

    function OtherJobStoreCompletenessDetailController($scope, $rootScope, $stateParams, entity, OtherJobStoreCompleteness, StoreCompleteness, OtherJob) {
        var vm = this;
        vm.otherJobStoreCompleteness = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:otherJobStoreCompletenessUpdate', function(event, result) {
            vm.otherJobStoreCompleteness = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
