(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('TPopFixtureDetailController', TPopFixtureDetailController);

    TPopFixtureDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'TPopFixture', 'TPop', 'Fixtures', 'ProductCategory'];

    function TPopFixtureDetailController($scope, $rootScope, $stateParams, entity, TPopFixture, TPop, Fixtures, ProductCategory) {
        var vm = this;
        vm.tPopFixture = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:tPopFixtureUpdate', function(event, result) {
            vm.tPopFixture = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
