(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('TPopInternetDeleteController',TPopInternetDeleteController);

    TPopInternetDeleteController.$inject = ['$uibModalInstance', 'entity', 'TPopInternet'];

    function TPopInternetDeleteController($uibModalInstance, entity, TPopInternet) {
        var vm = this;
        vm.tPopInternet = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            TPopInternet.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
