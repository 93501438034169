(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('FixturesDetailController', FixturesDetailController);

    FixturesDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Fixtures', 'ProductCategory'];

    function FixturesDetailController($scope, $rootScope, $stateParams, entity, Fixtures, ProductCategory) {
        var vm = this;
        vm.fixtures = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:fixturesUpdate', function(event, result) {
            vm.fixtures = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
