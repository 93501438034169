(function() {
    'use strict';
    angular
        .module('sceaApp')
        .factory('Outlet', Outlet);

    Outlet.$inject = ['$resource'];

    function Outlet ($resource) {
        var resourceUrl =  'api/outlets/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': {
                method:'PUT'
            },
            'queryByEmployee': {
            	url: 'api/outlets/employees/:id',
            	method: 'GET',
            	isArray: true
            },
            'queryBySpv': {
            	url: 'api/outlets/spv/:id',
            	method: 'GET',
            	isArray: true
            },
            'queryByVdo': {
            	url: 'api/outlets/vdo/:id',
            	method: 'GET',
            	isArray: true
            },
            'getGeoJson': {
                url: 'api/outlets/geojson',
                method: 'GET'
            },
            'exportAsCsv': {
                url: 'api/outlets/csv',
                method: 'GET',
                 transformResponse: function (data, headersGetter) {
                                    var filename = "outlets.csv";
                                    var blob = new Blob([data], {type: "text/csv;charset=UTF-8"});
                                    saveAs(blob, filename, true);
                                    return true;
                                }
            }


        });
    }
})();
