(function () {
    'use strict';

    angular
        .module('sceaApp')
        .controller('PlanogramDetailController', PlanogramDetailController);

    PlanogramDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Planogram', 'Outlet', '$locale'];

    function PlanogramDetailController($scope, $rootScope, $stateParams, entity, Planogram, Outlet, $locale) {
        var vm = this;

        vm.planogram = entity;

        vm.onSelectFixtureCategory = onSelectFixtureCategory;
        vm.onSelectFixture = onSelectFixture;
        vm.onSelectSubFixture = onSelectSubFixture;
        vm.loadEquipmentCategories = loadEquipmentCategories;
        vm.onSelectEquipmentCategories = onSelectEquipmentCategories;
        vm.onSelectEquipmentSubCategories = onSelectEquipmentSubCategories;

        (function () {
            vm.dateFormat = $locale.DATETIME_FORMATS.customTime;
            loadFixtureCategories(vm.planogram.id);
        })();

        // load fixture categories
        function loadFixtureCategories(id) {
            Planogram.getProductCategoriesFixturePlanogram({
                id: id
            }, function (data) {
                vm.fixtureCategory = data;
            });
        }

        // load equipment categories
        function loadEquipmentCategories() {
            Planogram.getEquipmentsCategory({
                id: vm.planogram.id,
                root: true
            }, function (data) {
                vm.equipmentCategories = data
            });
        }

        // all onSelect function in fixture tab  
        function onSelectFixtureCategory(index) {
            vm.selectedIndexFixtureCategory = index;

            // delete sub index
            vm.selectedIndexFixture = null;
            vm.selectedIndexSubFixture = null;

            Planogram.getFixturePlanogram({
                id: vm.planogram.id,
                categoryId: index
            }, function (data) {
                vm.fixtures = data;
            });
        }

        function onSelectFixture(index) {
            vm.selectedIndexFixture = index;
            // delete sub index
            vm.selectedIndexSubFixture = null;

            Planogram.getFixturePlanogram({
                id: vm.planogram.id,
                parentId: index
            }, function (data) {
                vm.subFixtures = data;
            });
        }

        function onSelectSubFixture(index) {
            vm.selectedIndexSubFixture = index;
            Planogram.getProductFixturesPlanogram({
                id: vm.planogram.id,
                fixtureId: index
            }, function (data) {
                vm.fixtureProducts = data;
            });
        }

        // all onSelect function in equipment tab
        function onSelectEquipmentCategories(index) {
            vm.selectedIndexEquipmentCategory = index;

            // delete sub index
            vm.selectedIndexEquipmentSubCategory = null;

            Planogram.getEquipmentsCategory({
                id: vm.planogram.id,
                root: false,
                parentId: index
            }, function (data) {
                vm.equipmentSubCategories = data;
            });
        }

        function onSelectEquipmentSubCategories(index) {
            vm.selectedIndexEquipmentSubCategory = index;

            Planogram.getProductEquipmentsPlanogram({
                id: vm.planogram.id,
                categoryId: index
            }, function (data) {
                vm.equipmentProducts = data;
            });
        }
    }
})();
