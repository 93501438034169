(function () {
    'use strict';

    angular
        .module('sceaApp')
        .controller('PlanogramSubFixtureDialog', PlanogramSubFixtureDialog);

    PlanogramSubFixtureDialog.$inject = ['$scope', '$uibModalInstance', 'resSubFixture', 'resFixture', 'ProductCategory', 'Fixtures'];

    function PlanogramSubFixtureDialog($scope, $uibModalInstance, resSubFixture, resFixture, ProductCategory, Fixtures) {
        var vm = this;

        vm.addSubFixture = addSubFixture;
        vm.cancel = cancel;
        vm.checkAll = checkAll;

        (function () {
            vm.fixtureName = resFixture.name;

            Fixtures.query({
                parent: resFixture.id
            }, function (data, headers) {
                Fixtures.query({
                    parent: resFixture.id,
                    size: headers('x-total-count')
                }, function (data) {
                    vm.subFixtures = data
                        .filter(function (each) {
                            var anyFixture = resSubFixture
                                .find(function (source) {
                                    return source.fixtureId === each.id;
                                });

                            return !anyFixture;
                        });
                });
            })
        })()

        function checkAll(isChecked) {
            if (isChecked) {
                angular.forEach(vm.subFixtures, function (e) {
                    e.status = true;
                });
            } else {
                angular.forEach(vm.subFixtures, function (e) {
                    e.status = false;
                });
            }
        }

        function addSubFixture() {
            $uibModalInstance.close(vm.subFixtures);
        };

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
