(function() {
    'use strict';

    angular
        .module('sceaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('upload-retail-mapping-allocation', {
            parent: 'entity',
            url: '/upload-retail-mapping-allocation',
            data: {
                authorities: ['ROLE_ADMIN', 'VDR'],
                pageTitle: 'sceaApp.uploadRetailMappingAllocation.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/upload-retail-mapping-allocation/upload-retail-mapping-allocation.html',
                    controller: 'UploadRetailMappingController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('uploadRetailMappingAllocation');
                	$translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        });
    }

})();
