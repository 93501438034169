(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('AllTasksController', AllTasksController);

    AllTasksController.$inject = ['$scope', '$state', 'AlertService', 'Principal', 'SceaProcessInstance', 'UserGroup'];

    function AllTasksController ($scope, $state, AlertService, Principal, SceaProcessInstance, UserGroup) {
        var vm = this;
        vm.role = null;
    	vm.projectIds = null;
        vm.tasks = [];
        vm.isInvolvement = true;
        vm.isMoreFilter = true;
        vm.filter = {
        	assignedToMe: true,
        	assignedToOthers: true,
        	assignee: null,
        	completed: false,
        }
		vm.others = '';
        vm.page = 1;
        vm.size = 20;
        vm.start = 0;
        vm.loadAll = loadAll;
		
        function getAccount() {
            Principal.identity().then(function(account) {
            	for(var i=0; i<account.authorities.length; i++) {
            		if(account.authorities[i] != 'ROLE_ADMIN') {
            			vm.role = account.authorities[i];
            			setOthers(account.authorities[i]);
            			
            			if(vm.role == 'VDO') {
            				setProjectId();
            			} else {
            		        vm.loadAll();
            			}
            			break;
            		}
            	}
            });
        };
        getAccount();
        
        function setOthers(myRole) {
        	vm.roles = UserGroup.query(function(result) {
	        	angular.forEach(result, function(role, key) {
	        		if(role.name != 'ROLE_ADMIN' && role.name != myRole) {
	        			vm.others = vm.others.concat(role.name);
		        		if(result.length-1 != key) {
		        			vm.others = vm.others.concat(',');
		        		}
	        		}
	        	})
	        });
        }

        var setProjectId = function() {
        	vm.projectIds = '';
        	SceaProcessInstance.getProcessInstance(function(result) {
        		angular.forEach(result.data, function(project, key) {
            		if(key != 0) {
            			vm.projectIds += ',';
            		}
            		vm.projectIds += project.processInstanceId;
        			
            		if(result.data.length-1 == key) {
        		        vm.loadAll();
            		}
        		});
        	});
        }
        
        function loadAll() {
        	if(vm.tasks.size != null) {
        		vm.start = parseInt(vm.page) * parseInt(vm.tasks.size) - parseInt(vm.tasks.size);
        	}

			if(vm.projectIds == null) {
	        	if(vm.isInvolvement){
	        		vm.isHistoric = false;
	        		
	            	if(vm.filter.assignedToMe && vm.filter.assignedToOthers) {
	    				SceaProcessInstance.getRuntimeTask({
	    	        		includeProcessVariables: true,
	    	    			start: vm.start,
	    	    			size: vm.size
	    		    	}, onSuccess, onError);
	            	} else if(vm.filter.assignedToMe) {
	    				SceaProcessInstance.getRuntimeTask({
	    	        		includeProcessVariables: true,
	            			candidateGroup: vm.role,
	    	    			start: vm.start,
	    	    			size: vm.size
	    		    	}, onSuccess, onError);
	            	} else if(vm.filter.assignedToOthers) {
	    				SceaProcessInstance.getRuntimeTask({
	    	        		includeProcessVariables: true,
	            			candidateGroups: vm.others,
	    	    			start: vm.start,
	    	    			size: vm.size
	    		    	}, onSuccess, onError);
	            	} else {
	            		vm.tasks = [];
	                    vm.totalItems = 0;
	            	}        		
	        	}
	        	if(vm.isAssignment){
	        		vm.isHistoric = false;
	        		
	        		if(vm.filter.assignee != null) {
	    				SceaProcessInstance.getRuntimeTask({
	    	        		includeProcessVariables: true,
	            			candidateGroup: vm.filter.assignee,
	    	    			start: vm.start,
	    	    			size: vm.size
	    		    	}, onSuccess, onError);
	            	} else {
	    				SceaProcessInstance.getRuntimeTask({
	    	        		includeProcessVariables: true,
	    	    			start: vm.start,
	    	    			size: vm.size
	    		    	}, onSuccess, onError);
	            	}
	        	}
	        	if(vm.isMoreFilter){
	        		if(vm.filter.completed) {
	            		vm.isHistoric = true;
	            		
	    				SceaProcessInstance.getHistoricTask({
	    	        		includeProcessVariables: true,
	                		taskDeleteReasonLike: 'completed',
	    	    			start: vm.start,
	    	    			size: vm.size
	    		    	}, onSuccess, onError);
	            	} else {
	            		vm.isHistoric = false;
	            		
	    				SceaProcessInstance.getRuntimeTask({
	    	        		includeProcessVariables: true,
	    	    			start: vm.start,
	    	    			size: vm.size
	    		    	}, onSuccess, onError);
	            	}
	        	}
			} else {
	        	if(vm.isInvolvement){
	        		vm.isHistoric = false;
	        		
	            	if(vm.filter.assignedToMe && vm.filter.assignedToOthers) {
	    				SceaProcessInstance.getRuntimeTask({
	    	        		includeProcessVariables: true,
	    	        		processInstanceIds: vm.projectIds,
	    	    			start: vm.start,
	    	    			size: vm.size
	    		    	}, onSuccess, onError);
	            	} else if(vm.filter.assignedToMe) {
	    				SceaProcessInstance.getRuntimeTask({
	    	        		includeProcessVariables: true,
	    	        		processInstanceIds: vm.projectIds,
	            			candidateGroup: vm.role,
	    	    			start: vm.start,
	    	    			size: vm.size
	    		    	}, onSuccess, onError);
	            	} else if(vm.filter.assignedToOthers) {
	    				SceaProcessInstance.getRuntimeTask({
	    	        		includeProcessVariables: true,
	    	        		processInstanceIds: vm.projectIds,
	            			candidateGroups: vm.others,
	    	    			start: vm.start,
	    	    			size: vm.size
	    		    	}, onSuccess, onError);
	            	} else {
	            		vm.tasks = [];
	                    vm.totalItems = 0;
	            	}        		
	        	}
	        	if(vm.isAssignment){
	        		vm.isHistoric = false;
	        		
	        		if(vm.filter.assignee != null) {
	    				SceaProcessInstance.getRuntimeTask({
	    	        		includeProcessVariables: true,
	    	        		processInstanceIds: vm.projectIds,
	            			candidateGroup: vm.filter.assignee,
	    	    			start: vm.start,
	    	    			size: vm.size
	    		    	}, onSuccess, onError);
	            	} else {
	    				SceaProcessInstance.getRuntimeTask({
	    	        		includeProcessVariables: true,
	    	        		processInstanceIds: vm.projectIds,
	    	    			start: vm.start,
	    	    			size: vm.size
	    		    	}, onSuccess, onError);
	            	}
	        	}
	        	if(vm.isMoreFilter){
	        		if(vm.filter.completed) {
	            		vm.isHistoric = true;
	            		
	    				SceaProcessInstance.getHistoricTask({
	    	        		includeProcessVariables: true,
	    	        		processInstanceIds: vm.projectIds,
	                		taskDeleteReasonLike: 'completed',
	    	    			start: vm.start,
	    	    			size: vm.size
	    		    	}, onSuccess, onError);
	            	} else {
	            		vm.isHistoric = false;
	            		
	    				SceaProcessInstance.getRuntimeTask({
	    	        		includeProcessVariables: true,
	    	        		processInstanceIds: vm.projectIds,
	    	    			start: vm.start,
	    	    			size: vm.size
	    		    	}, onSuccess, onError);
	            	}
	        	}
			}
        	
            function onSuccess(data) {
                vm.totalItems = data.total;
                vm.queryCount = vm.totalItems;
                vm.tasks = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        vm.updateFilter = function(){
            vm.page = 1;
            vm.size = 20;
            vm.start = 0;
            vm.totalItems = 0;
            vm.queryCount = 0;
            loadAll();
        }
		
		vm.splitCategory = function(input) {
			if(input == null) return '';
			else {
				var string = input.split('-');
				return string[0];
			}
		};
		
		vm.splitCandidate = function(input) {
			if(input == null) return '';
			else {
				var string = input.split('-');
				return string[1];
			}
		};
		
		vm.getVariableValue = function(obj, params) {
			var result = '';
			angular.forEach(obj, function(value, key) {
				if(params == value.name) {
					if(params == 'project') {
						var businessUnit = JSON.parse(value.value);
						result = businessUnit.projectName;
					} else if(params == 'business_unit') {
						var businessUnit = JSON.parse(value.value);
						result = businessUnit.name;
					} else if(params == 'store') {
						var store = JSON.parse(value.value);
						result = store.outletName;
					} else {
						result = value.value;
					}
				}
			});
			return result;
		};
    }
})();
