(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('SpvOutletDialogController', SpvOutletDialogController);

    SpvOutletDialogController.$inject = ['$scope', '$state', '$stateParams', '$uibModalInstance', '$uibModal', 'outlets', 'Calendar', 'Outlet', 'TicketOrder'];

    function SpvOutletDialogController ($scope, $state, $stateParams, $uibModalInstance, $uibModal, outlets, Calendar, Outlet, TicketOrder) {
        var vm = this;

        vm.outlets = outlets;
    	vm.reverse = true;
    	vm.predicate = 'outletCode';

        vm.addOutlet = addOutlet;
    	vm.clear = clear;
        vm.deleteOutlet = deleteOutlet;

        function addOutlet() {
            var modalInstance = $uibModal.open({
            	templateUrl: 'app/admin/spv-call-plan-creation/spv-outlet-add-dialog.html',
            	controller: 'SpvOutletAddDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
            	size: 'md',
            	resolve: {
                	outlets: ['Outlet', function(Outlet) {
                        return Outlet.queryBySpv({id: $stateParams.employeeId});
                    }]
            	}
            });

            modalInstance.result.then(function(result) {}, function() {
            	vm.outlets = TicketOrder.queryOutlets({
            		employeeId: $stateParams.employeeId,
            		calendarId : $stateParams.calendarId
            	});
            });
        }

        function deleteOutlet(outlet) {
        	TicketOrder.delete({id: outlet.ticketOrderId}, function(data) {
        		TicketOrder.queryOutlets({
        			employeeId: $stateParams.employeeId,
        			calendarId : $stateParams.calendarId
        		}, function(data) {
        	        vm.outlets = data;
        		});
        	});
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
