(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('InternetAnswerDetailController', InternetAnswerDetailController);

    InternetAnswerDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'InternetAnswer'];

    function InternetAnswerDetailController($scope, $rootScope, $stateParams, entity, InternetAnswer) {
        var vm = this;
        vm.internetAnswer = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:internetAnswerUpdate', function(event, result) {
            vm.internetAnswer = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
