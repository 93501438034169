(function () {
    'use strict';

    angular
        .module('sceaApp')
        .controller('PlanogramFixtureDialog', PlanogramFixtureDialog);

    PlanogramFixtureDialog.$inject = ['$scope', '$uibModalInstance', 'resFixture', 'ProductCategory', 'Fixtures'];

    function PlanogramFixtureDialog($scope, $uibModalInstance, resFixture, ProductCategory, Fixtures) {
        var vm = this;

        vm.addFixture = addFixture;
        vm.cancel = cancel;
        vm.changeFixtureList = changeFixtureList;
        vm.checkAll = checkAll;

        (function () {
            ProductCategory.getAllProductCategories({
                level: 2
            }, function (data) {
                vm.categories = data;
                vm.option = data[0];
                getFixtures(vm.option.id);
            });
        })()

        function changeFixtureList(id) {
            vm.check = false;
            getFixtures(id)
        }

        function getFixtures(id) {
            Fixtures.query({
                categoryId: id,
                parent: 'null'
            }, function (data) {
                vm.fixtures = data
                    .filter(function (each) {
                        var anyFixture = resFixture
                            .find(function (source) {
                                return source.fixtureId === each.id;
                            });

                        return !anyFixture;
                    });
            })
        }

        function checkAll(isChecked) {
            if (isChecked) {
                angular.forEach(vm.fixtures, function (e) {
                    e.status = true;
                });
            } else {
                angular.forEach(vm.fixtures, function (e) {
                    e.status = false;
                });
            }
        }

        function addFixture() {
            $uibModalInstance.close(vm.fixtures);
        };

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
