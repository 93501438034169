(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('ProductDialogController', ProductDialogController);

    ProductDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Product', 'ProductCategory', 'LaunchPeriod', 'ProductInch'];

    function ProductDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Product, ProductCategory, LaunchPeriod, ProductInch) {
        var vm = this;
        vm.product = entity;
        vm.dataCategory = [];
        vm.productCategories = ProductCategory.getAllProductCategories({level: 3});
        vm.launchperiods = LaunchPeriod.query({size: 2000});
        vm.productinches = ProductInch.query({size: 2000});
         
        if (vm.productCategories.$promise) {
        	vm.productCategories.$promise.then(function(data) {
        		for(var i=0; i<data.length; i++){
        			vm.dataCategory[i] = {
						"id" : data[i].id,
						"categoryName" : data[i].categoryName
					}
        		}	
           	}, function(error) {
				console.log(error);
			});
		}
        
        if (vm.product.$promise) {
        	vm.product.$promise.then(function(data) {
        		vm.productCategory = {
					"id" : data.productCategoryId,
					"categoryName" : data.productCategoryCategoryName
				}
           		if(vm.productCategory == null){
           			vm.productCategory = null;
	       		}
			}, function(error) {
				console.log(error);
			});
		}
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sceaApp:productUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if(vm.productCategory){
            	vm.product.productCategoryId = vm.productCategory.id;
            }else{
            	vm.product.productCategoryId = null;
            }
            if (vm.product.id !== null) {
                Product.update(vm.product, onSaveSuccess, onSaveError);
            } else {
                Product.save(vm.product, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
