(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('PopDeleteController',PopDeleteController);

    PopDeleteController.$inject = ['$uibModalInstance', 'entity', 'Pop'];

    function PopDeleteController($uibModalInstance, entity, Pop) {
        var vm = this;
        vm.pop = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            Pop.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
