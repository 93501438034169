(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('PlanogramEquipmentDetailController', PlanogramEquipmentDetailController);

    PlanogramEquipmentDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PlanogramEquipment', 'Planogram', 'Equipment'];

    function PlanogramEquipmentDetailController($scope, $rootScope, $stateParams, entity, PlanogramEquipment, Planogram, Equipment) {
        var vm = this;
        vm.planogramEquipment = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:planogramEquipmentUpdate', function(event, result) {
            vm.planogramEquipment = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
