(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('AchievementController', AchievementController);

    AchievementController.$inject = ['$scope', '$state', '$stateParams', '$filter', 'DashboardParams', 'Achievement', 'Calendar'];

    function AchievementController ($scope, $state,  $stateParams, $filter, DashboardParams, Achievement, Calendar) {
       var vm = this;
       vm.achievement = [];
       vm.dashboardParams = null;
       vm.achievement.year = null;
       vm.achievement.month = null;
       vm.achievement.week = null;
       vm.isDisabled = true;
       vm.getWeeks = getWeeks;
       vm.data = [];
            		
       vm.options = {
   			years: [],
   			months: [],
   			weeks: [],
   			dataType: { type: null },
   			storeType: { type: null },
   			callPlanType: {type: null},
   			callPlan: { status: null, max: null },
   			popAV: { status: null, max: null },
   			popHA: { status: null, max: null },
   			equipment: { status: null, max: null },
   			fixture: { status: null, max: null },
   			internet: { status: null, max: null },
   			totalValue: null,
   			adjustedData: { status: null }
   		}
       
       vm.onClickGetData = function(year, month, week, storeType, callPlanType){
    	    if(vm.options.dataType.type == 'month'){
  				week = null;
  				vm.showDisabled = true;
  				getData(year, month, week, storeType, callPlanType);
  			}else{
  				month = null;
  				vm.showDisabled = true;
  				getData(year, month, week, storeType, callPlanType);
  			}
       }
       
       var onSaveSuccess = function (result) {
    	   $scope.$emit('sceaApp:achievementUpdate', result);
           vm.isSaving = false;
       };

       var onSaveError = function () {
           vm.isSaving = false;
       };
       
       vm.save = function(){
	   		var objUpdated = [];

    	   	angular.forEach(vm.achievements, function(achievement, key) {
    	   		var tempAchievement = {};
    	   		if(achievement.statusUpdate == true) {
    	   			tempAchievement.callPlan = achievement.callPlan / 100;
    	   			tempAchievement.callPlanId = achievement.callPlanId;
    	   			    	   			
    	   			tempAchievement.popAV = achievement.popAV / 100;
    	   			tempAchievement.popAVId = achievement.popAVId;
    	   			
    	   			tempAchievement.popHA = achievement.popHA / 100;
    	   			tempAchievement.popHAId = achievement.popHAId;
    	   			
    	   			tempAchievement.equipment = achievement.equipment / 100;
    	   			tempAchievement.equipmentId = achievement.equipmentId;
    	   			
    	   			tempAchievement.fixture = achievement.fixture / 100;
    	   			tempAchievement.fixtureId = achievement.fixtureId;
    	   			
    	   			tempAchievement.internet = achievement.internet / 100;
    	   			tempAchievement.internetId = achievement.internetId;
    	   			
    	   			
    	   			tempAchievement.dataType = achievement.dataType;
    	   			tempAchievement.popAllId = achievement.popAllId;
    	   			tempAchievement.vde = achievement.vde;
    	   			objUpdated.push(tempAchievement);
    	   		}
    	   		if(vm.achievements.length-1 == key) {
    	   		    Achievement.update(objUpdated, onSaveSuccess, onSaveError);	
    	   		}
    	   	})
    	   	
       }
       
       vm.init = function() {
    	   DashboardParams.get(function(result){
    		   vm.dashboardParams = result;
	    	   
					getYears();
					getMonths();
					getWeeks(vm.achievement.year, vm.achievement.month); 
				    
					vm.options.dataType.type = 'month';
					vm.options.storeType.type = 'VDE';
					vm.options.callPlanType.type = 'ACOJTGT';
					vm.buttonSave = 'save';
    			});
		}
		vm.init();
		
		vm.onClickYear = function(value){
			vm.showDisabled = false;
		}
		
		vm.onClickStoreType = function(value){
			if(value == 'VDE' || value == 'PERFECT' || value == 'RIS' || value == 'PC'){
				vm.showDisabled = false;
			}
		}
		
		vm.onClickDataType = function(value){
			if(value == 'month' || value == 'week'){
				vm.showDisabled = false;
			}
		}
		
		vm.onClickOptionsDataType = function(value){
			vm.showDisabled = false;
		}
		
		vm.onClickCallPlanType = function(value){
			if(value == 'ACOJTGT' || value == 'ACOJRCP' || value == 'ACTGT' || value == 'ACRCP'){
				vm.showDisabled = false;
			}
		}
		
		vm.changeCallPlan = function(index, value) {
			if(vm.achievements[index].callPlan == null){
				vm.achievements[index].callPlan = 0;
			}
			vm.achievements[index].statusUpdate = true;
		}
		
		vm.changePopAV = function(index, value){
			if(vm.achievements[index].popAV == null){
				vm.achievements[index].popAV = 0;
			}
			vm.achievements[index].statusUpdate = true;
		}
		
		vm.changePopHA = function(index, value){
			if(vm.achievements[index].popHA == null){
				vm.achievements[index].popHA = 0;
			}
			vm.achievements[index].statusUpdate = true;
		}
		
		vm.changeEquipment = function(index, value){
			if(vm.achievements[index].equipment == null){
				vm.achievements[index].equipment = 0;
			}
			vm.achievements[index].statusUpdate = true;
		}
		
		vm.changeFixture = function(index, value){
			if(vm.achievements[index].fixture == null){
				vm.achievements[index].fixture = 0;
			}
			vm.achievements[index].statusUpdate = true;
		}
		
		vm.changeInternet = function(index, value){
			if(vm.achievements[index].internet == null){
				vm.achievements[index].internet = 0;
			}
			vm.achievements[index].statusUpdate = true;
		}
		
		function initCalendar() {
			 	vm.achievement.year = new Date().getFullYear();
			 	vm.achievement.month = (new Date().getMonth() + 1).toString();
			 	vm.getWeeks(vm.achievement.year);
	        }
		 initCalendar();
		 
		function getYears() {
			Calendar.queryYears(function(result) {		
				vm.options.years = result;
				getWeeks(vm.achievement.year);
			});
		}
		
		function getMonths() {
			Calendar.queryMonths(function(result) {		
				vm.options.months = result;
			});
		}
		
		function getWeeks(year, month) {
			Calendar.queryWeeks({year: year, month: month}, function(result) {
				vm.options.weeks = result;
				vm.achievement.week = vm.options.weeks[0];
			});
		}
		
		function getData(year, month, week, storeType, callPlanType){
			Achievement.query({year: year, month: month, week: week, storeType: storeType, callPlanType: callPlanType}, function(data){
				if(data.length == 0){
					vm.achievements = data;
					vm.isDisabled = true;
				}else{
					vm.achievements = data;
					angular.forEach(vm.achievements, function(achievement, key) {
						if(isNaN(achievement.callPlan)){
							achievement.callPlan = 0;
							achievement.flagCallPlan = 'NaN';
						}else if(!isFinite(achievement.callPlan)){
							achievement.callPlan = 100;
							achievement.flagCallPlan = 'Infinity';
						}else if(achievement.callPlan > 1){
							achievement.callPlan = 100;
						}else{
							achievement.callPlan *= 100;
							achievement.callPlan = $filter('number')(achievement.callPlan, 0);
							achievement.callPlan = parseInt(achievement.callPlan);
							achievement.flagCallPlan = 'Number';
						}
						  
						if(isNaN(achievement.popAV)){
							achievement.popAV = 0;
							achievement.flagPopAV = 'NaN';
						}else if(!isFinite(achievement.popAV)){
							achievement.popAV = 100;
							achievement.flagPopAV = 'Infinity';
						}else if(achievement.popAV > 1){
							achievement.popAV = 100;
						}else{
							achievement.popAV *= 100;
							achievement.popAV = $filter('number')(achievement.popAV, 0);
							achievement.popAV = parseInt(achievement.popAV);
							achievement.flagPopAV = 'Number';
						}
						
						if(isNaN(achievement.popHA)){
							achievement.popHA = 0;
							achievement.flagPopHA = 'NaN';
						}else if(!isFinite(achievement.popHA)){
							achievement.popHA = 100;
							achievement.flagPopHA = 'Infinity';
						}else if(achievement.popHA > 1){
							achievement.popHA = 100;
						}else{
							achievement.popHA *= 100;
							achievement.popHA = $filter('number')(achievement.popHA, 0);
							achievement.popHA = parseInt(achievement.popHA);
							achievement.flagPopHA = 'Number';
						}
						
						if(isNaN(achievement.equipment)){
							achievement.equipment = 0;
							achievement.flagEquipment = 'NaN';
						}else if(!isFinite(achievement.equipment)){
							achievement.equipment = 100;
							achievement.flagEquipment = 'Infinity';
						}else if(achievement.equipment > 1){
							achievement.equipment = 100;
						}else{
							achievement.equipment *= 100;
							achievement.equipment = $filter('number')(achievement.equipment, 0);
							achievement.equipment = parseInt(achievement.equipment);
							achievement.flagEquipment = 'Number';
						}
						
						if(isNaN(achievement.fixture)){
							achievement.fixture = 0;
							achievement.flagFixture = 'NaN';
						}else if(!isFinite(achievement.fixture)){
							achievement.fixture = 100;
							achievement.flagFixture = 'Infinity';
						}else if(achievement.fixture > 1){
							achievement.fixture = 100;
						}else{
							achievement.fixture *= 100;
							achievement.fixture = $filter('number')(achievement.fixture, 0);
							achievement.fixture = parseInt(achievement.fixture);
							achievement.flagFixture = 'Number';
						}
						
						if(isNaN(achievement.internet)){
							achievement.internet = 0;
							achievement.flagInternet = 'NaN';
						}else if(!isFinite(achievement.internet)){
							achievement.internet = 100;
							achievement.flagInternet = 'Infinity';
						}else if(achievement.internet > 1){
							achievement.internet = 100;
						}else{
							achievement.internet *= 100;
							achievement.internet = $filter('number')(achievement.internet, 0);
							achievement.internet = parseInt(achievement.internet);
							achievement.flagInternet = 'Number';
						}
					});
					vm.isDisabled = false;
				}
			});
			
		}
	}
})();
