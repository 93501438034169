(function() {
    'use strict';

    angular
        .module('sceaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig ($stateProvider) {
        $stateProvider
        .state('pop-dashboard', {
            parent: 'report',
            url: '/report/pop-dashboard',
            data: {
                authorities: ['ROLE_ADMIN', 'ARCO', 'SPV'],
                pageTitle: 'global.menu.report.pop.dashboard'
            },
            views: {
                'content@': {
                    templateUrl: 'app/report/report-pop/dashboard/report-pop-dashboard.html',
                    controller: 'ReportPopDashboardController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('reportPopChecklist');
                    $translatePartialLoader.addPart('calendar');
                    $translatePartialLoader.addPart('pop');
                    return $translate.refresh();
                }],
                submenu: function () {
                    return {
                        title: 'dashboard'
                    };
                }
            }
        })
        .state('pop-row-data', {
            parent: 'report',
            url: '/report/pop-row-data',
            data: {
                authorities: ['ROLE_ADMIN', 'ARCO', 'SPV', 'VDO', 'VDR'],
                pageTitle: 'global.menu.report.pop.rowData'
            },
            views: {
                'content@': {
                    templateUrl: 'app/report/report-pop/report-pop.html',
                    controller: 'ReportPopController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', function ($translate) {
                    return $translate.refresh();
                }],
                submenu: function () {
                    return {
                        title: 'row-data'
                    };
                }
            }
        })
        .state('pop-checklist', {
            parent: 'report',
            url: '/report/pop-checklist',
            data: {
                authorities: ['ROLE_ADMIN', 'ARCO', 'SPV'],
                pageTitle: 'global.menu.report.pop.checklist'
            },
            views: {
                'content@': {
                    templateUrl: 'app/report/report-pop/checklist/report-pop-checklist.html',
                    controller: 'ReportPopChecklistController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('reportPopChecklist');
                    $translatePartialLoader.addPart('calendar');
                    $translatePartialLoader.addPart('area');
                    return $translate.refresh();
                }],
                submenu: function () {
                    return {
                        title: 'checklist'
                    };
                }
            }
        })
        .state('pop-difference', {
            parent: 'report',
            url: '/report/pop-difference',
            data: {
                authorities: ['ROLE_ADMIN', 'ARCO', 'SPV'],
                pageTitle: 'global.menu.report.pop.difference'
            },
            views: {
                'content@': {
                    templateUrl: 'app/report/report-pop/report-pop.html',
                    controller: 'ReportPopController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', function ($translate) {
                    return $translate.refresh();
                }],
                submenu: function () {
                    return {
                        title: 'difference'
                    };
                }
            }
        })
        .state('pop-request-row-data', {
                    parent: 'report',
                    url: '/report/pop-request-row-data',
                    data: {
                        authorities: ['ROLE_ADMIN', 'ARCO', 'SPV'],
                        pageTitle: 'global.menu.report.pop.requestRowData'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/report/report-pop/report-pop.html',
                            controller: 'ReportPopController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', function ($translate) {
                            return $translate.refresh();
                        }],
                        submenu: function () {
                            return {
                                title: 'request-row-data'
                            };
                        }
                    }
                })
        .state('pop-summary-request', {
            parent: 'report',
            url: '/report/pop-summary-request',
            data: {
                authorities: ['ROLE_ADMIN', 'ARCO', 'SPV'],
                pageTitle: 'global.menu.report.pop.summaryRequest'
            },
            views: {
                'content@': {
                    templateUrl: 'app/report/report-pop/report-pop.html',
                    controller: 'ReportPopController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', function ($translate) {
                    return $translate.refresh();
                }],
                submenu: function () {
                    return {
                        title: 'summary-request'
                    };
                }
            }
        })
        .state('inventory-valuation', {
            parent: 'report',
            url: '/report/inventory-valuation',
            data: {
                authorities: ['ROLE_ADMIN', 'ARCO', 'SPV'],
                pageTitle: 'global.menu.report.pop.inventoryValuation'
            },
            views: {
                'content@': {
                    templateUrl: 'app/report/report-pop/report-pop.html',
                    controller: 'ReportPopController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', function ($translate) {
                    return $translate.refresh();
                }],
                submenu: function () {
                    return {
                        title: 'inventory-valuation'
                    };
                }
            }
        })
        .state('stock-move', {
            parent: 'report',
            url: '/report/stock-move',
            data: {
                authorities: ['ROLE_ADMIN', 'ARCO', 'SPV'],
                pageTitle: 'global.menu.report.pop.inventoryValuation'
            },
            views: {
                'content@': {
                    templateUrl: 'app/report/report-pop/report-pop.html',
                    controller: 'ReportPopController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', function ($translate) {
                    return $translate.refresh();
                }],
                submenu: function () {
                    return {
                        title: 'stock-move'
                    };
                }
            }
        });
    }
})();
