(function() {
	'use strict';

	angular.module('sceaApp').controller('CalendarDialogController',
			CalendarDialogController);

	CalendarDialogController.$inject = [ '$timeout', '$scope', '$stateParams',
			'$uibModalInstance', 'entity', 'Calendar' ];

	function CalendarDialogController($timeout, $scope, $stateParams,
			$uibModalInstance, entity, Calendar) {
		var vm = this;
        vm.checkValidDate = checkValidDate;

		vm.calendar = entity;

        if(entity.startDate != null && entity.endDate != null) {
            checkValidDate();
        }

		$timeout(function() {
			angular.element('.form-group:eq(1)>input').focus();
		});

		var onSaveSuccess = function(result) {
			$scope.$emit('sceaApp:calendarUpdate', result);
			$uibModalInstance.close(result);
			vm.isSaving = false;
		};

		var onSaveError = function() {
			vm.isSaving = false;
		};

		vm.save = function() {
			vm.isSaving = true;

            var endDate = moment(vm.calendar.endDate).endOf('day');
            vm.calendar.endDate = endDate._d;

			if (vm.calendar.id !== null) {
				Calendar.update(vm.calendar, onSaveSuccess, onSaveError);
			} else {
				Calendar.save(vm.calendar, onSaveSuccess, onSaveError);
			}
		};

		vm.clear = function() {
			$uibModalInstance.dismiss('cancel');
		};

		vm.datePickerOpenStatus = {};
		vm.datePickerOpenStatus.startDate = false;
		vm.datePickerOpenStatus.endDate = false;

		vm.openCalendar = function(date) {
			vm.datePickerOpenStatus[date] = true;
		};

        function checkValidDate() {
        	if(vm.calendar.startDate != null && vm.calendar.endDate != null) {
            	var isValid = moment(vm.calendar.endDate).isAfter(vm.calendar.startDate);
            	if(isValid) {
            		vm.invalidDate = false;
            	} else {
            		vm.invalidDate = true;
            	}
        	}
        }
	}
})();
