(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('SpvOutletAddDialogController', SpvOutletAddDialogController);

    SpvOutletAddDialogController.$inject = ['$scope', '$state', '$uibModalInstance', '$stateParams', 'outlets', 'AlertService', 'Outlet', 'OutletSearch', 'TicketOrder'];

    function SpvOutletAddDialogController($scope, $state, $uibModalInstance, $stateParams, outlets, AlertService, Outlet, OutletSearch, TicketOrder) {
        var vm = this;

        vm.outlets = outlets;
    	vm.reverse = true;
    	vm.predicate = 'outletCode';
    	
        vm.addOutlet = addOutlet;
    	vm.clear = clear;
    	vm.clearSearch = clearSearch;
        vm.loadAll = loadAll;
        vm.search = search;

    	outlets.$promise.then(function(data) {
    		vm.isLoadAllOutlet = true;
    		
            vm.predicate = 'outletCode';
            vm.page = 1;
            
            vm.loadAll();
    	});
    	
        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function addOutlet(outlet) {
        	TicketOrder.save({
            	outletId: outlet.id,
            	employeeId: parseInt($stateParams.employeeId),
            	calendarId: parseInt($stateParams.calendarId),
            	flagAsynch: 0,
            	ticketWfStat: 0
        	});
        }

        function loadAll () {
            if (vm.searchQuery) {
                OutletSearch.query({
                    query: vm.searchQuery,
                    page: vm.page - 1,
                    size: 20,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Outlet.query({
                    page: vm.page - 1,
                    size: 20,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'outletCode') {
                    result.push('outletCode');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.masterOutlets = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.loadAll();
        }
        
        function clearSearch () {
            vm.page = 1;
            vm.predicate = 'outletCode';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.searchQuery = null;
            vm.loadAll();
        }
    }
})();
