(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('ApiKeyDialogController', ApiKeyDialogController);

    ApiKeyDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ApiKey'];

    function ApiKeyDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ApiKey) {
        var vm = this;

        vm.apiKey = entity;
        vm.clear = clear;
        vm.save = save;
        vm.editMode = true;
        vm.generatingToken = false;
        vm.generateToken = generateToken;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();


            vm.editMode = false;
            (function(){
                if (entity.name != null) {
                    vm.editMode = true;
                }

                if (!vm.editMode) {
                    vm.generateToken();
                }
            })();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.apiKey.id !== null) {
                ApiKey.update(vm.apiKey, onSaveSuccess, onSaveError);
            } else {
                ApiKey.save(vm.apiKey, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('sceaApp:apiKeyUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function generateToken () {
            vm.generatingToken = true
            ApiKey.generateToken().then(function(response){
                vm.apiKey.token = response.data;
                vm.generatingToken = false;
            }, function(error){
                vm.generatingToken = false;
            });
        }

    }
})();
