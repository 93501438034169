(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('AreaDialogController', AreaDialogController);

    AreaDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Area', 'AreaLevel', 'AreaSearch'];

    function AreaDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Area, AreaLevel, AreaSearch) {
        var vm = this;
        vm.area = entity;
        vm.areas = Area.query();
        vm.arealevels = AreaLevel.query();
        vm.getArea = getArea;
        vm.loadingArea = false;
        vm.parent = null;
        
       if (vm.area.$promise) {
			vm.area.$promise.then(function(data) {
				vm.parent = {
					"id" : data.parentId,
					"areaName" : data.parentName
				}
			}, function(error) {
				console.log(error);
			});
		}
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });
        
        var onSaveSuccess = function (result) {
            $scope.$emit('sceaApp:areaUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if(vm.parent){
            	vm.area.parentId = vm.parent.id;
            }else{
            	vm.area.parentId = null;
            }	
            if (vm.area.id !== null) {
            	Area.update(vm.area, onSaveSuccess, onSaveError);
            } else {          	
                Area.save(vm.area, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        
        function getArea(value) {
        	vm.loadingArea = true;
            return AreaSearch.query({
            	query: '*'+value+'*'
            }).$promise.then(function(response){
            	vm.loadingArea = false;
                return response;
            }, function(response){
            	vm.loadingArea = false;
                AlertService.error(response.data.message);
            });
        };
    }
})();
