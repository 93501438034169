(function () {
    'use strict';
    angular
        .module('sceaApp')
        .factory('Planogram', Planogram);

    Planogram.$inject = ['$resource', 'DateUtils'];

    function Planogram($resource, DateUtils) {
        var resourceUrl = 'api/planograms/:id';

        return $resource(resourceUrl, {}, {
            'queryPlanogram': { method: 'GET', isArray: true },
            'getPlanogramById': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.periodStart = DateUtils.convertDateTimeFromServer(data.periodStart);
                    return data;
                }
            },
            'updatePlanogram': { method: 'PUT' },
            'savePlanogram': { method: 'POST' },
            'getProductCategoriesFixturePlanogram': {
                url: 'api/planograms/:id/fixtures/product-categories',
                method: 'GET',
                isArray: true
            },
            'getFixturePlanogram': {
                url: 'api/planograms/:id/fixtures',
                method: 'GET',
                isArray: true
            },
            'getProductFixturesPlanogram': {
                url: 'api/planograms/:id/products',
                method: 'GET',
                isArray: true
            },
            'getEquipmentsCategory': {
                url: 'api/planograms/:id/equipment-categories',
                method: 'GET',
                isArray: true
            },
            'getProductEquipmentsPlanogram': {
                url: 'api/planograms/:id/equipments',
                method: 'GET',
                isArray: true
            },
            'updatePlanogramFixture': {
                url: 'api/planograms/:id/fixtures',
                method: 'PUT'
            },
            'updateProductFixture': {
                url: 'api/planograms/:id/products',
                method: 'PUT'
            },
            'updatePlanogramEquipment': {
                url: 'api/planograms/:id/equipments',
                method: 'PUT'
            },
            'deletePlanogramFixture': {
                url: 'api/planograms/:id/fixtures/:fixtureId',
                method: 'DELETE'
            },
            'deleteFixtureProduct': {
                url: 'api/planograms/:id/fixtures/:fixtureId/products/:productId',
                method: 'DELETE'
            },
            'deleteEquipmentProduct' : {
                url: 'api/planograms/:id/equipments/:equipmentId',
                method: 'DELETE'
            }
        });
    }
})();
