(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('OtherJobBrandDetailController', OtherJobBrandDetailController);

    OtherJobBrandDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'OtherJobBrand', 'Brand', 'ProductInch', 'ProductCategory', 'OtherJob'];

    function OtherJobBrandDetailController($scope, $rootScope, $stateParams, entity, OtherJobBrand, Brand, ProductInch, ProductCategory, OtherJob) {
        var vm = this;
        vm.otherJobBrand = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:otherJobBrandUpdate', function(event, result) {
            vm.otherJobBrand = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
