(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('CalendarDetailController', CalendarDetailController);

    CalendarDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Calendar'];

    function CalendarDetailController($scope, $rootScope, $stateParams, entity, Calendar) {
        var vm = this;
        vm.calendar = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:calendarUpdate', function(event, result) {
            vm.calendar = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
