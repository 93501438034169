(function() {
    'use strict';

    angular
        .module('sceaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig ($stateProvider) {
        $stateProvider
        .state('equipment-row-data', {
            parent: 'report',
            url: '/report/equipment-row-data',
            data: {
                authorities: ['ROLE_ADMIN', 'ARCO', 'SPV', 'VDO', 'VDR'],
                pageTitle: 'global.menu.report.equipment.rowData'
            },
            views: {
                'content@': {
                    templateUrl: 'app/report/report-equipment/report-equipment.html',
                    controller: 'ReportEquipmentController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', function ($translate) {
                    return $translate.refresh();
                }],
                submenu: function () {
                    return {
                        title: 'row-data'
                    };
                }
            }
        });
    }
})();
