(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('FormulaFieldDetailController', FormulaFieldDetailController);

    FormulaFieldDetailController.$inject = ['$scope', '$rootScope', '$stateParams', '$filter', 'entity', 'FormulaField', 'ProjectColumn'];

    function FormulaFieldDetailController($scope, $rootScope, $stateParams, $filter, entity, FormulaField, ProjectColumn) {
        var vm = this;
        vm.formulaField = entity;
	    vm.formulaField.$promise.then(function(data) {
	    	vm.formulaField.formula = data.formula.split(" ");
	    	
            angular.forEach(vm.formulaField.formula, function(formula, key) {
    			if(formula.substring(0, 1) === "@") {
    				var parse = '';
    				
    	        	var sellout = formula.substring(16, 22);
    	        	if(sellout == 'outlet') {
    	        		parse += 'Sell Out Outlet ';
    	        	} else if(sellout == 'branch') {
    	        		parse += 'Sell Out Branch ';
    	        	}

                	var properties = formula.substring(30, formula.length-1);
                	properties = properties.replace(/#/g, '');
                	properties = properties.replace(/inYear/g, 'Input Year');
                	properties = properties.replace(/lpYear/g, 'Last Project Year');
                	properties = properties.replace(/inMonth/g, 'Input Month');
                	properties = properties.replace(/lpMonth/g, 'Last Project Month');
                	properties = properties.split(",");
                	
                	angular.forEach(properties, function(result, key) {
                		if(key == 0) {
            				parse += 'from ';
                		}
                		if(key == 2) {
            				parse += ' to ';
                		}

                		if(key%2 != 0) {
            				parse += '/';
                		}

        				parse += '{';
        				
        				var separators = ['\\\+', '-'];
        				var tempParse = result.split(new RegExp(separators.join('|'), 'g'));

        	    		if(tempParse.length > 1){
        	    			if(tempParse[0].length < 3) {
        	        			parse += $filter('translate')('sceaApp.calendar.months.' + tempParse[0]);
        	        			parse += result.substring(tempParse[0].length, tempParse[0].length+1);
        	    				parse += tempParse[1];
        	    			} else {
        	    				parse += result;
        	    			}
        	    		} else {
        	    			if(result.length < 3) {
        	    				parse += $filter('translate')('sceaApp.calendar.months.' + result);
        	    			} else {
        	    				parse += result;		    				
        	    			}
        	    		}

        				parse += '}';
                	});
    				
                	vm.formulaField.formula[key] = parse;
    			}
    			
    			if(formula.substring(0, 1) === "#") {
    				var id = formula.replace(/#/g, '');
    				
    				var columnName = ProjectColumn.getbyColumnId({columnId: id});
    				columnName.$promise.then(function(data) {
        				vm.formulaField.formula[key] = data.columnName + ' ';
    				});
    			}
    			
    			vm.formulaField.formula[key] += ' ';
            });
	    });
        
        var unsubscribe = $rootScope.$on('sceaApp:formulaFieldUpdate', function(event, result) {
            vm.formulaField = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
