(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('ApiKeyController', ApiKeyController);

    ApiKeyController.$inject = ['$state', 'ApiKey', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function ApiKeyController($state, ApiKey, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.loadAll = loadAll;
        vm.setActive = setActive;

        loadAll();

        function loadAll () {
            ApiKey.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'name') {
                    result.push('name');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.apiKeys = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            });
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'name';
            vm.reverse = true;
            vm.transition();
        }

        function setActive(apiKey, active) {
            apiKey.active = active;
            ApiKey.update(apiKey);
        }
    }
})();
