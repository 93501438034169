(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('ChannelDeleteController',ChannelDeleteController);

    ChannelDeleteController.$inject = ['$uibModalInstance', 'entity', 'Channel'];

    function ChannelDeleteController($uibModalInstance, entity, Channel) {
        var vm = this;
        vm.channel = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            Channel.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
