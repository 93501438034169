(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('FormulaFieldController', FormulaFieldController);

    FormulaFieldController.$inject = ['$scope', '$state', '$filter', 
                                      'FormulaField', 'FormulaFieldSearch', 'ParseLinks', 'AlertService', 'pagingParams', 
                                      'paginationConstants', 'ProjectColumn'];

    function FormulaFieldController ($scope, $state, $filter, 
    		FormulaField, FormulaFieldSearch, ParseLinks, AlertService, pagingParams, paginationConstants, ProjectColumn) {
        var vm = this;
        vm.formulaFields = [];
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.clear = clear;
        vm.search = search;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.loadAll();

        function loadAll () {
            if (pagingParams.search) {
                FormulaFieldSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                FormulaField.query({
                    page: pagingParams.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.page = pagingParams.page; 
                vm.queryCount = vm.totalItems;
                vm.formulaFields = data;
                
                angular.forEach(vm.formulaFields, function(formulaField) {
                	formulaField.formula = formulaField.formula.split(" ");

                    angular.forEach(formulaField.formula, function(formula, key) {
            			if(formula.substring(0, 1) === "@") {
            				var parse = '';
            				
            	        	var sellout = formula.substring(16, 22);
            	        	if(sellout == 'outlet') {
            	        		parse += 'Sell Out Outlet ';
            	        	} else if(sellout == 'branch') {
            	        		parse += 'Sell Out Branch ';
            	        	}

                        	var properties = formula.substring(30, formula.length-1);
                        	properties = properties.replace(/#/g, '');
                        	properties = properties.replace(/inYear/g, 'Input Year');
                        	properties = properties.replace(/lpYear/g, 'Last Project Year');
                        	properties = properties.replace(/inMonth/g, 'Input Month');
                        	properties = properties.replace(/lpMonth/g, 'Last Project Month');
                        	properties = properties.split(",");
                        	
                        	angular.forEach(properties, function(result, key) {
                        		if(key == 0) {
                    				parse += 'from ';
                        		}
                        		if(key == 2) {
                    				parse += ' to ';
                        		}

                        		if(key%2 != 0) {
                    				parse += '/';
                        		}

                				parse += '{';
                				
                				var separators = ['\\\+', '-'];
                				var tempParse = result.split(new RegExp(separators.join('|'), 'g'));

                	    		if(tempParse.length > 1){
                	    			if(tempParse[0].length < 3) {
                	        			parse += $filter('translate')('sceaApp.calendar.months.' + tempParse[0]);
                	        			parse += result.substring(tempParse[0].length, tempParse[0].length+1);
                	    				parse += tempParse[1];
                	    			} else {
                	    				parse += result;
                	    			}
                	    		} else {
                	    			if(result.length < 3) {
                	    				parse += $filter('translate')('sceaApp.calendar.months.' + result);
                	    			} else {
                	    				parse += result;		    				
                	    			}
                	    		}

                				parse += '}';
                        	});
            				
            				formulaField.formula[key] = parse;
            			}
            			
            			if(formula.substring(0, 1) === "#") {
            				var id = formula.replace(/#/g, '');
            				
            				var columnName = ProjectColumn.getbyColumnId({columnId: id});
            				columnName.$promise.then(function(data) {
                				formulaField.formula[key] = data.columnName + ' ';
            				});
            			}
            			
        				formulaField.formula[key] += ' ';
                    });
                });
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        
    	function loadPage (page) {
            vm.page = page;
            vm.transition();
        }
        
        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
        
    }
})();
