(function() {
    'use strict';
    angular
        .module('sceaApp')
        .factory('VDETaskPlanning', VDETaskPlanning);

    VDETaskPlanning.$inject = ['$resource'];

    function VDETaskPlanning ($resource) {
        var resourceUrl =  'api/vde-task-plannings/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'queryOutletsActuals': { 
            	url: 'api/vde-task-plannings/outlets/actuals',
            	method: 'GET',
            	isArray: true
            },
            'queryOutletsRecommendations': { 
            	url: 'api/vde-task-plannings/outlets/recommendations',
            	method: 'GET',
            	isArray: true
            },
            'queryOutletsTargets': { 
            	url: 'api/vde-task-plannings/outlets/targets',
            	method: 'GET',
            	isArray: true
            },
            'querySummaries': { 
            	url: 'api/vde-task-plannings/summaries',
            	method: 'GET',
            	isArray: true,
            	transformResponse: function(data) {
            		data = angular.fromJson(data);
            		var dataCopy = JSON.parse(JSON.stringify(data));
            	
            		// get max week length
            		var maxWeeksElem = 0;
            		var arrIdxHasMaxWeek = 0;
            		for( var i = 0; i < data.length; i++) {
            			if(data[i].weeks && data[i].weeks.length > maxWeeksElem){
            				maxWeeksElem = data[i].weeks.length;
            				arrIdxHasMaxWeek = i;
            			}
            		}
            		
            		var weekNums = [];
            		for(var i = 0; i < data[arrIdxHasMaxWeek].weeks.length; i++){
            			weekNums.push(data[arrIdxHasMaxWeek].weeks[i])
            		}

            		//
            		var template = JSON.parse(JSON.stringify(weekNums));
            		template.forEach(function(el, index, theArray){
            			theArray[index].value = 0;
            		});
            		for( var i = 0; i < data.length; i++) {
            			if(data[i].weeks.length < weekNums.length) {
            				var copyOri = JSON.parse(JSON.stringify(data[i].weeks));
            				var newWeeks = JSON.parse(JSON.stringify(template));
            				for(var h = 0; h < copyOri.length; h++){
            					var modWe = newWeeks.find(function(w){
            						return w.week === copyOri[h].week;
            					});
            					modWe.value = copyOri[h].value;
            				}
            				dataCopy[i].weeks = JSON.parse(JSON.stringify(newWeeks));
            			}
            		}
            		return dataCopy;
            	}
            }
        });
    }
})();
