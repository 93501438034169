(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('CallPlanOutletDialogController', CallPlanOutletDialogController);

    CallPlanOutletDialogController.$inject = ['$uibModalInstance', 'calendar', 'employeeId', 'TicketOrder', 'AlertService'];

    function CallPlanOutletDialogController ($uibModalInstance, calendar, employeeId, TicketOrder, AlertService) {
        var vm = this;
        vm.clear = clear;
        vm.bgColorStat = {
            'Not Downloaded' : 'lightgrey',
            'Succeed' : 'lightgreen',
            'Failed' : 'lightpink',
            'In Progress' : 'yellow',
        }
        vm.total = {
            todo: 0,
            failed: 0,
            success: 0,
            created: 0
        }
        vm.ticketOrders = [];

        loadTickerOrder();

        function loadTickerOrder() {
            vm.loading = true;
            TicketOrder.queryOutlets({
                employeeId: employeeId,
                calendarId : calendar.id
            }, onSuccess, onError);

            function onSuccess(ticketOrders) {
                vm.loading = false;
                vm.ticketOrders = ticketOrders;
                vm.total = {
                    todo: filterTicketByStatus(vm.ticketOrders, 'In Progress'),
                    failed: filterTicketByStatus(vm.ticketOrders, 'Failed'),
                    success: filterTicketByStatus(vm.ticketOrders, 'Succeed'),
                    created: filterTicketByStatus(vm.ticketOrders, 'Not Downloaded')
                }
            }

            function onError() {
                vm.loading = false;
                AlertService.error(error.data.message);
            }

            function filterTicketByStatus(data, status) {
                var tickets = data.filter(function(eachData){
                    return eachData.status === status;
                });
                return tickets.length;
            }
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
