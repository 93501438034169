(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('ProductInchDetailController', ProductInchDetailController);

    ProductInchDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'ProductInch'];

    function ProductInchDetailController($scope, $rootScope, $stateParams, entity, ProductInch) {
        var vm = this;
        vm.productInch = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:productInchUpdate', function(event, result) {
            vm.productInch = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
