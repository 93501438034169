(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('PopTrackingDetailController', PopTrackingDetailController);

        PopTrackingDetailController.$inject = ['$scope', '$state', '$stateParams', 'Principal', 'User', 'Calendar', 'Dashboard'];

    function PopTrackingDetailController ($scope, $state, $stateParams, Principal, User, Calendar, Dashboard) {
        var vm = this;
        
        vm.back = back;
        vm.updateParams = updateParams;
        vm.updateWeeks = updateWeeks;

		function init() {
            getAccount();

            vm.predicate = '-achievement';

            if ($stateParams.showChart != null) {
                vm.showChart = $stateParams.showChart == 'true';
            }

            vm.calendar = {
                year: String($stateParams.year),
                month: String($stateParams.month),
                week: String($stateParams.week)
            }

            vm.years = Calendar.queryYears();
            vm.months = Calendar.queryMonths();
            vm.weeks = Calendar.queryWeeks({
                year: vm.calendar.year,
                month: vm.calendar.month
            });
		}
        init();
        
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.user = User.get({login: account.login});

                if(vm.user.$promise) {
                    vm.user.$promise.then(function(data) {
                        getPopTracking(data, vm.calendar);
                    });
                }
            });
        }

        function updateWeeks(year, month) {
            vm.weeks = Calendar.queryWeeks({
                year: year,
                month: month
            }, function(data) {
                vm.calendar.week = String(data[0]);

                updateParams(vm.calendar);
            });
        }

        function updateParams(calendar) {
            $state.go('pop-tracking-detail', {
                week: calendar.week,
                month: calendar.month,
                year: calendar.year
            });
        }

		function getPopTracking(user, calendar) {
            if(calendar.year && calendar.week) {
                Dashboard.getAsmPopTracking({
                    userId: user.id,
                    year: calendar.year,
                    week: calendar.week
                }, function(data) {
                    var sumActual = 0;
                    var sumTarget = 0;
                    var sumAchievement = 0;

                    angular.forEach(data, function(eachData) {
                        (eachData.roleName == 'VDE') ? eachData.roleName = 'VDO' : null;

                        eachData.achievement = eachData.achievements.actual / eachData.achievements.target * 100;
                        if(isNaN(eachData.achievement)) {
                            eachData.achievement = 0;
                        }
                        
                        sumActual += eachData.achievements.actual;
                        sumTarget += eachData.achievements.target;
                        sumAchievement += eachData.achievement;
                    });
                    
                    data.sumActual = sumActual;
                    data.sumTarget = sumTarget;
                    data.sumAchievement = sumAchievement / data.length; 

                    vm.popTracking = data;
                });
            }
        }
        
        function back(calendar) {
            $state.go('home', {
                week: calendar.week,
                month: calendar.month,
                year: calendar.year,
                showChart: vm.showChart
            });
        }
    }
})();
