(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('OutletEmployeeDialogController', OutletEmployeeDialogController);

    OutletEmployeeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModal', '$uibModalInstance', 'entity', 'employee', 'Outlet', 'Employee', 'VisitCycle'];

    function OutletEmployeeDialogController($timeout, $scope, $stateParams, $uibModal, $uibModalInstance, entity, employee, Outlet, Employee, VisitCycle) {
        var vm = this;
        var modalLoading;
        vm.outlet = entity;
    	vm.employee = employee;
        vm.visitcycles = VisitCycle.query();
        
        employee.$promise.then(function(data) {
            vm.employees = Employee.query({
            	role: 'VDE', 
            	managerId: data.managerId
            });
        });
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sceaApp:outletUpdate', result);
            modalLoading.dismiss('cancel');
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            modalLoading.dismiss('cancel');
            vm.isSaving = false;
        };

        vm.updateEmployee = function(employee) {
        	vm.outlet.employeeId = employee.id;
        	vm.outlet.employeeName = employee.firstName;
        }
        
        vm.save = function () {
        	openModal();
            vm.isSaving = true;
            if (vm.outlet.id !== null) {
                Outlet.update(vm.outlet, onSaveSuccess, onSaveError);
            } else {
                Outlet.save(vm.outlet, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        
        function openModal() {
            modalLoading = $uibModal.open({
                animation: true,
            	templateUrl: 'app/merchandiser-activity/outlet-employee/loading-dialog.html',
                backdrop: 'static',
            	size: 'sm'
            });
        };
    }
})();
