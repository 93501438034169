(function() {
    'use strict';

    angular.module('sceaApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('row-data-pop-report', {
            parent: 'report',
            url: '/report/row-data-pop',
            data: {
                authorities: ['ROLE_ADMIN', 'ARCO', 'SPV', 'VDO', 'VDR'],
                pageTitle: 'sceaApp.rowDataPopReport.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/report/row-data-pop-report/row-data-pop-reports.html',
                    controller: 'RowDataPopReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('global');
                	$translatePartialLoader.addPart('rowDataPopReport');
                	$translatePartialLoader.addPart('calendar');
                	$translatePartialLoader.addPart('area');
                    return $translate.refresh();
                }]
            }
        })
    }
})();
