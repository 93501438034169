(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('PlanogramExportController', PlanogramExportController);

    PlanogramExportController.$inject = [];

    function PlanogramExportController () {
        var vm = this;
    }
})();
