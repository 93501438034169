(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('VDETaskPlanningController', VDETaskPlanningController);

    VDETaskPlanningController.$inject = ['$rootScope', '$scope', '$state', '$q', '$timeout', 'VDETaskPlanning', 'VDETaskPlanningSearch', 'AlertService', 'Calendar'];

    function VDETaskPlanningController ($rootScope, $scope, $state, $q, $timeout, VDETaskPlanning, VDETaskPlanningSearch, AlertService, Calendar) {
		var defer = $q.defer();
		
        var vm = this;
        vm.vdeTaskPlannings = [];
        vm.data = [];
        vm.currentCalendar = Calendar.getCurrent(function(months) {	
    		vm.month = vm.currentCalendar.month.toString();
		});
		
		vm.months = Calendar.queryMonths();
		
		$timeout(function() {
			var vdo = $rootScope.vdo;
			if(vdo != null) {
				vm.vdeTaskPlannings = VDETaskPlanning.querySummaries({
					vdoId: vdo.id,
					year: getYear(),
					month: vm.month });	
				defer.resolve(vm.vdeTaskPlannings);
			}
		}, 500);
		
        function getYear() {
			var result = new Date().getFullYear();
			return result;
		}

		vm.updateVdeTaskPlannings = function(vdo, month) {
			if(vdo != null && month != null) {
				VDETaskPlanning.querySummaries({
					vdoId : vdo.id,
					year : getYear(),
					month : parseInt(month)
				}, onSuccess, onError);
			}

			function onSuccess(result) {
				vm.vdeTaskPlannings = result;
			}
			function onError(error) {
				AlertService.error(error.data.message);
			}
		}
        
		vm.onChangeMonth = function(month) {
			vm.updateVdeTaskPlannings($rootScope.vdo, month);
		}
		
		$rootScope.$watch('vdo', function(vdo) {
			if(vdo != null) {
				vm.updateVdeTaskPlannings(vdo, vm.month);
			}
		});
    }
})();
