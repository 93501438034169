(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('TStoreCompletenessDeleteController',TStoreCompletenessDeleteController);

    TStoreCompletenessDeleteController.$inject = ['$uibModalInstance', 'entity', 'TStoreCompleteness'];

    function TStoreCompletenessDeleteController($uibModalInstance, entity, TStoreCompleteness) {
        var vm = this;
        vm.tStoreCompleteness = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            TStoreCompleteness.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
