(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('RetailMappingPhotoCategoryDeleteController',RetailMappingPhotoCategoryDeleteController);

    RetailMappingPhotoCategoryDeleteController.$inject = ['$uibModalInstance', 'entity', 'RetailMappingPhotoCategory'];

    function RetailMappingPhotoCategoryDeleteController($uibModalInstance, entity, RetailMappingPhotoCategory) {
        var vm = this;
        vm.retailMappingPhotoCategory = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            RetailMappingPhotoCategory.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
