(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('DroppointDeleteController',DroppointDeleteController);

    DroppointDeleteController.$inject = ['$uibModalInstance', 'entity', 'Droppoint'];

    function DroppointDeleteController($uibModalInstance, entity, Droppoint) {
        var vm = this;
        vm.droppoint = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            Droppoint.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
