(function() {
    'use strict';

    angular
        .module('sceaApp')
        .factory('TPopPlanogramSearch', TPopPlanogramSearch);

    TPopPlanogramSearch.$inject = ['$resource'];

    function TPopPlanogramSearch($resource) {
        var resourceUrl =  'api/_search/t-pop-planograms/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
