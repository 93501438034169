(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('OtherJobDialogController', OtherJobDialogController);

    OtherJobDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'TFileImageOtherJob', 'OtherJob'];

    function OtherJobDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, TFileImageOtherJob, OtherJob) {
        var vm = this;
        vm.otherJob = entity;

        if(vm.otherJob.$promise) {
        	vm.otherJob.$promise.then(function(data) {
        		TFileImageOtherJob.query({
        			formNo: data.formNo
        		}, function(data) {
        			vm.images = data;
        			vm.image = data[0];
        		});
        	});
        }
        
        var onSaveSuccess = function (result) {
            $scope.$emit('sceaApp:otherJobUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function (status) {
            vm.isSaving = true;
            
            vm.otherJob.status = status;
            
            if (vm.otherJob.id !== null) {
                OtherJob.update(vm.otherJob, onSaveSuccess, onSaveError);
            } else {
                OtherJob.save(vm.otherJob, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
