(function() {
    'use strict';

    angular.module('sceaApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('report-incentive', {
            parent: 'report',
            url: '/report/incentive',
            data: {
                authorities: ['ROLE_ADMIN', 'ARCO', 'SPV', 'VDO', 'VDR'],
                pageTitle: 'sceaApp.incentiveReport.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/report/incentive/incentive.html',
                    controller: 'IncentiveController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('global');
                	$translatePartialLoader.addPart('dashboard');
                	$translatePartialLoader.addPart('calendar');
                	$translatePartialLoader.addPart('area');
                    return $translate.refresh();
                }]
            }
        })
    }
})();
