(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('SpvCallPlanCreationController', SpvCallPlanCreationController);

    SpvCallPlanCreationController.$inject = ['$rootScope', '$scope', '$state', 'Calendar', 'TicketOrder'];

    function SpvCallPlanCreationController ($rootScope, $scope, $state, Calendar, TicketOrder) {
        var vm = this;
        var monthNow, yearNow;        
		
        vm.getSpvSummaries = getSpvSummaries;
        
		function init() {
			vm.months = Calendar.queryMonths();
			
			monthNow = moment().month() + 1;
			monthNow = monthNow.toString();
			vm.month = monthNow.toString();
			
			yearNow = moment().year();
			vm.years = [yearNow-1, yearNow];
			
			yearNow = yearNow.toString();
			vm.year = yearNow.toString();
			
			getSpvSummaries(yearNow, monthNow);
		}
		init();
		
		function getSpvSummaries(year, month) {
	        TicketOrder.querySpvSummaries({
	        	year: year,
	    		month: month
	        }, function(data) {
	        	vm.spvSummaries = data;
	        });			
		}
    }
})();
