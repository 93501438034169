(function () {
    'use strict';

    angular
        .module('sceaApp')
        .directive('disableNegativeInput', disableNegativeInput);

    disableNegativeInput.$inject = [];

    function disableNegativeInput() {
        return {
            require: 'ngModel',
            link: function ($scope, $element, $attrs, ngModelCtrl) {
                function isNotAllowed(key) {
                    var minusCode = [189, 109];
                    
                    return minusCode.includes(key);
                }

                $element.bind('keydown', function(event) {
                    var key = event.keyCode;
                    if(isNotAllowed(key))
                        event.preventDefault();
                });
            }
        }
    }
})();