(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('ReportImageController', ReportImageController);

    ReportImageController.$inject = ['$scope', '$state', '$sce', 'submenu', 'ApplicationSetting'];

    function ReportImageController ($scope, $state, $sce, submenu, ApplicationSetting) {
        var vm = this;

        vm.submenu = submenu.title;

    	function init() {
    		ApplicationSetting.queryByKey({
    			key: 'BIRT_VIEWER_ADDRESS'
    		}, function(data) {
    			var address = data.value;

    			if(vm.submenu == 'row-data') {
    				address += '?__report=image/image.rptdesign';
    			}

    			vm.birtViewerAddress = $sce.trustAsResourceUrl(address);
    		});
    	}
    	init();
    }
})();
