(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('PlanogramProductDeleteController',PlanogramProductDeleteController);

    PlanogramProductDeleteController.$inject = ['$uibModalInstance', 'entity', 'PlanogramProduct'];

    function PlanogramProductDeleteController($uibModalInstance, entity, PlanogramProduct) {
        var vm = this;
        vm.planogramProduct = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            PlanogramProduct.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
