(function () {
    'use strict';

    angular
        .module('sceaApp')
        .factory('User', User);

    User.$inject = ['$resource'];

    function User ($resource) {
        var service = $resource('api/users/:login', {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': { method:'POST' },
            'update': { method:'PUT' },
            'delete':{ method:'DELETE'},
            'updateManager': {
                url: 'api/usermanager',
                method:'POST'
            },
            'exportAsCsv': {
                url: 'api/users/csv',
                method: 'GET',
                transformResponse: function (data, headersGetter) {
                    var filename = "users.csv";
                    var blob = new Blob([data], {type: "text/csv;charset=UTF-8"});
                    saveAs(blob, filename, true);
                    return true;
                }
            },
            getInternal: {
                url: 'api/users/internal',
                method: 'GET',
                isArray: true
            },
            'andCriteria': {
                url: 'api/users%3AandCriteria',
                method: 'GET',
                isArray: true
            },
            'andOrCriteria': {
                url: 'api/users%3AandOrCriteria',
                method: 'POST',
                isArray: true
            }
        });

        return service;
    }
})();
