(function() {
    'use strict';

    angular
        .module('sceaApp')
        .factory('DashboardSettingSearch', DashboardSettingSearch);

    DashboardSettingSearch.$inject = ['$resource'];

    function DashboardSettingSearch($resource) {
        var resourceUrl =  'api/_search/dashboard-settings/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
