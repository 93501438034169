(function () {
    'use strict';

    angular
        .module('sceaApp')
        .controller('RisProductTargetDialogController', RisProductTargetDialogController);

    RisProductTargetDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'RisProductTarget', 'Outlet', 'OutletSearch', 'Product', 'ProductSearch'];

    function RisProductTargetDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, RisProductTarget, Outlet, OutletSearch, Product, ProductSearch) {
        var vm = this;

        vm.clear = clear;
        vm.getOutlet = getOutlet;
        vm.getProduct = getProduct;
        vm.save = save;

        vm.risProductTarget = entity;
        vm.products = Product.query();

        if (vm.risProductTarget.$promise) {
            vm.risProductTarget.$promise.then(function (data) {
                if (data.outletId != null) {
                    Outlet.get({ id: data.outletId }, function (outlet) {
                        vm.outlet = outlet;
                    });
                }
                if (data.productId != null) {
                    Product.get({ id: data.productId }, function (product) {
                        vm.product = product;
                    });
                }
            });
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });
        
        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;

            if (vm.outlet) {
                vm.risProductTarget.outletId = vm.outlet.id;
                vm.risProductTarget.outletOutletCode = vm.outlet.outletCode;
            }

            if (vm.product) {
                vm.risProductTarget.productId = vm.product.id;
                vm.risProductTarget.productProductCode = vm.product.productCode;
            }

            if (vm.risProductTarget.id !== null) {
                RisProductTarget.update(vm.risProductTarget, onSaveSuccess, onSaveError);
            } else {
                RisProductTarget.save(vm.risProductTarget, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('sceaApp:risProductTargetUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function getOutlet(value) {
            vm.loadingOutlet = true;
            return OutletSearch.query({
                query: '*' + value + '*'
            }).$promise.then(function (response) {
                vm.loadingOutlet = false;
                return response;
            }, function (response) {
                vm.loadingOutlet = false;
                AlertService.error(response.data.message);
            });
        }

        function getProduct(value) {
            vm.loadingProduct = true;
            return ProductSearch.query({
                query: '*' + value + '*'
            }).$promise.then(function (response) {
                vm.loadingProduct = false;
                return response;
            }, function (response) {
                vm.loadingProduct = false;
                AlertService.error(response.data.message);
            });
        }
    }
})();
