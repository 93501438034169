(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('AreaLevelDetailController', AreaLevelDetailController);

    AreaLevelDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'AreaLevel'];

    function AreaLevelDetailController($scope, $rootScope, $stateParams, entity, AreaLevel) {
        var vm = this;
        vm.areaLevel = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:areaLevelUpdate', function(event, result) {
            vm.areaLevel = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
