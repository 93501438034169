(function() {
	'use strict';
	angular.module('sceaApp').factory('Task', Task);

	Task.$inject = [ '$resource' ];

	function Task($resource) {
        var resourceUrl =  'bpm-rest/runtime/tasks/:taskId';

		return $resource(resourceUrl, {}, {
			'query': { method: 'GET', isArray: false },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': { method: 'POST' },
            'update': { method: 'PUT' },
            'delete':{ method: 'DELETE' },
            
            'getVariable': { 
            	url: 'bpm-rest/runtime/tasks/:taskId/variables/:variableName',
            	method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
		});
	}
})();
