(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('PromotionDtlController', PromotionDtlController);

    PromotionDtlController.$inject = ['$scope', '$state', '$stateParams', '$timeout', 'PromotionDtl', 'PromotionDtlSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'DataUtils', 'Upload'];

    function PromotionDtlController ($scope, $state, $stateParams, $timeout, PromotionDtl, PromotionDtlSearch, ParseLinks, AlertService, pagingParams, paginationConstants, DataUtils, Upload) {
      var vm = this;
      var parentVm = $scope.$parent.vm;

      vm.validUntil = moment(parentVm.promotion.validUntil).format('DD-MM-YYYY');
      
      vm.checkExpiredPromotion = checkExpiredPromotion;
      vm.loadAll = loadAll;
      vm.loadPage = loadPage;
      vm.predicate = pagingParams.predicate;
      vm.reverse = pagingParams.ascending;
      vm.clear = clear;
      vm.search = search;
      vm.searchQuery = pagingParams.search;
      vm.currentSearch = pagingParams.search;

      vm.setlistOrderData = setlistOrderData;
      vm.upload = upload;
      vm.transition = transition;
      console.log($stateParams.id)
      vm.loadAll();

      function checkExpiredPromotion () {
        var checkExpired = moment($scope.$parent.vm.promotion.validUntil).isAfter(moment());
        return (checkExpired) ? true : false;
      }

      function loadAll () {
          PromotionDtl.queryByPromotion({
          	promotionId: $stateParams.id,
            page: pagingParams.page - 1,
            size: paginationConstants.itemsPerPage,
            sort: sort()
          }, onSuccess, onError);

          function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
          }
          function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.promotionDtls = data;
            vm.page = pagingParams.page;
          }
          function onError(error) {
            AlertService.error(error.data.message);
          }
      }

      function loadPage (page) {
        vm.page = page;
      }

      function search (searchQuery) {
        if (!searchQuery){
          return vm.clear();
        }
        vm.links = null;
        vm.page = 1;
        vm.predicate = '_score';
        vm.reverse = false;
        vm.currentSearch = searchQuery;
      }

      function clear () {
        vm.links = null;
        vm.page = 1;
        vm.predicate = 'id';
        vm.reverse = true;
        vm.currentSearch = null;
      }

      function transition () {
        $state.transitionTo($state.$current, {
            page: vm.page,
            sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
            search: vm.currentSearch,
            id: $stateParams.id
        });
    }

      vm.listOrderData = {};

      vm.byteSize = DataUtils.byteSize;
      vm.openFile = DataUtils.openFile;

      function setlistOrderData ($file) {
        if($file) {
          vm.file = $file;
          vm.isUploading = false;
          vm.isUploadFinished = false;

          vm.listOrderData.dataContentType = $file.type;
          vm.listOrderData.fileName = $file.name;
          vm.listOrderData.size = $file.size;
        }
      }

      function upload (file) {
        vm.isUploading = true;

        Upload.upload({
          url: 'api/promotions/' + $stateParams.id + '/promotion-dtls/import',
          promotionId: $stateParams.id,
          file: file
        }).then(function (resp) {
          vm.loadAll();
          $timeout(function () {
            vm.file.result = 'Success ' + resp.config.data.file.name + 'uploaded. Response: ' + resp.data;
          });
        }, function (resp) {
          vm.loadAll();
          if (resp.status > 0) vm.file.error = resp.status + ': ' + resp.data;
          if (resp.headers('x-sceaapp-error') == 'error.importerror') AlertService.error('please check your excel file format');
        }, function (evt) {
          var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
          vm.file.progress = 'Progress: ' + progressPercentage + '% ';
        })

        vm.isUploadFinished = true;
      }
    }
})();
