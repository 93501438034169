(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('PromotionDtlDetailController', PromotionDtlDetailController);

    PromotionDtlDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PromotionDtl', 'Outlet', 'PromoMaterial', 'Promotion'];

    function PromotionDtlDetailController($scope, $rootScope, $stateParams, entity, PromotionDtl, Outlet, PromoMaterial, Promotion) {
        var vm = this;
        vm.promotionDtl = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:promotionDtlUpdate', function(event, result) {
            vm.promotionDtl = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
