(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('TPopDtlDetailController', TPopDtlDetailController);

    TPopDtlDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'TPopDtl', 'TPop', 'Pop', 'Product'];

    function TPopDtlDetailController($scope, $rootScope, $stateParams, entity, TPopDtl, TPop, Pop, Product) {
        var vm = this;
        vm.tPopDtl = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:tPopDtlUpdate', function(event, result) {
            vm.tPopDtl = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
