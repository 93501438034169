(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('VdeOutletAddDialogController', VdeOutletAddDialogController);

    VdeOutletAddDialogController.$inject = ['$scope', '$state', '$uibModalInstance', '$stateParams', 'outlets', 'Outlet', 'TicketOrder'];

    function VdeOutletAddDialogController($scope, $state, $uibModalInstance, $stateParams, outlets, Outlet, TicketOrder) {
        var vm = this;

        vm.outlets = outlets;
    	vm.reverse = true;
    	vm.predicate = 'outletCode';

        vm.addOutlet = addOutlet;
    	vm.clear = clear;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function addOutlet(outlet) {
        	TicketOrder.save({
            	outletId: outlet.id,
            	employeeId: parseInt($stateParams.vdeId),
            	calendarId: parseInt($stateParams.calendarId),
            	flagAsynch: 0,
            	ticketWfStat: 0
        	});
        }
    }
})();
