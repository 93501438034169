(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('FixturesDeleteController',FixturesDeleteController);

    FixturesDeleteController.$inject = ['$uibModalInstance', 'entity', 'Fixtures'];

    function FixturesDeleteController($uibModalInstance, entity, Fixtures) {
        var vm = this;
        vm.fixtures = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            Fixtures.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
