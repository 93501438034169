(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('SelloutDetailController', SelloutDetailController);

    SelloutDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Sellout', 'Outlet', 'ProductCategory'];

    function SelloutDetailController($scope, $rootScope, $stateParams, entity, Sellout, Outlet, ProductCategory) {
        var vm = this;
        vm.sellout = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:selloutUpdate', function(event, result) {
            vm.sellout = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
