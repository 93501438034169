(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('UserManagementController', UserManagementController);

    UserManagementController.$inject = ['$scope', '$state','Principal', 'User', 'UserSearch','ParseLinks', 'paginationConstants', 'AlertService','pagingParams','JhiLanguageService'];

    function UserManagementController($scope, $state, Principal, User, UserSearch, ParseLinks, paginationConstants, AlertService, pagingParams, JhiLanguageService) {
        var vm = this;

        vm.profiles = [ null, 'ROLE_ADMIN', 'ARCO', 'SPV', 'PC', 'VDO', 'VDE' ];
        vm.roleUser = vm.profiles[0];
        vm.currentAccount = null;
        vm.languages = null;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.page = pagingParams.page;
        vm.totalItems = null;
        vm.users = [];
        vm.filter = {
            id: pagingParams.id,
            login: pagingParams.login,
            email: pagingParams.email,
            firstName: pagingParams.firstName,
            lastName:pagingParams.lastName,
            employeeCode: pagingParams.employeeCode,
            managerName: pagingParams.managerName,
            activated: pagingParams.activated ? pagingParams.activated : '',
            profiles: pagingParams.profiles
        };

        vm.ngModelOpt = { debounce: 200 };

        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.setActive = setActive;
        vm.exportAsCsv = exportAsCsv;
        vm.buildQuery = buildQuery;
        vm.transition = transition;
        vm.filterChange = filterChange;

        vm.loadAll();

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        Principal.identity().then(function(account) {
            vm.currentAccount = account;
        });

        function exportAsCsv() {
            User.exportAsCsv();
        }

        function loadAll () {
            var query = {
                page: vm.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort()
            };

            query = Object.assign(query, vm.buildQuery());

            User.andCriteria(query, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.users = data;
                vm.transition();
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.loadAll();
        }

        function transition () {
            $state.go($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                id: vm.filter.id,
                login: vm.filter.login,
                email: vm.filter.email,
                firstName: vm.filter.firstName,
                lastName: vm.filter.lastName,
                employeeCode: vm.filter.employeeCode,
                managerName: vm.filter.managerName,
                activated: vm.filter.activated ? vm.filter.activated : null,
                profiles: vm.filter.profiles
            }, {notify: false});
        }

        function buildQuery() {
            var query = {};
            if (vm.filter.id) {
                query = Object.assign(query, {'id.equals': vm.filter.id});
            }
            if (vm.filter.login) {
                query = Object.assign(query, {'login.contains': vm.filter.login});
            }
            if (vm.filter.email) {
                query = Object.assign(query, {'email.contains': vm.filter.email});
            }
            if (vm.filter.firstName) {
                query = Object.assign(query, {'firstName.contains': vm.filter.firstName});
            }
            if (vm.filter.lastName) {
                query = Object.assign(query, {'lastName.contains': vm.filter.lastName});
            }
            if (vm.filter.employeeCode) {
                query = Object.assign(query, {'employeeCode.contains': vm.filter.employeeCode});
            }
            if (vm.filter.managerName) {
                query = Object.assign(query, {'managerName.contains': vm.filter.managerName});
            }
            if (vm.filter.activated) {
                query = Object.assign(query, {'activated.equals': vm.filter.activated});
            }
            if (vm.filter.profiles) {
                query = Object.assign(query, {'authorities.contains': vm.filter.profiles});
            }

            return query;
        }

        function setActive (user, isActivated) {
            user.activated = isActivated;
            User.update(user, function () {
                vm.loadAll();
                vm.clear();
            });
        }

        function filterChange() {
            vm.page = 1;
            vm.loadAll();
        }
    }
})();
