(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('FormulaFieldDialogController', FormulaFieldDialogController);

    FormulaFieldDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$filter', '$location', '$anchorScroll', 
                                            'entity', 'AlertService', 'FormulaField', 'ProjectColumn'];

    function FormulaFieldDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $filter, $location, $anchorScroll, 
    		entity, AlertService, FormulaField, ProjectColumn) {
        var vm = this;
        vm.formulaField = entity;
        vm.formulas = [];
        vm.projectColumn = [];
        vm.validationFormula = {};
        vm.dynamicPopover = 'app/entities/formula-widget/formula-widgets.html';
        vm.optionsField = ProjectColumn.query({isDecimalType: true}, function(result) {
        	angular.forEach(result, function(variable, key) {
	    		vm.projectColumn[variable.columnId] = {};
	 			vm.projectColumn[variable.columnId].columnName = variable.columnName;
	 			
	 			if(result.length-1 == key) {
		 	        generateFormula();
	 			}
        	});
        });
        
        function generateFormula() {
            if (vm.formulaField.$promise) {
    	    	vm.formulaField.$promise.then(function(data) {
    	    		var tempFormula = data.formula.split(" ");
                	
                    angular.forEach(tempFormula, function(formula, key) {
        	    		var label = null;
        	    		var value = null;
        	    		var type = null;
        	    		
            			if(formula.substring(0, 1) === "@") {                        	
                        	label = generateFormulaSellout(formula);
            				value = formula;
            				type = 'exponent';

                			vm.addFormula(label, value, type);
            			} else if(formula.substring(0, 1) === "#") {
            				var id = formula.replace(/#/g, '');
            				label = vm.projectColumn[id].columnName;
            				value = formula;
            				type = 'exponent';

                			vm.addFormula(label, value, type);
            			} else {
                        	label = formula;
            				value = formula;
            				type = 'opr';

                			vm.addFormula(label, value, type);
            			}            			
                    });
    	    	});
            }
        }
        
        vm.generateField = function(field) {
    		var label = field.columnName;
    		var value = '#' + field.columnId;
    		var type = 'exponent';
    		
			vm.addFormula(label, value, type);        	
        }
        
        function generateFormulaSellout(formula) {
			var parse = '';
			
        	var sellout = formula.substring(16, 22);
        	if(sellout == 'outlet') {
        		parse += 'Sell Out Outlet ';
        	} else if(sellout == 'branch') {
        		parse += 'Sell Out Branch ';
        	}

        	var properties = formula.substring(30, formula.length-1);
        	properties = properties.replace(/#/g, '');
        	properties = properties.replace(/inYear/g, 'Input Year');
        	properties = properties.replace(/lpYear/g, 'Last Project Year');
        	properties = properties.replace(/inMonth/g, 'Input Month');
        	properties = properties.replace(/lpMonth/g, 'Last Project Month');
        	properties = properties.split(",");
        	
        	angular.forEach(properties, function(result, key) {
        		if(key == 0) {
    				parse += 'from ';
        		}
        		if(key == 2) {
    				parse += ' to ';
        		}

        		if(key%2 != 0) {
    				parse += '/';
        		}

				parse += '{';
				
				var separators = ['\\\+', '-'];
				var tempParse = result.split(new RegExp(separators.join('|'), 'g'));

	    		if(tempParse.length > 1){
	    			if(tempParse[0].length < 3) {
	        			parse += $filter('translate')('sceaApp.calendar.months.' + tempParse[0]);
	        			parse += result.substring(tempParse[0].length, tempParse[0].length+1);
	    				parse += tempParse[1];
	    			} else {
	    				parse += result;
	    			}
	    		} else {
	    			if(result.length < 3) {
	    				parse += $filter('translate')('sceaApp.calendar.months.' + result);
	    			} else {
	    				parse += result;		    				
	    			}
	    		}

				parse += '}';
        	}); 
        	
			return parse;     	
        }
    	
	    vm.addFormula = function(label, value, type) {
    		if(vm.formulas[vm.formulas.length-1]) {
		    	if(vm.formulas[vm.formulas.length-1].type == 'exponent' && type == 'exponent') {
		    		vm.formulas.push({
			    		label: '+',
			    		value: '+',
			    		type: 'opr'
			    	});
		    	}
    		}
    		
	    	vm.formulas.push({
	    		label: label,
	    		value: value,
	    		type: type
	    	});
		}  
	    
	    vm.removeFormula = function(){
    		vm.formulas.splice(vm.formulas.length-1, 1); 
    	}
	    
	    vm.clearFormula = function(){
    		vm.formulas = [];
    	} 
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sceaApp:formulaFieldUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
        	if(vm.validationFormula.status) {
                vm.isSaving = true;
                if (vm.formulaField.id !== null) {
                    FormulaField.update(vm.formulaField, onSaveSuccess, onSaveError);
                } else {
                    FormulaField.save(vm.formulaField, onSaveSuccess, onSaveError);
                }
        	} else {
                AlertService.error(vm.validationFormula.message);
        	}
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        
        vm.initWidget = function() {
        	gotoBottom();
        	vm.sellout = {
				formula : 'outlet',
				optionFromYear : 'inYear',
				oprFromYear : '+',
				inputFromYear : 0,
				optionFromMonth : 'inMonth',
				oprFromMonth : '+',
				inputFromMonth : 0,
				optionToYear : 'inYear',
				oprToYear : '+',
				inputToYear : 0,
				optionToMonth : 'inMonth',
				oprToMonth : '+',
				inputToMonth : 0
			}
        }
        
        function gotoBottom() {
            $location.hash('formulaWidget');            
            $anchorScroll();        	
        }
        
        vm.addSellout = function() {
        	var formula = '@formulaService.';
        	
        	if(vm.sellout.formula == 'outlet') {
        		formula += 'outletSellout(';
        	} else if(vm.sellout.formula == 'branch') {
        		formula += 'branchSellout(';
            }

    		formula += '#' + vm.sellout.optionFromYear;
        	if(vm.sellout.inputFromYear != 0) {
            	if(vm.sellout.inputFromYear > 0) {
            		formula += vm.sellout.oprFromYear + vm.sellout.inputFromYear;
            	} else {
            		formula += vm.sellout.inputFromYear;            		
            	}
        	}
    		formula += ',';    		

    		formula += '#' + vm.sellout.optionFromMonth;
        	if(vm.sellout.inputFromMonth != 0) {
            	if(vm.sellout.inputFromMonth > 0) {
            		formula += vm.sellout.oprFromMonth + vm.sellout.inputFromMonth;
            	} else {
            		formula += vm.sellout.inputFromMonth;            		
            	}
        	}
    		formula += ',';

    		formula += '#' + vm.sellout.optionToYear;
        	if(vm.sellout.inputToYear != 0) {
            	if(vm.sellout.inputToYear > 0) {
            		formula += vm.sellout.oprToYear + vm.sellout.inputToYear;
            	} else {
            		formula += vm.sellout.inputToYear;            		
            	}
        	}
    		formula += ',';    		

    		formula += '#' + vm.sellout.optionToMonth;
        	if(vm.sellout.inputToMonth != 0) {
            	if(vm.sellout.inputToMonth > 0) {
            		formula += vm.sellout.oprToMonth + vm.sellout.inputToMonth;
            	} else {
            		formula += vm.sellout.inputToMonth;            		
            	}
        	}
    		
    		formula += ')';
    		
        	var label = generateFormulaSellout(formula);
        	var value = formula;
        	var type = 'exponent';
        	
			vm.addFormula(label, value, type);

        	vm.sellout = {
        		formula: 'outlet',
        		optionFromYear: 'inYear',
        		oprFromYear: '+',
        		inputFromYear: 0,
        		optionFromMonth: 'inMonth',
        		oprFromMonth: '+',
        		inputFromMonth: 0,
        		optionToYear: 'inYear',
        		oprToYear: '+',
        		inputToYear: 0,
        		optionToMonth: 'inMonth',
        		oprToMonth: '+',
        		inputToMonth: 0
        	}
        }
        
        $scope.$watchCollection('vm.formulas', function(formulas, old) {
        	vm.validationFormula.status = true;
        	
        	if(formulas != old) {
        		if(formulas.length == 0) {
        			vm.formulaField.formula = null;
        		} else {
                	var tempFormula = '';
                	var validationString = '';
                	angular.forEach(formulas, function(formula, key) {
        			    if(formula.type == 'exponent') {
    						if(key > 0){
    	    					try {
    								var item = math.parse(formulas[key-1].value);
    					    		if(item.valueType == "number") {
    					    			tempFormula += " * " + formula.value;
    							        validationString += "*1";
    					    		} else { 
    							        tempFormula += formula.value;
    							        validationString += "1"; 
    					    		} 
        						} catch (e) {
        							tempFormula += ' ';
        							tempFormula += formula.value;
        					        validationString += "1";  
        						}
        						
	            		    	if(formulas[key-1].type == 'exponent') {
	            		    		var temp = vm.formulas[key];
	            		    		vm.formulas[key] = { label: '+', value: '+', type: 'opr' };
	            		    		vm.formulas.push(temp);
	            		    		
					    			tempFormula += " + " + formula.value;
							        validationString += "+1";
	            		    	}
    						} else {
    							tempFormula += formula.value;
    						    validationString += "1";  
    						}
    			        } else {
    				        try {
    							if(key > 0){
    								var item1 = math.parse(formulas[key-1].value);
    								var item2 = math.parse(formulas[key].value);
    					    		if(item1.valueType == "number") {
    		    				        tempFormula += formula.value;
    		    				        validationString += formula.value;			    			
    					    		}
    							} else {
		    				        tempFormula += formula.value;
		    				        validationString += formula.value;
    							}
    						} catch (e) {
								tempFormula += ' ';
	    				        tempFormula += formula.value;
	    				        validationString += formula.value;
    						}
    			        }
                    	
                    	if(vm.formulas.length-1 == key) {
                    		vm.formulaField.formula = tempFormula;

            	        	try { 
            		        	var parser = math.parser();
            		        	var result = parser.eval(validationString); 
            		        	if(result == "Infinity") {
            		        		vm.validationFormula.status = false;
            		        		vm.validationFormula.message = "Results Infinity";
            		        	}  
            	            }
            	            catch(error) {
            	            	vm.validationFormula.status = false;
            	        		vm.validationFormula.message = "Invalid Formula";
            	            } 
                    	}
                	});           			
        		}     		
        	}
        });
    }
})();
