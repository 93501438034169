(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('ReportPopDashboardDetailController', ReportPopDashboardDetailController);

    ReportPopDashboardDetailController.$inject = ['$uibModalInstance', 'outlet', 'entity'];

    function ReportPopDashboardDetailController ($uibModalInstance, outlet, entity) {
        var vm = this;

        vm.title = null
        vm.product = []
        
        vm.predicate = 'product'
        vm.reverse = true // desc
        vm.sort = sort


        onInit();

        function onInit() {
            vm.title = outlet.name

            vm.product = entity.map(function (each) {
                return {
                    name : each.display.productProductCode,
                    qty : each.display.qty,
                    item : each.popDtls.map(function (item) {
                        return {
                            name : item.popPopName,
                            qty : item.qty
                        }
                    })
                }
            });

        }

        vm.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };

        function sort(propertyName) {
            vm.reverse = (vm.predicate == propertyName) ? !vm.reverse : false;
            vm.predicate = propertyName;
        }

    }
})();
