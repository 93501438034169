(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('ReportPopChecklistController', ReportPopChecklistController);

    ReportPopChecklistController.$inject = ['$scope', '$state', 'DynamicForm', 'Channel', 'OutletAccount', 'Area', 'User', 'Calendar', 'ApplicationSetting', '$window'];

    function ReportPopChecklistController ($scope, $state, DynamicForm, Channel, OutletAccount, Area, User, Calendar, ApplicationSetting, $window) {
        var vm = this;
        vm.getBranchs = getBranchs;
        vm.getCities = getCities;
        vm.getVdes = getVdes;
        vm.getSpvs = getSpvs;
        vm.getPcs = getPcs;
        vm.getMonths = getMonths;
        vm.getWeeks = getWeeks;
        vm.isDisplayOnly = false;

        ApplicationSetting.queryByKey({
            key: 'BIRT_VIEWER_ADDRESS'
        }, function(data) {
            vm.birtViewerAddress = data.value += '?__report=pop/PopChecklist.rptdesign';
        });

        vm.bizUnits = DynamicForm.getBusinessUnit(function(result) {
            vm.selectedBizUnit = result[0];
        });

        vm.types = ['ALL', 'Targeted'];
        vm.selectedType = vm.types[0];

        vm.channels = Channel.query();

        vm.accounts = OutletAccount.query();

        vm.roles = ['ALL', 'VDE', 'PC'];
        vm.selectedRole = vm.roles[0];

		vm.area = {
			regions: Area.queryByLevelId({areaLevelId : 1},
				function(result) {
					if(vm.area.region != null) {
						vm.getBranchs(vm.area.region.id);
					}
			}),
			branchs: [],
			cities: []
		}

		vm.employee = {
			vdos: User.query({roleName: 'VDO'}, function(result) {
				if(vm.employee.vdo != null) {
					vm.getVdes(vm.employee.vdo.id);
				}
			}),
			vdes: [],
			arcos: User.query({roleName: 'ARCO'}, function(result) {
                if(vm.employee.arco != null) {
                    vm.getSpvs(vm.employee.arco.id);
                }
            }),
            spvs: [],
            pcs: []
		}

		vm.calendar = {
			years: Calendar.queryYears(),
			months: Calendar.queryMonths(),
			weeks: []
		}

		function getBranchs(regionId) {
			vm.selectedBranch = null;
			vm.selectedCity = null;
			if(regionId) {
			    vm.area.branchs = Area.queryByParentIdAndLevelId({parentId: regionId, areaLevelId : 2});
			}
        }

        function getCities(branchId) {
			vm.area.cities = [];
			vm.selectedCity = null;
        	if(branchId) {
        		vm.area.cities = Area.queryByParentIdAndLevelId({parentId: branchId, areaLevelId : 3});
        	}
        }

        function getVdes(managerId) {
        	vm.employee.vdes = [];
        	vm.selectedVde = null;
        	if(managerId) {
        		vm.employee.vdes = User.query({roleName: 'VDE', managerId: managerId});
        	}
        }

        function getSpvs(managerId) {
            vm.employee.spvs = [];
            vm.selectedSpv = null;
            vm.employee.pcs = [];
            vm.selectedPc = null;
            if(managerId) {
                vm.employee.spvs = User.query({roleName: 'SPV', managerId: managerId});
            }
        }

        function getPcs(managerId) {
            vm.employee.pcs = [];
            vm.selectedPc = null;
            if(managerId) {
                vm.employee.pcs = User.query({roleName: 'PC', managerId: managerId});
            }
        }

        function getMonths() {
            vm.selectedMonth = null;
            vm.selectedWeek = null;
        }

        function getWeeks(year, month) {
			Calendar.queryWeeks({year: year, month: month}, function(result) {
				vm.calendar.weeks = result;
			});
        }

        vm.generate = function() {
        	var url = vm.birtViewerAddress.replace('frameset', 'run');
        	url = url.concat('&__format=xlsx');

        	url = url.concat("&display="+vm.isDisplayOnly);
        	url = url.concat("&category="+vm.selectedBizUnit.id);
        	url = url.concat("&type="+vm.selectedType.toLowerCase());
        	if(vm.selectedChannel) {
        	    url = url.concat("&channel="+vm.selectedChannel.id);
        	}
        	if(vm.selectedAccount) {
                url = url.concat("&account="+vm.selectedAccount.id);
            }
            url = url.concat("&role="+vm.selectedRole);
            if(vm.selectedRole == 'VDE') {
                var allVdo = vm.selectedVdo==null;
                url = url.concat("&all_vdo="+allVdo);
                if(vm.selectedVdo) {
                    url = url.concat("&VDO="+vm.selectedVdo.id);
                }
                if(vm.selectedVde) {
                    url = url.concat("&VDE="+vm.selectedVde.id);
                }
            } else if(vm.selectedRole == 'PC') {
                var allArco = vm.selectedArco==null;
                url = url.concat("&all_arco="+allArco);
                if(vm.selectedArco) {
                    url = url.concat("&ARCO="+vm.selectedArco.id);
                }
                if(vm.selectedSpv) {
                    url = url.concat("&SPV="+vm.selectedSpv.id);
                }
                if(vm.selectedPc) {
                    url = url.concat("&PC="+vm.selectedPc.id);
                }
            }
            if(vm.selectedRegion) {
                url = url.concat("&Region="+vm.selectedRegion.id);
            }
            if(vm.selectedBranch) {
                url = url.concat("&Branch="+vm.selectedBranch.id);
            }
            if(vm.selectedCity) {
                url = url.concat("&City="+vm.selectedCity.id);
            }
            if(vm.selectedYear) {
                url = url.concat("&year="+vm.selectedYear);
            }
            if(vm.selectedMonth) {
                url = url.concat("&month="+vm.selectedMonth);
            }
            if(vm.selectedWeek) {
                url = url.concat("&week="+vm.selectedWeek);
            }

            $window.open(url, '_blank');
        }
    }
})();
