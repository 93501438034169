(function() {
    'use strict';

    angular
        .module('sceaApp')
        .factory('TPopDtlSearch', TPopDtlSearch);

    TPopDtlSearch.$inject = ['$resource'];

    function TPopDtlSearch($resource) {
        var resourceUrl =  'api/_search/t-pop-dtls/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
