(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('PopInvDetailController', PopInvDetailController);

    PopInvDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PopInv', 'Pop'];

    function PopInvDetailController($scope, $rootScope, $stateParams, entity, PopInv, Pop) {
        var vm = this;
        vm.popInv = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:popInvUpdate', function(event, result) {
            vm.popInv = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
