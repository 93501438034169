(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('RetailMappingSubCategoryDeleteController',RetailMappingSubCategoryDeleteController);

    RetailMappingSubCategoryDeleteController.$inject = ['$uibModalInstance', 'entity', 'RetailMappingSubCategory'];

    function RetailMappingSubCategoryDeleteController($uibModalInstance, entity, RetailMappingSubCategory) {
        var vm = this;
        vm.retailMappingSubCategory = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            RetailMappingSubCategory.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
