(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('PopDetailController', PopDetailController);

    PopDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Pop', 'ProductCategory'];

    function PopDetailController($scope, $rootScope, $stateParams, entity, Pop, ProductCategory) {
        var vm = this;
        vm.pop = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:popUpdate', function(event, result) {
            vm.pop = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
