(function() {
    'use strict';
    angular
        .module('sceaApp')
        .factory('Calendar', Calendar);

    Calendar.$inject = ['$resource', 'DateUtils'];

    function Calendar ($resource, DateUtils) {
        var resourceUrl =  'api/calendars/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.startDate = DateUtils.convertLocalDateFromServer(data.startDate);
                    data.endDate = DateUtils.convertLocalDateFromServer(data.endDate);
                    return data;
                }
            },
            'getCurrent': {
            	url: 'api/calendars/current',
                method: 'GET',
                transformResponse: function (data) {
                    if(!data) {
                        return;
                    }
                    data = angular.fromJson(data);
                    data.startDate = DateUtils.convertLocalDateFromServer(data.startDate);
                    data.endDate = DateUtils.convertLocalDateFromServer(data.endDate);
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data.startDate = DateUtils.convertLocalDateToServer(data.startDate);
                    data.endDate = DateUtils.convertLocalDateToServer(data.endDate);
                    return angular.toJson(data);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data.startDate = DateUtils.convertLocalDateToServer(data.startDate);
                    data.endDate = DateUtils.convertLocalDateToServer(data.endDate);
                    return angular.toJson(data);
                }
            },
            'queryYears': {
            	url: 'api/calendars/years',
            	method: 'GET',
            	isArray: true
            },
            'queryMonths': {
            	url: 'api/calendars/months',
            	method: 'GET',
            	isArray: true
            },
            'queryWeeks': {
            	url: 'api/calendars/weeks',
            	method: 'GET',
            	isArray: true
            }
        });
    }
})();
