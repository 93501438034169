(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('AdjustedAccumulationDetailController', AdjustedAccumulationDetailController);

    AdjustedAccumulationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'AdjustedAccumulation'];

    function AdjustedAccumulationDetailController($scope, $rootScope, $stateParams, entity, AdjustedAccumulation) {
        var vm = this;
        vm.adjustedAccumulation = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:adjustedAccumulationUpdate', function(event, result) {
            vm.adjustedAccumulation = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
