(function() {
    'use strict';

    angular
        .module('sceaApp')
        .factory('TicketResultSearch', TicketResultSearch);

    TicketResultSearch.$inject = ['$resource'];

    function TicketResultSearch($resource) {
        var resourceUrl =  'api/_search/ticket-results/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
