(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('IncentiveController', IncentiveController);

    IncentiveController.$inject = ['$scope', '$state', '$uibModal', '$filter', 'Dashboard', 'DashboardParams', 'orderByFilter'];

    function IncentiveController ($scope, $state, $uibModal, $filter, Dashboard, DashboardParams, orderByFilter) {
        var vm = this;

        vm.dashboardDatas = [];
		vm.dashboardParams = null;
		vm.exportVdeProgressing = false;
    	vm.pageDashboard = 1;

    	vm.loadMoreDashboard = loadMoreDashboard;

        vm.loadDashboard = function() {
            Dashboard.query(function(result) {
            	vm.dashboardDatas = result;

                for (var i = 0; i < result.length; i++) {
					if (isNaN(vm.dashboardDatas[i].achievement)) {
						vm.dashboardDatas[i].achievement = 0;
					} else {
						vm.dashboardDatas[i].achievement = result[i].achievement;
					}
				}
            });
        };
        vm.loadDashboard();

        function loadMoreDashboard() {
            vm.disableLoadDashboard = true;

            Dashboard.query({
                page: vm.pageDashboard
            }, function(data, headers) {
            	angular.forEach(data, function(dashboardData) {
					if (isNaN(dashboardData.achievement)) {
						dashboardData.achievement = 0;
					} else {
						dashboardData.achievement = dashboardData.achievement;
					}

                	vm.dashboardDatas.push(dashboardData);
            	});

            	var totalNow = vm.dashboardDatas.length + data.length;
                var totalItems = headers('X-Total-Count');

                if(totalNow == totalItems) {
                	vm.disableLoadDashboard = true;
                } else {
                	vm.disableLoadDashboard = false;
                }
            	vm.pageDashboard++;
            });
        }

        vm.loadDashboardParams = function() {
    		DashboardParams.get(function(result){
    			vm.dashboardParams = result;
    		});
        };
        vm.loadDashboardParams();

        vm.exportVde = function() {
        	vm.exportVdeProgressing = true;
            Dashboard.exportExcel().$promise.then(function(data){
            	vm.exportVdeProgressing = false;
            },function(error){
            	// error
            });
        }

        vm.openOption = function() {
            var modalInstance = $uibModal.open({
            	animation: $scope.animationsEnabled,
            	templateUrl: 'app/report/incentive/incentive-params/incentive-params-dialog.html',
            	controller: 'IncentiveParamsDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
            	size: "lg",
                resolve: {
                    entity: ['DashboardParams', function(DashboardParams) {
                        return vm.dashboardParams;
                    }],
                    mainTranslatePartialLoader: ['$stateParams', '$translate', '$translatePartialLoader',
                        function ($stateParams, $translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('dashboardParams');
                        $translatePartialLoader.addPart('storeType');
                        $translatePartialLoader.addPart('callPlanType');
                        $translatePartialLoader.addPart('calendar');
                        return $translate.refresh();
                    }]
                }
            });

            modalInstance.result.then(function (result) {
            	vm.dashboardParams = result;
                vm.loadDashboard();
            }, function () {
                vm.loadDashboardParams();
            });
        }
    }
})();
