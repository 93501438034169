(function() {
    'use strict';

    angular.module('sceaApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('project-list', {
            parent: 'merchandiser-activity',
            url: '/merchandiser-activity/store-completion/project-list',
            data: {
                authorities: ['ROLE_ADMIN', 'SPV', 'VDO'],
                pageTitle: 'sceaApp.projectList.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/merchandiser-activity/project-list/projects.html',
                    controller: 'ProjectsController',
                    controllerAs: 'vm'
                },
	            'project-list@project-list': {
	                templateUrl: 'app/merchandiser-activity/project-list/project-lists.html'
	            },
	            'columns@project-list': {
	                templateUrl: 'app/merchandiser-activity/project-list/columns.html'
	            }
	        },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('global');
                	$translatePartialLoader.addPart('projectList');
                	$translatePartialLoader.addPart('attachment');
                    return $translate.refresh();
                }]
            }
        })
        .state('project-list.new', {
            parent: 'project-list',
            url: '/new-project',
            data: {
                authorities: ['ROLE_ADMIN', 'VDO', 'VDR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/merchandiser-activity/project-list/project-list-dialog.html',
                    controller: 'ProjectListDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md'
                }).result.then(function() {
                    $state.go('project-list', null, { reload: true });
                }, function() {
                    $state.go('project-list');
                });
            }]
        });
    }
})();
