(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('ReportPopController', ReportPopController);

    ReportPopController.$inject = ['$scope', '$state', '$sce', 'submenu', 'ApplicationSetting'];

    function ReportPopController ($scope, $state, $sce, submenu, ApplicationSetting) {
        var vm = this;

        vm.submenu = submenu.title;

    	function init() {
    		ApplicationSetting.queryByKey({
    			key: 'BIRT_VIEWER_ADDRESS'
    		}, function(data) {
    			var address = data.value;

    			if(vm.submenu == 'row-data') {
    				address += '?__report=pop/PopRowData.rptdesign';
    			} else if(vm.submenu == 'checklist') {
    				address += '?__report=pop/PopChecklist.rptdesign';
    			} else if(vm.submenu == 'difference') {
    				address += '?__report=pop/PopDisplayDifference.rptdesign';
                } else if(vm.submenu == 'summary-request') {
    				address += '?__report=pop/PopSummaryRequest.rptdesign';
    			} else if(vm.submenu == 'request-row-data') {
                    address += '?__report=pop/PopRequestRowData.rptdesign';
                } else if(vm.submenu == 'inventory-valuation') {
                    address += '?__report=pop/InventoryValuation.rptdesign';
                }   else if(vm.submenu == 'stock-move') {
                    address += '?__report=pop/StockMove.rptdesign';
                }

    			vm.birtViewerAddress = $sce.trustAsResourceUrl(address);
    		});
    	}
    	init();
    }
})();
