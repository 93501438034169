(function() {
    'use strict';

    angular
        .module('sceaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('promotion', {
            parent: 'entity',
            url: '/promotion?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN', 'VDR'],
                pageTitle: 'sceaApp.promotion.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/promotion/promotions.html',
                    controller: 'PromotionController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('promotion');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('promotion-detail', {
            parent: 'entity',
            url: '/promotion/{id}?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN', 'VDR'],
                pageTitle: 'sceaApp.promotion.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/promotion/promotion-detail.html',
                    controller: 'PromotionDetailController',
                    controllerAs: 'vm'
                },
	            'promotion-dtls@promotion-detail': {
                    templateUrl: 'app/entities/promotion-dtl/promotion-dtls.html',
                    controller: 'PromotionDtlController',
                    controllerAs: 'vm'
	            }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('promotionDtl');
                    $translatePartialLoader.addPart('promotion');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Promotion', function($stateParams, Promotion) {
                    return Promotion.get({id : $stateParams.id});             
                }]
            }
        })
        .state('promotion-dtl-detail', {
            parent: 'promotion-detail',
            url: '/promotion-dtl/{dtlId}',
            data: {
                authorities: ['ROLE_ADMIN', 'VDR'],
                pageTitle: 'sceaApp.promotionDtl.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/promotion-dtl/promotion-dtl-detail.html',
                    controller: 'PromotionDtlDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('promotionDtl');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PromotionDtl', function($stateParams, PromotionDtl) {
                    return PromotionDtl.get({id : $stateParams.dtlId});
                }]
            }
        })
        .state('promotion-dtl-new', {
            parent: 'promotion-detail',
            url: '/promotion-dtl/1/new',
            data: {
                authorities: ['ROLE_ADMIN', 'VDR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/promotion-dtl/promotion-dtl-dialog.html',
                    controller: 'PromotionDtlDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        promotion: ['Promotion', function(Promotion) {
                            return Promotion.get({id : $stateParams.id});             
                        }],
                        entity: function () {
                            return {
                                allocationQty: null,
                                id: null,
                                outletId: null,
                                outletOutletName: null,
                                promoMaterialId: null,
                                promoMaterialName: null,
                                promotionId: null,
                                promotionName: null,
                                received: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('promotion-detail', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('promotion-dtl-edit', {
            parent: 'promotion-detail',
            url: '/promotion-dtl/{dtlId}/edit',
            data: {
                authorities: ['ROLE_ADMIN', 'VDR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/promotion-dtl/promotion-dtl-dialog.html',
                    controller: 'PromotionDtlDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        promotion: ['Promotion', function(Promotion) {
                            return Promotion.get({id : $stateParams.id});             
                        }],
                        entity: ['PromotionDtl', function(PromotionDtl) {
                            return PromotionDtl.get({id : $stateParams.dtlId});
                        }]
                    }
                }).result.then(function() {
                    $state.go('promotion-detail', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('promotion-dtl-delete', {
            parent: 'promotion-detail',
            url: '/promotion-dtl/{dtlId}/delete',
            data: {
                authorities: ['ROLE_ADMIN', 'VDR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/promotion-dtl/promotion-dtl-delete-dialog.html',
                    controller: 'PromotionDtlDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        promotion: ['Promotion', function(Promotion) {
                            return Promotion.get({id : $stateParams.id});             
                        }],
                        entity: ['PromotionDtl', function(PromotionDtl) {
                            return PromotionDtl.get({id : $stateParams.dtlId});
                        }]
                    }
                }).result.then(function() {
                    $state.go('promotion-detail', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('promotion.new', {
            parent: 'promotion',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN', 'VDR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/promotion/promotion-dialog.html',
                    controller: 'PromotionDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                validFrom: null,
                                validUntil: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('promotion', null, { reload: true });
                }, function() {
                    $state.go('promotion');
                });
            }]
        })
        .state('promotion.edit', {
            parent: 'promotion',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN', 'VDR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/promotion/promotion-dialog.html',
                    controller: 'PromotionDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Promotion', function(Promotion) {
                            return Promotion.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('promotion', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('promotion.delete', {
            parent: 'promotion',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN', 'VDR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/promotion/promotion-delete-dialog.html',
                    controller: 'PromotionDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Promotion', function(Promotion) {
                            return Promotion.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('promotion', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
