(function() {
	'use strict';
	angular.module('sceaApp').factory('ProcessInstance', ProcessInstance);

	ProcessInstance.$inject = [ '$resource' ];

	function ProcessInstance($resource) {
        var resourceUrl =  'bpm-rest/runtime/process-instances/:processInstanceId';

		return $resource(resourceUrl, {}, {
			'query': { method: 'GET', isArray: false },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': { method: 'POST' },
            'update': { method: 'PUT' },
            'deleteProcessInstance': { method: 'DELETE' },
            
            'getDiagram': {
            	url: 'runtime/process-instances/:processInstanceId/diagram',
            	method: 'GET',
                responseType: 'arraybuffer',
                transformResponse: function (data, headersGetter) {
                    var blob = new Blob([data], {type: "image/png;charset=UTF-8"});
                    
                    var result = {};
                    result.headers = headersGetter();
                    result.data = data;
                    result.blob = blob;
                     
                    return result;
                }
            }
		});
	}
})();
