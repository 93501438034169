(function() {
    'use strict';
    angular
        .module('sceaApp')
        .factory('ProductCategory', ProductCategory);

    ProductCategory.$inject = ['$resource'];

    function ProductCategory ($resource) {
        var resourceUrl =  'api/product-categories/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getAllProductCategories' : {
            	url:'api/product-categories/level/:level', 
            	method: 'GET', 
            	isArray: true
            },
            'queryChild' : {
            	url:'api/product-categories/:id/children', 
            	method: 'GET', 
            	isArray: true
            }
        });
    }
})();
