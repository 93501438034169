(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('OutletDetailController', OutletDetailController);

    OutletDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Outlet', 'Channel', 'OutletAccount', 'OutletType', 'Area', 'VisitCycle'];

    function OutletDetailController($scope, $rootScope, $stateParams, entity, Outlet, Channel, OutletAccount, OutletType, Area, VisitCycle) {
        var vm = this;
        vm.outlet = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:outletUpdate', function(event, result) {
            vm.outlet = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
