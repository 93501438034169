(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('TPopFixtureDialogController', TPopFixtureDialogController);

    TPopFixtureDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'TPopFixture', 'TPop', 'Fixtures', 'ProductCategory'];

    function TPopFixtureDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, TPopFixture, TPop, Fixtures, ProductCategory) {
        var vm = this;
        vm.tPopFixture = entity;
        vm.tpops = TPop.query();
        vm.fixtures = Fixtures.query();
        vm.productcategories = ProductCategory.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sceaApp:tPopFixtureUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.tPopFixture.id !== null) {
                TPopFixture.update(vm.tPopFixture, onSaveSuccess, onSaveError);
            } else {
                TPopFixture.save(vm.tPopFixture, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
