(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('InternetQaDetailController', InternetQaDetailController);

    InternetQaDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'InternetQa', 'Internet', 'InternetAnswer'];

    function InternetQaDetailController($scope, $rootScope, $stateParams, entity, InternetQa, Internet, InternetAnswer) {
        var vm = this;
        vm.internetQa = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:internetQaUpdate', function(event, result) {
            vm.internetQa = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
