(function() {
    'use strict';
    angular
        .module('sceaApp')
        .factory('PopPairVersion', PopPairVersion);

    PopPairVersion.$inject = ['$resource'];

    function PopPairVersion ($resource) {
        var resourceUrl =  'api/pop-pair-versions/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getProducts': {
                url: 'api/pop-pair-versions/:id/products',
                method: 'GET',
                isArray: true
            },
            'getProductPops': {
                url: 'api/pop-pair-versions/:popPairVersionId/products/:productId/productpops',
                method: 'GET',
                isArray: true
            },
            'updateProductPops': {
                url: 'api/pop-pair-versions/:popPairVersionId/products/:productId/productpops',
                method: 'PUT'
            }
        });
    }
})();
