(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('ChannelDetailController', ChannelDetailController);

    ChannelDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Channel'];

    function ChannelDetailController($scope, $rootScope, $stateParams, entity, Channel) {
        var vm = this;
        vm.channel = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:channelUpdate', function(event, result) {
            vm.channel = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
