(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('TPopDeleteController',TPopDeleteController);

    TPopDeleteController.$inject = ['$uibModalInstance', 'entity', 'TPop'];

    function TPopDeleteController($uibModalInstance, entity, TPop) {
        var vm = this;
        vm.tPop = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            TPop.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
