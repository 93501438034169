(function() {
  'use strict';

  angular
    .module('sceaApp')
    .controller('RequestStatusPopReportController', RequestStatusPopReportController);

    RequestStatusPopReportController.$inject = ['$scope', '$state', 'OrderPop', 'OrderPopSearch', 'Outlet', 'Pop', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

    function RequestStatusPopReportController ($scope, $state, OrderPop, OrderPopSearch, Outlet, Pop, ParseLinks, AlertService, pagingParams, paginationConstants) {
      var vm = this;
      vm.loadAll = loadAll;
      vm.loadPage = loadPage;
      vm.predicate = pagingParams.predicate;
      vm.reverse = pagingParams.ascending;
      vm.transition = transition;
      vm.clear = clear;
      vm.search = search;
      vm.searchQuery = pagingParams.search;
      vm.currentSearch = pagingParams.search;

      vm.getPopDetail = getPopDetail;
      vm.index = null;

      vm.loadAll();

      function loadAll () {
        if (pagingParams.search) {
          OrderPopSearch.query({
            query: pagingParams.search,
            page: pagingParams.page - 1,
            size: paginationConstants.itemsPerPage,
            sort: sort()
          }, onSuccess, onError);
        } else {
          OrderPop.query({
            page: pagingParams.page - 1,
            size: paginationConstants.itemsPerPage,
            sort: sort()
          }, onSuccess, onError);
        }
        function sort() {
          var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
          if (vm.predicate !== 'id') {
            result.push('id');
          }
          return result;
        }
        function onSuccess(data, headers) {
          vm.links = ParseLinks.parse(headers('link'));
          vm.totalItems = headers('X-Total-Count');
          vm.queryCount = vm.totalItems;
          vm.orderPops = data;
          vm.page = pagingParams.page;
        }
        function onError(error) {
          AlertService.error(error.data.message);
        }
      }

      function loadPage (page) {
        vm.page = page;
        vm.transition();
      }

      function transition () {
        $state.transitionTo($state.$current, {
          page: vm.page,
          sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
          search: vm.currentSearch
        });
      }

      function search (searchQuery) {
        if (!searchQuery){
          return vm.clear();
        }
        vm.links = null;
        vm.page = 1;
        vm.predicate = '_score';
        vm.reverse = false;
        vm.currentSearch = searchQuery;
        vm.transition();
      }

      function clear () {
        vm.links = null;
        vm.page = 1;
        vm.predicate = 'id';
        vm.reverse = true;
        vm.currentSearch = null;
        vm.transition();
      }

      function getPopDetail(popId) {
        if (vm.popLoaded != popId) {
          return Pop.get({
            id: popId
          }, function(dataPop) {
            vm.popDetail = dataPop;
            console.log(popId + 'loaded');
          });
        }
      }
    }
})();
