(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('OutletEmployeeDetailController', OutletEmployeeDetailController);

    OutletEmployeeDetailController.$inject = ['$rootScope', '$scope', '$state', '$uibModalInstance', '$stateParams', 'calendarId', 'vde', 'Outlet', 'VDETaskPlanning'];

    function OutletEmployeeDetailController($rootScope, $scope, $state, $uibModalInstance, $stateParams, calendarId, vde, Outlet, VDETaskPlanning) {
        var vm = this;
        vm.calendarId = calendarId;
        vm.vde = vde;
        vm.outletsByEmployee = [];
        
		$rootScope.$watch('vdo', function(vdo) {
			if(vdo != null) {
		        vm.outletsByEmployee = Outlet.queryByEmployee({id: vdo.id});
			}
		});
        
        var unsubscribe = $rootScope.$on('sceaApp:outletUpdate', function(event, result) {
            vm.outlet = result;
        });
        $scope.$on('$destroy', unsubscribe);
        
        var onSaveSuccess = function (result) {
            $scope.$emit('sceaApp:vDETaskPlanningUpdate', result);
            $uibModalInstance.close(result);
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.addOutlet = function (outlet) {
            var vdeTaskPlanning = {
            	calendarId: vm.calendarId,
            	employeeId: vm.vde.id,
            	outletCode: outlet.outletCode,
            	outletId: outlet.id,
            	outletName: outlet.outletName,
            	recommendation: false,
            	storeGrade: outlet.visitCycleStoreGrade,
            	visited: false
            };
            
            VDETaskPlanning.save(vdeTaskPlanning, onSaveSuccess, onSaveError);
        };
        
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

    }
})();
