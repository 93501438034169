(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('RetailMappingCategoryDialogController', RetailMappingCategoryDialogController);

    RetailMappingCategoryDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'RetailMappingCategory'];

    function RetailMappingCategoryDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, RetailMappingCategory) {
        var vm = this;
        vm.retailMappingCategory = entity;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sceaApp:retailMappingCategoryUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.retailMappingCategory.id !== null) {
                RetailMappingCategory.update(vm.retailMappingCategory, onSaveSuccess, onSaveError);
            } else {
                RetailMappingCategory.save(vm.retailMappingCategory, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
