(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('TPopPlanogramDetailController', TPopPlanogramDetailController);

    TPopPlanogramDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'TPopPlanogram', 'TPop', 'PlanogramProduct'];

    function TPopPlanogramDetailController($scope, $rootScope, $stateParams, entity, TPopPlanogram, TPop, PlanogramProduct) {
        var vm = this;
        vm.tPopPlanogram = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:tPopPlanogramUpdate', function(event, result) {
            vm.tPopPlanogram = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
