(function() {
    'use strict';

    angular
        .module('sceaApp')
        .factory('OrderPopSearch', OrderPopSearch);

    OrderPopSearch.$inject = ['$resource'];

    function OrderPopSearch($resource) {
        var resourceUrl =  'api/_search/order-pops/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'queryOutlets': {
            	url: 'api/_search/order-pops/outlets',
            	method: 'GET',
            	isArray: true
            },
        });
    }
})();
