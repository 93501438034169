(function () {
    'use strict';

    angular
        .module('sceaApp')
        .controller('PlanogramMaterialDialog', PlanogramMaterialDialog);

    PlanogramMaterialDialog.$inject = ['$scope', '$uibModalInstance', 'Equipment', 'Planogram', 'planogramId'];

    function PlanogramMaterialDialog($scope, $uibModalInstance, Equipment, Planogram, planogramId) {
        var vm = this;

        vm.addMaterial = addMaterial;
        vm.cancel = cancel;
        vm.onChangeCategories = onChangeCategories;
        vm.onChangeSubCategories = onChangeSubCategories;
        vm.checkAll = checkAll;

        (function () {
            Equipment.getEquipmentCategory({
                root: true
            }, function (data) {
                vm.categories = data;
                vm.catOption = data[0];

                Equipment.getEquipmentCategory({
                    root: false,
                    parentId: vm.catOption.id
                }, function (data) {
                    vm.subCategories = data;
                    vm.subOption = data[0];
                    Equipment.getEquipmentCategory({
                        root: false,
                        parentId: vm.subOption.id
                    }, function (data) {
                        vm.notFilteredMaterials = data;
                        Planogram.getProductEquipmentsPlanogram({
                            id: planogramId,
                            categoryId: vm.subOption.id
                        }, function (data) {
                            vm.materials = vm.notFilteredMaterials
                                .filter(function (each) {
                                    var anyMaterial = data
                                        .find(function (source) {
                                            return source.equipmentId === each.id;
                                        });
                                    return !anyMaterial;
                                });
                        });
                    });
                });
            });
        })();

        function onChangeCategories(id) {
            vm.check = false;
            Equipment.getEquipmentCategory({
                root: false,
                parentId: id
            }, function (data) {
                vm.subCategories = data;
                vm.subOption = data[0];

                if (!data.length) {
                    vm.subOption = null;
                    vm.materials = [];
                } else {
                    Equipment.getEquipmentCategory({
                        root: false,
                        parentId: vm.subOption.id
                    }, function (data, headers) {
                        vm.notFilteredMaterials = data;
                        Planogram.getProductEquipmentsPlanogram({
                            id: planogramId,
                            categoryId: vm.subOption.id
                        }, function (data) {
                            vm.materials = vm.notFilteredMaterials
                                .filter(function (each) {
                                    var anyMaterial = data
                                        .find(function (source) {
                                            return source.equipmentId === each.id;
                                        });
                                    return !anyMaterial;
                                });
                        });
                    });
                }
            });
        }

        function onChangeSubCategories(id) {
            vm.check = false;
            Equipment.getEquipmentCategory({
                root: false,
                parentId: id
            }, function (data) {
                vm.notFilteredMaterials = data;
                Planogram.getProductEquipmentsPlanogram({
                    id: planogramId,
                    categoryId: vm.subOption.id
                }, function (data) {
                    vm.materials = vm.notFilteredMaterials
                        .filter(function (each) {
                            var anyMaterial = data
                                .find(function (source) {
                                    return source.equipmentId === each.id;
                                });
                            return !anyMaterial;
                        });
                });
            });
        }

        function checkAll(isChecked) {
            if (isChecked) {
                angular.forEach(vm.materials, function (e) {
                    e.status = true;
                });
            } else {
                angular.forEach(vm.materials, function (e) {
                    e.status = false;
                });
            }
        }

        function addMaterial() {
            vm.catOption.childs = [];
            var reduceMaterials = vm.materials
                .reduce(function (acc, idx) {
                    idx.status && acc.push({
                        id: null,
                        equipmentEquipmentName: idx.equipmentName,
                        equipmentId: idx.id,
                        target: 0
                    });
                    return acc;
                }, []);

            var result = {
                category: vm.catOption,
                subCategory: vm.subOption,
                materials: reduceMaterials
            };

            $uibModalInstance.close(result);
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
