(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('DroppointDetailController', DroppointDetailController);

    DroppointDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Droppoint', 'User'];

    function DroppointDetailController($scope, $rootScope, $stateParams, entity, Droppoint, User) {
        var vm = this;
        vm.droppoint = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:droppointUpdate', function(event, result) {
            vm.droppoint = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
