(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('ProductCategoryController', ProductCategoryController);

    ProductCategoryController.$inject = ['$scope', '$state', 'ProductCategory', 'ProductCategorySearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

    function ProductCategoryController ($scope, $state, ProductCategory, ProductCategorySearch, ParseLinks, AlertService, pagingParams, paginationConstants) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.clear = clear;
        vm.search = search;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.loadAll();
        vm.productCategories = []
        vm.expandProp = {
            field: 'id',
            displayName: 'Id',
            sortable : true,
            sortingType : "number",
        }
        vm.filterString = null;
        vm.colDefinitions = [
            {
                field: "categoryName",
                displayName: "Category Name",
                sortable : true,
                filterable: true,
            },
            {
                field: "parentName",
                displayName: "Parent Name",
                sortable : false,
                filterable: true
            },
            {
                field: "actions",
                displayName: " ",
                sortable: false,
                filterable: false,
                cellTemplate:
                "<div class=\"btn-group flex-btn-group-container\">"
                + "<button type=\"submit\" ui-sref=\"product-category-detail({id:row.branch[col.field]})\" class=\"btn btn-info btn-sm\">"
                + "<span class=\"glyphicon glyphicon-eye-open\"></span>&nbsp;&nbsp;"
                + "<span class=\"hidden-xs hidden-sm\" translate=\"entity.action.view\"></span>"
                + "</button>"
                + "<button type=\"submit\" ui-sref=\"product-category.edit({id:row.branch[col.field], level : row.level })\" class=\"btn btn-primary btn-sm\">"
                + "<span class=\"glyphicon glyphicon-pencil\"></span>&nbsp;&nbsp;"
                + "<span class=\"hidden-xs hidden-sm\" translate=\"entity.action.edit\"></span>"
                + "</button>"
                + "<button type=\"submit\" ui-sref=\"product-category.delete({id:row.branch[col.field]})\" class=\"btn btn-danger btn-sm\">"
                + "<span class=\"glyphicon glyphicon-remove-circle\"></span>&nbsp;&nbsp;"
                + "<span class=\"hidden-xs hidden-sm\" translate=\"entity.action.delete\"></span>"
                + "</button>"
                + "</div>"
            }
        ];
        $scope.myTree = {};

        function loadAll () {
            ProductCategory.query({
                page: 0,
                size: 2000,
                sort: 'id,asc'
            }, onSuccess, onError);
            function onSuccess(data, headers) {
                data.forEach(addActionsProps);
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.productCategories = _listToTree(data);
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
            function addActionsProps(each) {
                each.actions = each.id
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (searchQuery === '' || null) {
                vm.filterString = null;
                return;
            }
            vm.filterString = searchQuery;
        }

        function clear () {
            vm.filterString = null;
            vm.searchQuery = null;
            $scope.myTree.collapse_all();
        }
        

        // private function

        function _listToTree(list) {
            var map = {};
            var node;
            var roots = [];
            var i;

            for (i = 0; i < list.length; i += 1) {
                map[list[i].id] = i; // initialize the map
                list[i].children = []; // initialize the children
            }

            for (i = 0; i < list.length; i += 1) {
                node = list[i];
                if (node.parentId) {
                    // if you have dangling branches check that map[node.parentId] exists
                    list[map[node.parentId]].children.push(node);
                } else {
                    roots.push(node);
                }
            }
            return roots;
        }

    }
})();
