(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('VDETaskPlanningDetailController', VDETaskPlanningDetailController);

    VDETaskPlanningDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'VDETaskPlanning', 'Calendar', 'Outlet'];

    function VDETaskPlanningDetailController($scope, $rootScope, $stateParams, entity, VDETaskPlanning, Calendar, Outlet) {
        var vm = this;
        vm.vDETaskPlanning = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:vDETaskPlanningUpdate', function(event, result) {
            vm.vDETaskPlanning = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
