(function() {
    'use strict';

    angular
        .module('sceaApp')
        .factory('InternetConnectionSearch', InternetConnectionSearch);

    InternetConnectionSearch.$inject = ['$resource'];

    function InternetConnectionSearch($resource) {
        var resourceUrl =  'api/_search/internet-connections/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
