(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('TicketResultDetailController', TicketResultDetailController);

    TicketResultDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'TicketResult'];

    function TicketResultDetailController($scope, $rootScope, $stateParams, entity, TicketResult) {
        var vm = this;
        vm.ticketResult = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:ticketResultUpdate', function(event, result) {
            vm.ticketResult = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
