(function() {
    'use strict';

    angular.module('sceaApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('taskDetail', {
        	parent: 'merchandiser-activity',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'sceaApp.tasks.home.title'
            },
            views: {
            	'content@': {
            		templateUrl: 'app/merchandiser-activity/task/taskDetail.html',
                    controller: 'TaskDetailController',
                    controllerAs: 'vm'
                }
            },
        	url: '/merchandiser-activity/store-completion/tasksDetail',
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('global');
                	$translatePartialLoader.addPart('task');
                    return $translate.refresh();
                }]
            }
        })
    }
})();
