(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('StoreCompletenessReportController', StoreCompletenessReportController);

    StoreCompletenessReportController.$inject = ['$scope', '$state', 'Principal', 'Area', 'User', 'Calendar', 'ExcelReport'];

    function StoreCompletenessReportController ($scope, $state, Principal, Area, User, Calendar, ExcelReport) {
        var vm = this;
        vm.options = {
        	all: true,
        	filterType: 'employee'
        }
		vm.area = {
			regions: Area.queryByLevelId({areaLevelId : 1},
				function(result) {
					vm.area.region = result[0];
					if(vm.area.region != null) {
						vm.getBranchs(vm.area.region.id);
					}
			}),
			branchs: [],
			cities: []
		}
		vm.employee = {
    		/*
			vdrs: Employee.query({role: 'VDH'},
				function(result) {
					vm.employee.vdr = result[0];
					if(vm.employee.vdr != null) {
						vm.getVdos(vm.employee.vdr.id);
					}
			}),*/
			vdos: User.query({roleName: 'VDO'}, function(result) {
				vm.employee.vdo = result[0];
				if(vm.employee.vdo != null) {
					vm.getVdes(vm.employee.vdo.id);
				}
			}),
			vdes: []
		}
		vm.calendar = {
			years: Calendar.queryYears(),
			months: Calendar.queryMonths(),
			weeks: []
		}

        vm.isVdo = false;
        vm.getBranchs = getBranchs;
        vm.getCities = getCities;
        vm.getVdos = getVdos;
        vm.getVdes = getVdes;
        vm.getWeeks = getWeeks;
        vm.init = init;
        vm.init();

        function init() {
            getAccount();
            initCalendar();
        }

        function getAccount() {
            Principal.identity().then(function(account) {
            	for(var i=0; i<account.authorities.length; i++) {
            		if(account.authorities[i] == 'VDO') {
            			isVdo();
            			break;
            		}
            	}
            });
        }

        function isVdo() {
			vm.isVdo = true;
			vm.options.all = false,
        	vm.employee.vdos  = Employee.query({role: 'VDO'},
        		function(result) {
					vm.employee.vdo = result[0];
					if(vm.employee.vdo != null) {
						vm.getVdes(vm.employee.vdo.id);
					}
			});
        }

        function initCalendar() {
        	vm.calendar.year = new Date().getFullYear();
        	vm.calendar.month = (new Date().getMonth() + 1).toString();
        	vm.getWeeks(vm.calendar.year,vm.calendar.month);
        }

		function getBranchs(regionId) {
			vm.area.branch = null;
			vm.area.city = null;
        	vm.area.branchs = Area.queryByParentIdAndLevelId({parentId: regionId, areaLevelId : 2});
        }

        function getCities(branchId) {
			vm.area.cities = [];
			vm.area.city = null;
        	if(branchId != null) {
        		vm.area.cities = Area.queryByParentIdAndLevelId({parentId: branchId, areaLevelId : 3});
        	}
        }

        function getVdos() {
        	vm.employee.vdos  = Employee.query({role: 'VDO'},
        		function(result) {
					vm.employee.vdo = result[0];
					if(vm.employee.vdo != null) {
						vm.getVdes(vm.employee.vdo.id);
					}
			});
        }

        function getVdos(managerId) {
        	vm.employee.vdo = null;
        	vm.employee.vde = null;
        	vm.employee.vdos = Employee.query({role: 'VDO', managerId: managerId});
        }

        function getVdes(managerId) {
        	vm.employee.vdes = [];
        	vm.employee.vde = null;
        	if(managerId) {
        		vm.employee.vdes = User.query({roleName: 'VDE', managerId: managerId});
        	}
        }

        function getWeeks(year, month) {
			Calendar.queryWeeks({year: year, month: month}, function(result) {
				vm.calendar.weeks = result;
				vm.calendar.week = vm.calendar.weeks[0];
			});
        }

        vm.generate = function() {
        	vm.isSaving = true;

        	var filter = generateFilter();
        	var calendar = getCalendar();

        	ExcelReport.reportStoreCompleteness({
        		allWeek: false,
        		categoryId: 0,
        		filter: filter.type,
        		filterId: filter.id,
        		month: calendar.month,
        		week: calendar.week,
        		year: calendar.year
        	}, function() {
            	vm.isSaving = false;
        	});

        	function generateFilter() {
        		var result = { type: null, id: null };

        		if(vm.options.all == false) {
	        		if(vm.options.filterType == 'area') {
	        			if(vm.area.city != null) {
	        				result.type = 'CITY';
	        				result.id = vm.area.city.id;
	        			} else if(vm.area.branch != null) {
	        				result.type = 'BRANCH';
	        				result.id = vm.area.branch.id;
	        			} else if(vm.area.region != null) {
	        				result.type = 'REGION';
	        				result.id = vm.area.region.id;
	        			}
	        		} else if(vm.options.filterType == 'employee') {
	        			if(vm.employee.vde != null) {
	        				result.type = 'VDE';
	        				result.id = vm.employee.vde.id;
	        			} else if(vm.employee.vdo != null) {
	        				result.type = 'VDO';
	        				result.id = vm.employee.vdo.id;
	        			} else if(vm.employee.vdr != null) {
	        				result.type = 'VDR';
	        				result.id = vm.employee.vdr.id;
	        			}
	        		}
        		} else {
    				result.type = 'ALL';
    				result.id = 0;
        		}
    			return result;
        	}

        	function getCalendar() {
        		var result = {
        			year: vm.calendar.year,
        			month: parseInt(vm.calendar.month),
        			week: vm.calendar.week
        		};
        		if(vm.calendar.week == 'All') {
        			result.week = 0;
        		}
    			return result;
        	}
        }
    }
})();
