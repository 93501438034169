(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('PromoMaterialDetailController', PromoMaterialDetailController);

    PromoMaterialDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PromoMaterial', 'ProductCategory'];

    function PromoMaterialDetailController($scope, $rootScope, $stateParams, entity, PromoMaterial, ProductCategory) {
        var vm = this;
        vm.promoMaterial = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:promoMaterialUpdate', function(event, result) {
            vm.promoMaterial = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
