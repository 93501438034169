(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('ProjectListDialogController', ProjectListDialogController)   
        .directive('dynamicForm', function () {
		    return {
		        restrict: 'E',
		        templateUrl: "app/layouts/dynamic-form/dynamic-form.html"
		    };
		})
    	.directive('format', ['$filter', function ($filter) {
		    return {
		        require: '?ngModel',
		        link: function (scope, elem, attrs, ctrl) {
		            if (!ctrl) return;
		
		            ctrl.$formatters.unshift(function (a) {
		                return $filter(attrs.format)(ctrl.$modelValue)
		            });
		            
		            ctrl.$parsers.unshift(function (viewValue) {
		                var plainNumber = viewValue.replace(/[^\d|\-+|\.+]/g, '');
		                elem.val($filter(attrs.format)(plainNumber));
		                return plainNumber;
		            });
	            }
	        };
	    }]);

    ProjectListDialogController.$inject = ['$timeout', '$scope', '$state', '$stateParams', '$uibModalInstance', '$filter', 'orderByFilter',
                                           'AlertService', 'Attachment', 'DataUtils', 'DynamicForm', 'Form', 'OutletSearch', 'Project', 'SceaProcessInstance'];

    function ProjectListDialogController ($timeout, $scope, $state, $stateParams, $uibModalInstance, $filter, orderByFilter,
    		AlertService, Attachment, DataUtils, DynamicForm, Form, OutletSearch, Project, SceaProcessInstance) {
        var vm = this;
        Project.query(function(result) {
        	vm.projects = orderByFilter(result, 'id', false);
        });
        vm.getOutlet = getOutlet;
        
        var idAttachmentFiles = [];
        var idALongVariables = [];        
        var indexBusinessUnit = 0;
        var indexOutlet = 0;
        
        vm.generateForm = function(project) {
        	vm.project = project;
            vm.businessUnit = DynamicForm.getBusinessUnit();
            vm.loadingOutlet = false;
            vm.attachment = [];
            
            if(project.processDefinitionId != null) {
	            vm.taskDetailed = Form.get({processDefinitionId : project.processDefinitionId}, 
	            	function(result) {
	            	angular.forEach(result.formProperties, function(variable, key) {
	            		if(variable.type == 'scea_file') {
	            			idAttachmentFiles.push(key);
	            			vm.attachment[key] = {};
	            		}
	            		if(variable.type == 'long') {
	            			idALongVariables.push(key);
	            		}
	            		if(variable.type == 'scea_business_unit') {
	            			indexBusinessUnit = key;
	            		}
	            		if(variable.type == 'scea_outlet') {
	            			indexOutlet = key;
	            		}
	            		if(variable.type == 'scea_project') {
	            			vm.taskDetailed.formProperties[key].value = project.id;
	            		}
	            	});
	            });
            } else {
            	vm.taskDetailed = [];
                idAttachmentFiles = [];
                idALongVariables = [];        
                indexBusinessUnit = 0;
                indexOutlet = 0;
            }
        }
        
        function getOutlet(value) {
        	vm.loadingOutlet = true;
            return OutletSearch.query({
                query: '*'+value+'*'
            }).$promise.then(function(response){
            	vm.loadingOutlet = false;
                return response;
            }, function(response){
            	vm.loadingOutlet = false;
                AlertService.error(response.data.message);
            });
        }
        
        vm.setContent = function ($file, attachment) {
            if($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        attachment.content = base64Data;
                        attachment.name = $file.name;
                        attachment.size = $file.size;
                        attachment.contentContentType = $file.type;
                    });
                });
            }
        };

        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;
        
        vm.openDatePicker = function(obj) {
            obj.opened = true;
        };
        
        vm.calculate = function(index) {
        	var obj = {};
        	if(vm.taskDetailed.formProperties[indexBusinessUnit].value != null) {
            	obj.bizUnitId = parseInt(vm.taskDetailed.formProperties[indexBusinessUnit].value);
            	
            	if(vm.taskDetailed.formProperties[indexOutlet].value != null) {
            		obj.outletId = vm.taskDetailed.formProperties[indexOutlet].value.id;
            		
                	obj.fieldName = vm.taskDetailed.formProperties[index].id;
                	obj.variables = {};

                	angular.forEach(idALongVariables, function(idALongVariable, key) {
                		var property = vm.taskDetailed.formProperties[idALongVariable].id;
                		var value = vm.taskDetailed.formProperties[idALongVariable].value;
                		
                		obj.variables[property] = value;

                		if(idALongVariables.length-1 == key) {
                			DynamicForm.calculateFormula(obj, function(result) {
                        		vm.taskDetailed.formProperties[index].value = result.value;
                        	});
                		}
                	});
            	} else {
                    AlertService.error('Store is empty');
            	}
        	} else {
                AlertService.error('Business Unit is empty');
        	}
        }
        
        vm.save = function() {
        	vm.isSaving = true;
        	
        	angular.forEach(idAttachmentFiles, function(idAttachmentFile, key) {        		
        		if(vm.attachment[idAttachmentFile] != null) {
		        	var obj = {};
		        	obj.content = vm.attachment[idAttachmentFile].content;
		        	obj.fileName = vm.attachment[idAttachmentFile].name;
		        	obj.contentContentType = vm.attachment[idAttachmentFile].contentContentType;
		        	obj.creationDate = new Date();
		        	obj.size = vm.attachment[idAttachmentFile].size;
		        	
		        	Attachment.upload(obj, function(result) {
		        		setAttachmentFile(result, key);
		        	});
        		}
        	});
        	
        	function setAttachmentFile(obj, key) {
        		var id = idAttachmentFiles[key];
				vm.taskDetailed.formProperties[id].value = obj.id;
				
				if(idAttachmentFiles.length-1 == key) {
        			startProcessInstance();
        		}
        	}
        };
        
        var startProcessInstance = function () {
            var obj = {};
            obj.processDefinitionId = vm.project.processDefinitionId;
            obj.businessKey = vm.project.projectName;
            obj.properties = [];
            
            angular.forEach(vm.taskDetailed.formProperties, function(variable, key) {
                var property = {}
                property = variable;
                
        		if(variable.type == 'scea_outlet' && variable.value != null) {
        			property.value = variable.value.id;
        		}
        		if(variable.type == 'date' && variable.value != null) {
        			property.value = $filter('date')(variable.value, variable.datePattern);
        		}
        		if(variable.type == 'long' && variable.value != null) {
        			property.value = parseInt(variable.value);
        		}
        		
        		obj.properties.push(property);
        		
        		if(vm.taskDetailed.formProperties.length-1 == key) {
        			SceaProcessInstance.submitForm(obj, onSaveSuccess, onSaveError);
        		}
        	});
        };

        var onSaveSuccess = function (result) {
            vm.isSaving = false;
            $scope.$emit('sceaApp:projectListUpdate', result);
            $uibModalInstance.close(result);
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });
    }
})();
