(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('DiagramDialogController', DiagramDialogController);

    DiagramDialogController.$inject = ['$scope', 'diagram', 'DataUtils'];

    function DiagramDialogController ($scope, diagram, DataUtils) {
        var vm = this;

        function init() {	
        	DataUtils.toBase64(diagram, function(base64Data) {
        		$scope.$apply(function() {
        			vm.diagram = base64Data;
        		});
        	});   	
        }
        init();
    }
})();
