(function () {
    'use strict';

    angular
        .module('sceaApp')
        .controller('DashboardAsmController', DashboardAsmController);

    DashboardAsmController.$inject = ['$scope', '$state', 'filterParams', 'Principal', 'User', 'Calendar', 'Dashboard',
        'years', 'months', 'weeks', 'currentUser', 'currentCalendar', 'vdrList', 'vdoList', 'vdeList'];

    function DashboardAsmController($scope, $state, filterParams, Principal, User, Calendar, Dashboard,
        years, months, weeks, currentUser, currentCalendar, vdrList, vdoList, vdeList) {
        var vm = this;
        var sName = "";
        var sData = [];
        var arrWeek = [];

        vm.showChart = filterParams.showChart === 'true';
        vm.updateWeeks = updateWeeks;
        vm.getAsmSummariesCp = getAsmSummariesInvoke;
        vm.getAsmSummariesPc = getAsmSummariesInvoke;
        vm.getAsmSummariesPt = getAsmSummariesInvoke;
        vm.getAsmSummariesDp = getAsmSummariesDPS;

        vm.transition = transition;
        vm.onVdrChange = onVdrChange;
        vm.onVdoChange = onVdoChange;
        vm.onVdeChange = onVdeChange;

        vm.isAdmin = currentUser ? currentUser.authorities.includes('ROLE_ADMIN') : false;
        vm.isVdr = currentUser ? currentUser.authorities.includes('VDR') : false;
        vm.isVdo = currentUser ? currentUser.authorities.includes('VDO') : false;
        vm.isVde = currentUser ? currentUser.authorities.includes('VDE') : false;

        vm.vdr = vdrList;
        vm.vdo = vdoList;
        vm.vde = vdeList;
        vm.weeks = weeks;
        vm.months = months;
        vm.years = years;
        vm.currentUser = currentUser;
        vm.calendar = {
            year: filterParams.year ? filterParams.year : currentCalendar.year ? currentCalendar.year.toString() : null,
            month: filterParams.month ? filterParams.month : currentCalendar.month ? currentCalendar.month.toString() : null,
            week: filterParams.week ? filterParams.week : currentCalendar.week ? currentCalendar.week.toString() : null,
        }
        vm.selectedRole = {
            vdr: filterParams.vdr ? vm.vdr.find(function(vdr){return vdr.id == filterParams.vdr}) : null,
            vdo: filterParams.vdoCoo ? vm.vdo.find(function(vdoCoo){return vdoCoo.id == filterParams.vdoCoo}) : null,
            vde: filterParams.vdo ? vm.vde.find(function(vdo){return vdo.id == filterParams.vdo}) : null,
        }

        if (!filterParams.vdo && !filterParams.vdoCoo && !filterParams.vdo) {
            if (vm.isVde) {
                User.getInternal({ roleName: 'VDE', managerId: currentUser.managerId }, function (vde) {
                    vm.vde = vde
                    vm.selectedRole.vde = vm.vde.find(function(vde){return vde.id == currentUser.id})
                });

                User.getInternal({ roleName: 'VDO'}, function (vdo) {
                    vm.vdo = vdo
                    console.log(vm.vdo);
                    vm.selectedRole.vdo = vm.vdo.find(function(vdo){return vdo.id == currentUser.managerId});
                    vm.selectedRole.vdr = vm.vdr.find(function(vdr){return vdr.id == vm.selectedRole.vdo.managerId});
                });
            } else if (vm.isVdo) {
                vm.selectedRole.vdr = vm.vdr.find(function(vdr){return vdr.id == currentUser.managerId});

                User.getInternal({ roleName: 'VDO', managerId: currentUser.managerId }, function (vdo) {
                    vm.vdo = vdo
                    vm.selectedRole.vdo = vm.vdo.find(function(vdo){return vdo.id == currentUser.id})
                });

                User.getInternal({ roleName: 'VDE', managerId: currentUser.id }, function (vde) {
                    vm.vde = vde
                    vm.selectedRole.vdo = vm.vdo.find(function(vdo){return vdo.id == currentUser.id})
                });
            } else if (vm.isVdr) {
                vm.selectedRole.vdr = vm.vdr.find(function(vdr){return vdr.id == currentUser.id});
            }
        }

        loadData();

        function loadData() {
            var userId;

            if (filterParams.vdo){
                userId = filterParams.vdo
            } else if (filterParams.vdoCoo) {
                userId = filterParams.vdoCoo
            } else if (filterParams.vdr) {
                userId = filterParams.vdr
            } else {
                userId = vm.currentUser.id
            }

            if (!userId) {
                return;
            }

            if (vm.showChart) {
                getAsmChart(userId, vm.calendar, "CALLPLANACOJTGT");
                getAsmChart(userId, vm.calendar, "POPALL");
                getAsmChart(userId, vm.calendar, "POPMOVEMENT");
            } else {
                getAsmSummariesInvoke(userId, vm.calendar, "CALLPLANACOJTGT")
                getAsmSummariesInvoke(userId, vm.calendar, "POPALL")
                getAsmSummariesInvoke(userId, vm.calendar, "POPMOVEMENT")
                getAsmSummariesDPS(userId, vm.calendar, "DPSTOCKLEVEL")
            }

        }

        function updateWeeks() {
            vm.weeks = Calendar.queryWeeks({
                year: vm.calendar.year,
                month: vm.calendar.month
            }, function (weeks) {
                vm.calendar.week = weeks.length ? weeks[0].toString() : null;
                vm.transition();
            });
        }

        // load chart
        function getAsmChart(userId, calendar, categories) {

            if (calendar.year && calendar.month) {

                // total weekly
                Dashboard.getAsmChart({
                    userId: userId,
                    year: calendar.year,
                    month: calendar.month,
                    storeType: 'VDE',
                    category: categories
                }, function (data) {
                    sData = [];
                    arrWeek = [];
                    var tempUrl;
                    var sumPercentage = 0;
                    var countData = 0;
                    angular.forEach(data, function (eachData) {
                        if (eachData.week) {
                            var url;

                            if (categories === "CALLPLANACOJTGT") {
                                url = 'vdo-callplan-detail';
                            } else if (categories === "POPALL") {
                                url = 'pop-compliance-detail';
                            } else {
                                url = 'pop-tracking-detail';
                            }

                            arrWeek.push(eachData.week);

                            var percentage = eachData.percentage * 100;
                            var fixedPercentage = parseFloat(percentage.toFixed(2));
                            sData.push({
                                url: url,
                                y: fixedPercentage,
                                week: eachData.week
                            });
                            tempUrl = url;
                            sumPercentage += fixedPercentage;
                            countData++;
                        }
                    });
                    arrWeek.push("Average")

                    var fixedPercentage = parseFloat((sumPercentage / countData).toFixed(2));
                    sData.push({
                        url: null,
                        y: fixedPercentage,
                        week: "AVG"
                    });

                    if (categories == "CALLPLANACOJTGT") {
                        sName = "VDO CALLPLAN";
                        chartInvoke("chart1", sName, sData, arrWeek);
                    } else if (categories == "POPALL") {
                        sName = "POP COMPLIANCE";
                        chartInvoke("chart2", sName, sData, arrWeek);
                    } else {
                        // sName = "POP TRACKING";
                        // chartInvoke("chart3", sName, sData, arrWeek);
                    }
                });

            }
        }

        // total summary
        function getAsmSummariesInvoke(userId, calendar, categories) {
            if (calendar.year && calendar.week) {
                Dashboard.getAsmSummaries({
                    userId: userId,
                    year: calendar.year,
                    week: calendar.week,
                    storeType: 'VDE',
                    category: categories
                }, function (data) {
                    if (categories == "CALLPLANACOJTGT") {
                        vm.asmSummariesCp = data;
                    } else if (categories == "POPALL") {
                        vm.asmSummariesPc = data;
                    } else {
                        vm.asmSummariesPt = data;
                    }
                });
            }
        }

        function getAsmSummariesDPS(userId, calendar, categories) {
            if (calendar.year && calendar.week) {
                Dashboard.getAsmDropPointStockLevel({
                    userId: userId,
                    year: calendar.year,
                    week: calendar.week,
                    storeType: 'VDE',
                    category: categories
                }, function (e) {
                    console.log('e: ', e);

                    vm.asmSummariesDp = e;
                });
            }
        }

        // highchart
        function chartInvoke(idContainer, chartName, ChartSeries, arrWeek) {
            var weeks = arrWeek;

            Highcharts.chart(idContainer, {
                credits: false,
                chart: {
                    type: 'column'
                },
                title: {
                    text: chartName
                },
                xAxis: {

                    // categories: arrWeek,
                    type: 'category',
                    crosshair: true,
                    title: {
                        text: 'Week'
                    },
                    labels: {
                        formatter: function () {
                            return '<a href="' + this.chart.series[0].options.data[this.value].url +
                                '" target="_parent">' + this.chart.series[0].options.data[this.value].week +
                                '</a>';
                        }
                    }
                },
                yAxis: {
                    min: 0,
                    max: 100,
                    title: {
                        text: 'Percentage (%)'
                    }
                },
                tooltip: {
                    // headerFormat: '<span style="font-size:10px">week'+weeks[this.x]+'</span><table>',
                    // pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    // '<td style="padding:0"><b>{point.y:.2f}%</b></td></tr>',
                    // footerFormat: '</table>',
                    shared: true,
                    useHTML: true,
                    formatter: function () {
                        var w = weeks
                        var s = '<span style="font-size:10px">week ' +
                            weeks[this.x] +
                            '</span><table> <tr><td style="color:';

                        $.each(this.points, function (i, point) {
                            s += point.series.color + ';padding:0">' +
                                point.series.name + ': </td> <td style="padding:0"><b>' +
                                point.y.toFixed(2) + '%</b></td></tr></table>';
                        });

                        return s;
                    }
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.1,
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true
                        }
                    },
                    series: {
                        cursor: 'pointer',
                        point: {
                            events: {
                                click: function (a, b, c) {
                                    console.log(a.point.options);
                                    var week = a.point.options.week;
                                    var url = a.point.options.url;
                                    if(!url) {
                                        return;
                                    }
                                    $state.go(url, {
                                        showChart: vm.showChart,
                                        week: week,
                                        month: vm.calendar.month,
                                        year: vm.calendar.year,
                                        vdr: vm.selectedRole.vdr ? vm.selectedRole.vdr.id : null,
                                        vdoCoo: vm.selectedRole.vdo ? vm.selectedRole.vdo.id : null,
                                        vdo: vm.selectedRole.vde ? vm.selectedRole.vde.id : null
                                    });
                                }
                            }
                        }
                    }
                },
                series: [{
                    showInLegend: false,
                    name: sName,
                    data: sData

                }]
            });
        }

        // event change role
        function onVdrChange() {
            vm.selectedRole.vdo = null;
            vm.selectedRole.vde = null;
            vm.transition();
        }

        function onVdoChange() {
            vm.selectedRole.vde = null;
            vm.transition();
        }

        function onVdeChange() {
            vm.transition();
        }

        function transition() {
            $state.go('home', {
                showChart: vm.showChart,
                week: vm.calendar.week,
                month: vm.calendar.month,
                year: vm.calendar.year,
                vdr: vm.selectedRole.vdr ? vm.selectedRole.vdr.id : null,
                vdoCoo: vm.selectedRole.vdo ? vm.selectedRole.vdo.id : null,
                vdo: vm.selectedRole.vde ? vm.selectedRole.vde.id : null
            });
        }
    }
})();
