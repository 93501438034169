(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', '$state', '$filter', 'Principal', 'LoginService', '$rootScope'];

    function HomeController ($scope, $state, $filter, Principal, LoginService, $rootScope) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = false;
        vm.login = LoginService.open;
        vm.register = register;
        $rootScope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;

                if (vm.isAuthenticated()) {
                    $state.go('dashboard');
                }
            });
        }
        function register () {
            $state.go('register');
        }
    }
})();
