(function() {
    'use strict';

    angular
        .module('sceaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('other-job-approval', {
            parent: 'merchandiser-activity',
            url: '/merchandiser-activity/other-job-approval?page&sort&search&status',
            data: {
                authorities: ['ROLE_ADMIN', 'VDO', 'ARCO', 'VDR'],
                pageTitle: 'sceaApp.otherJob.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/merchandiser-activity/other-job-approval/other-jobs.html',
                    controller: 'OtherJobController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null,
                status: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        status: $stateParams.status
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('otherJob');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('other-job-approval.edit', {
            parent: 'other-job-approval',
            url: '/{id}/detail',
            data: {
                authorities: ['ROLE_ADMIN', 'VDO', 'ARCO']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/merchandiser-activity/other-job-approval/other-job-dialog.html',
                    controller: 'OtherJobDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['OtherJob', function(OtherJob) {
                            return OtherJob.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('other-job-approval', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
