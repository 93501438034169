(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('RowDataPopReportController', RowDataPopReportController);

    RowDataPopReportController.$inject = ['$scope', '$state', 'Principal', 'Area', 'Employee', 'ProductCategory', 'ExcelReport'];

    function RowDataPopReportController ($scope, $state, Principal, Area, Employee, ProductCategory, ExcelReport) {
        var vm = this;
        vm.options = {
        	all: true,
        	filterType: 'employee'
        }
		vm.area = {
			regions: Area.queryByLevelId({areaLevelId : 1},
				function(result) {
					vm.area.region = result[0];
					if(vm.area.region != null) {
						vm.getBranchs(vm.area.region.id);
					}
			}),
			branchs: [],
			cities: []
		}
		vm.employee = {
			vdrs: Employee.query({role: 'VDH'},
				function(result) {
					vm.employee.vdr = result[0];
					if(vm.employee.vdr != null) {
						vm.getVdos(vm.employee.vdr.id);
					}
			}),
			vdos: [],
			vdes: []
		}
        vm.categories = ProductCategory.getAllProductCategories({level: 2},function(result) {
        	vm.category = result[0];
        });

        vm.isVdo = false;
        vm.getBranchs = getBranchs;
        vm.getCities = getCities;
        vm.getVdos = getVdos;
        vm.getVdes = getVdes;

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
            	for(var i=0; i<account.authorities.length; i++) {
            		if(account.authorities[i] == 'VDO') {
            			isVdo();
            			break;
            		}
            	}
            });
        }

        function isVdo() {
			vm.isVdo = true;
			vm.options.all = false,
        	vm.employee.vdos  = Employee.query({role: 'VDO'},
        		function(result) {
					vm.employee.vdo = result[0];
					if(vm.employee.vdo != null) {
						vm.getVdes(vm.employee.vdo.id);
					}
			});
        }

		function getBranchs(regionId) {
			vm.area.branch = null;
			vm.area.city = null;
        	vm.area.branchs = Area.queryByParentIdAndLevelId({parentId: regionId, areaLevelId : 2});
        }

        function getCities(branchId) {
			vm.area.cities = [];
			vm.area.city = null;
        	if(branchId != null) {
        		vm.area.cities = Area.queryByParentIdAndLevelId({parentId: branchId, areaLevelId : 3});
        	}
        }

        function getVdos() {
        	vm.employee.vdos  = Employee.query({role: 'VDO'},
        		function(result) {
					vm.employee.vdo = result[0];
					if(vm.employee.vdo != null) {
						vm.getVdes(vm.employee.vdo.id);
					}
			});
        }

        function getVdos(managerId) {
        	vm.employee.vdo = null;
        	vm.employee.vde = null;
        	vm.employee.vdos = Employee.query({role: 'VDO', managerId: managerId});
        }

        function getVdes(managerId) {
        	vm.employee.vdes = [];
        	vm.employee.vde = null;
        	if(managerId) {
        		vm.employee.vdes = Employee.query({role: 'VDE', managerId: managerId});
        	}
        }

        vm.generate = function() {
        	vm.isSaving = true;

        	var filter = generateFilter();

        	ExcelReport.reportPOP({
        		allWeek: true,
        		categoryId: vm.category.id,
        		filter: filter.type,
        		filterId: filter.id,
        		month: 0,
        		week: 0,
        		year: 0
        	}, function() {
            	vm.isSaving = false;
        	});

        	function generateFilter() {
        		var result = { type: null, id: null };

        		if(vm.options.all == false) {
	        		if(vm.options.filterType == 'area') {
	        			if(vm.area.city != null) {
	        				result.type = 'CITY';
	        				result.id = vm.area.city.id;
	        			} else if(vm.area.branch != null) {
	        				result.type = 'BRANCH';
	        				result.id = vm.area.branch.id;
	        			} else if(vm.area.region != null) {
	        				result.type = 'REGION';
	        				result.id = vm.area.region.id;
	        			}
	        		} else if(vm.options.filterType == 'employee') {
	        			if(vm.employee.vde != null) {
	        				result.type = 'VDE';
	        				result.id = vm.employee.vde.id;
	        			} else if(vm.employee.vdo != null) {
	        				result.type = 'VDO';
	        				result.id = vm.employee.vdo.id;
	        			} else if(vm.employee.vdr != null) {
	        				result.type = 'VDR';
	        				result.id = vm.employee.vdr.id;
	        			}
	        		}
        		} else {
    				result.type = 'ALL';
    				result.id = 0;
        		}
    			return result;
        	}
        }
    }
})();
