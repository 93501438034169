(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('VDETaskPlanningDialogController2', VDETaskPlanningDialogController2);

    VDETaskPlanningDialogController2.$inject = ['$timeout', '$scope', '$state', '$stateParams', '$uibModalInstance', '$uibModal', 'entity', 'vde', 'last', 'Calendar', 'VDETaskPlanning'];

    function VDETaskPlanningDialogController2 ($timeout, $scope, $state, $stateParams, $uibModalInstance, $uibModal, entity, vde, last, Calendar, VDETaskPlanning) {
        var vm = this;
        var isChange = false;
        vm.vdeTaskPlanningsPerWeek = entity;
        vm.vde = vde;
        vm.isLast = last;
        vm.reverse = false;
        
        vm.ok = function() {
        	if(isChange) {
                $uibModalInstance.close(vm.vdeTaskPlanningsPerWeek);        		
        	} else {
        		vm.clear();    		
        	}
        };
        
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.addVdeTaskPlanning = function() {
        	isChange = true;
            var modalInstance = $uibModal.open({
            	animation: $scope.animationsEnabled,
            	templateUrl: 'app/merchandiser-activity/outlet-employee/outlet-employee-detail.html',
            	controller: 'OutletEmployeeDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
            	size: "md",
            	resolve: {
            		calendarId: [function() {
            			return vm.calendarId
            		}],
            		vde: [function() {
            			return vm.vde
            		}]
            	}
            });

            modalInstance.result.then(function(result) {
            	vm.vdeTaskPlanningsPerWeek = VDETaskPlanning.query({vdeId: vm.vde.id, calendarId : vm.calendarId});
            });   	
        };
        
        vm.deleteVdeTaskPlanning = function(vdeTaskPlanning) {
        	isChange = true;
            var modalInstance = $uibModal.open({
            	animation: $scope.animationsEnabled,
            	templateUrl: 'app/merchandiser-activity/v-de-task-planning/v-de-task-planning-delete-dialog.html',
            	controller: 'VDETaskPlanningDeleteController',
                controllerAs: 'vm',
                backdrop: 'static',
            	size: "md",
            	resolve: {
            		entity: [function() {
            			return vdeTaskPlanning;
            		}]
            	}
            });

            modalInstance.result.then(function(result) {
            	vm.vdeTaskPlanningsPerWeek = VDETaskPlanning.query({vdeId: vm.vde.id, calendarId : vm.calendarId});
            });   	
        };
    }
})();
