(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('PromotionDialogController', PromotionDialogController);

    PromotionDialogController.$inject = ['$timeout', '$location', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Promotion'];

    function PromotionDialogController ($timeout, $location, $scope, $stateParams, $uibModalInstance, entity, Promotion) {
        var vm = this;
        
        vm.checkValidDate = checkValidDate;

        (function() {
            vm.promotion = entity;

            if(vm.promotion.$promise) {
                vm.promotion.$promise.then(function(data) {
                    if(checkExpiredPromotion(data)){
                        vm.clear();
                    }
                });
            }
        })();

        function checkExpiredPromotion (params) {
            var checkExpired = moment(params.validUntil).isBefore(moment());
            return (checkExpired) ? true : false;
        }

        vm.date = {
            validFrom: vm.promotion.validFrom ? new Date(moment(vm.promotion.validFrom, formatDate).format()) : new Date(),
            validUntil: vm.promotion.validUntil ? new Date(moment(vm.promotion.validUntil, formatDate).format()) : new Date()
        };

        vm.datePickerOptions = {
            validFrom: { minDate: new Date() }
        };

        if(entity.validFrom != null && entity.validUntil != null) {
            checkValidDate();        	
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sceaApp:promotionUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            
            var validUntil = moment(vm.promotion.validUntil).endOf('day');
            vm.promotion.validUntil = validUntil._d;
            
            if (vm.promotion.id !== null) {
                Promotion.update(vm.promotion, onSaveSuccess, onSaveError);
            } else {
                Promotion.save(vm.promotion, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {
            validFrom: false,
            validUntil: false
        };

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };

        function checkValidDate(validFrom) {
            if (vm.date.validUntil) {
                vm.date.validUntil = (moment(validFrom).isAfter(vm.date.validUntil)) ? null : vm.date.validUntil;
            } else {
                vm.date.validUntil = new Date(validFrom);
            }

            vm.datePickerOptions.validUntil = {
                minDate: new Date(validFrom),
                initDate: new Date(validFrom)
            };

        }
    }
})();
