(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('DashboardSettingDialogController', DashboardSettingDialogController);

    DashboardSettingDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity', 'DashboardSetting'];

    function DashboardSettingDialogController ($timeout, $scope, $uibModalInstance, entity, DashboardSetting) {
        var vm = this;
        vm.dashboardSetting = entity;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sceaApp:dashboardSettingUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.dashboardSetting.id !== null) {
                DashboardSetting.update(vm.dashboardSetting, onSaveSuccess, onSaveError);
            } else {
                DashboardSetting.save(vm.dashboardSetting, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
