(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('ProjectsController', ProjectController);

    ProjectController.$inject = ['$filter', '$scope', '$state', '$uibModal',
                                 'AlertService', 'Attachment', 'orderByFilter', 'ProcessInstance', 'Project', 'ProjectColumn', 'SceaProcessInstance'];

    function ProjectController ($filter, $scope, $state, $uibModal, 
    		AlertService, Attachment, orderByFilter, ProcessInstance, Project, ProjectColumn, SceaProcessInstance) {
        var vm = this;
        vm.variables = [];
        vm.project = null;
        vm.instancesList = [];
        vm.historicInstancesList = [];
        vm.pageRunning = 1;
        vm.sizeRunning = 20;
        vm.startRunning = 0;
        vm.pageHistoric = 1;
        vm.sizeHistoric = 20;
        vm.startHistoric = 0;
        vm.isHistoric = false;
    	vm.inputVariable = null;
        vm.removedVariables = [];
		vm.models = {
			selected : null,
			lists : []
		}
        
        Project.query(function(result) {
            vm.projects = orderByFilter(result, 'id', false);
        	vm.project = vm.projects[0];
        	vm.updateProject(vm.project);
        });
        
        vm.updateProject = function(project) {
        	if(project != null) {
        		if(project.processDefinitionId != null) {
                	var key = project.projectName;
                	if(!vm.isHistoric) {
                		vm.loadRunning();
                	} else {
                		vm.loadHistoric();
                    }
                	
	                vm.removedVariables = [];
	                vm.models.lists = [];

        			ProjectColumn.get({
        				projectId: project.id
        			}, function(result) {
    					var resultOrder = orderByFilter(result, 'sequence', false);
    					
    					angular.forEach(resultOrder, function(variable) {
    						if(variable.active) {
			    	        	vm.models.lists.push(variable);
    						} else {
    							vm.removedVariables.push(variable);
    						}
    					});
        			});
	        	} else {
	                vm.removedVariables = [];
	                vm.models.lists = [];
	        		vm.clear();
	        	}
        	}
        };
        
        function loadAll() {
        	if(!vm.isHistoric) {
            	if(vm.instancesList.size != null) {
            		vm.startRunning = parseInt(vm.pageRunning) * parseInt(vm.instancesList.size) - parseInt(vm.instancesList.size);
            	}

            	SceaProcessInstance.getProcessInstance({
            		businessKey: vm.project.projectName,
            		start: vm.startRunning,
            		size: vm.sizeRunning
            	}, onSuccess, onError);
        	}
        	if(vm.isHistoric) {
            	if(vm.historicInstancesList.size != null) {
            		vm.startHistoric = parseInt(vm.pageHistoric) * parseInt(vm.historicInstancesList.size) - parseInt(vm.historicInstancesList.size);
            	}

            	SceaProcessInstance.getHistoricProcessInstance({
            		businessKey: vm.project.projectName,
            		start: vm.startRunning,
            		size: vm.sizeRunning
            	}, onSuccess, onError);
        	}
        	
        	function onSuccess(result) {                
            	if(!vm.isHistoric) {
                    vm.totalItemsRunning = result.total;
                    vm.queryCountRunning = vm.totalItemsRunning;
        			vm.instancesList = result;
        			
	        		angular.forEach(vm.instancesList.data, function(runningTask, keyData) {
			        	parseData(vm.instancesList.data[keyData]);
		        	});            		
            	}
            	if(vm.isHistoric) {
                    vm.totalItemsHistoric = result.total;
                    vm.queryCountHistoric = vm.totalItemsHistoric;
        			vm.historicInstancesList = result;
        			
	        		angular.forEach(vm.historicInstancesList.data, function(historicTask, keyData) {
			        	parseData(vm.historicInstancesList.data[keyData]);
		        	});
            	}
        	}

            function onError(error) {
                AlertService.error(error.data.message);                	
            }
            
            function parseData(objParams) {    			
            	angular.forEach(objParams.variables, function(variable, keyVariable) {
    				if(variable.type == 'date') {
    					variable.value = $filter('date')(variable.value, 'medium');								
    				}
    				if(variable.type == 'scea_business_unit' || variable.type == 'scea_outlet' || 
    						variable.type == 'scea_project' || variable.type == 'scea_file') {

    					var strings = variable.value;
    					var json = JSON.stringify(eval("(" + strings + ")"));
    					var obj = JSON.parse(json);
    					
    					if(variable.type == 'scea_business_unit') {
    						objParams.variables[keyVariable].value = obj.name;
    					}
    					if(variable.type == 'scea_outlet') {
    						objParams.variables[keyVariable].value = obj.outletName;
    					}
    					if(variable.type == 'scea_project') {
    						objParams.variables[keyVariable].value = obj.projectName;
    					}
    					if(variable.type == 'scea_file') {
    						objParams.variables[keyVariable].value = obj;
    					}
    				}
    				if(vm.isHistoric) {
    					objParams.variables[keyVariable].writable = false;					
    				}
        		});
            }
        }
        
        vm.clear = function() {
            vm.instancesList = [];
            vm.historicInstancesList = [];  
            vm.pageRunning = 1;
            vm.sizeRunning = 20;
            vm.startRunning = 0;
            vm.pageHistoric = 1;
            vm.sizeHistoric = 20;
            vm.startHistoric = 0;  	            	
        }
        
        vm.loadRunning = function() {
            if(vm.project != null) {
        		if(vm.project.processDefinitionId != null) {
		        	vm.isHistoric = false;
		        	vm.clear();
		        	loadAll();
        		}
        	}
        }
        
        vm.loadHistoric = function() {
            if(vm.project != null) {
        		if(vm.project.processDefinitionId != null) {
		        	vm.isHistoric = true;
		        	vm.clear();
		        	loadAll();
        		}
    		}
        }

    	vm.downloadFile = function(file) {
        	Attachment.download({id: file.id});        		
    	}
    	
    	vm.openDiagram = function(data) {
    		ProcessInstance.getDiagram({ 
    			processInstanceId: data.id 
    		}, function(result) {
				var modalInstance = $uibModal.open({
                    templateUrl: 'app/merchandiser-activity/project-list/diagram-dialog.html',
                    controller: 'DiagramDialogController',
                    controllerAs: 'vm',
					size : 'lg',
					resolve : {
						diagram : function() {
							return result.blob;
						}
					}
				});
    		});
    	}
		
        vm.addInputVariable = function(variable) {
			if(variable != null) {
				addVariable(variable.columnAlias);
		        vm.inputVariable = '';
			}
        }

		vm.exportExcel = function() {
			var modalInstance = $uibModal.open({
                templateUrl: 'app/merchandiser-activity/project-list/export-dialog.html',
                controller: 'ExportDialogController',
				controllerAs: 'vm',
				size: 'md',
				resolve: {
					isHistoric: function() {
						return vm.isHistoric;
					},
					project: function() {
						return vm.project;
					}
				}
			});
    	}
        
		$scope.$watch('vm.inputVariable', function(newVariable, oldVariable) {
			if(newVariable != null && newVariable != '' && newVariable != oldVariable) {
				addVariable(newVariable);
		        vm.inputVariable = '';
			}
		}, true);
        
        function addVariable(variable) {
        	for(var i=0; i<vm.removedVariables.length; i++) {
        		if(vm.removedVariables[i].columnAlias == variable) {
                	var obj = vm.removedVariables[i];
                	obj.active = true;
                	obj.sequence = vm.models.lists.length+1;
                	
                	ProjectColumn.update(obj);
                	
        			vm.models.lists.push(vm.removedVariables[i]);
        			vm.removedVariables.splice(i, 1);
        			break;
        		}
        	}
        }
        
        vm.removeColumn = function(index) {
        	var obj = vm.models.lists[index];
        	obj.active = false;
        	obj.sequence = 0;
        	
        	ProjectColumn.update(obj);
        	
	        vm.removedVariables.push(vm.models.lists[index]);
        	vm.models.lists.splice(index, 1);
        	
        	updateSequence();
        }
        
        vm.moveColumn = function(index) {        	
        	vm.models.lists.splice(index, 1);
        	updateSequence();
        }
        
        vm.updateAlias = function(index) {
        	vm.isDisabledAlias[index] = true;
        	
        	var obj = vm.models.lists[index];
        	if(obj.columnAlias == null || obj.columnAlias == '') {
        		obj.columnAlias = obj.columnName;
        	}
        	
        	ProjectColumn.update(obj);
        }
        
        function updateSequence() {        	
        	angular.forEach(vm.models.lists, function(column, key) {
            	vm.isDisabledAlias[key] = true;
            	
            	var obj = vm.models.lists[key];
            	obj.sequence = key+1;
        		
            	ProjectColumn.update(obj);
        	});        	
        }
		
		$scope.$watch('vm.models', function(model) {
			vm.variables = model.lists;
		}, true);
    }
})();
