(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('InternetAnswerDeleteController',InternetAnswerDeleteController);

    InternetAnswerDeleteController.$inject = ['$uibModalInstance', 'entity', 'InternetAnswer'];

    function InternetAnswerDeleteController($uibModalInstance, entity, InternetAnswer) {
        var vm = this;
        vm.internetAnswer = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            InternetAnswer.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
