(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('TPopDetailController', TPopDetailController);

    TPopDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'TPop', 'Employee', 'Outlet', 'TicketOrder'];

    function TPopDetailController($scope, $rootScope, $stateParams, entity, TPop, Employee, Outlet, TicketOrder) {
        var vm = this;
        vm.tPop = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:tPopUpdate', function(event, result) {
            vm.tPop = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
