(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('RetailMappingCategoryDeleteController',RetailMappingCategoryDeleteController);

    RetailMappingCategoryDeleteController.$inject = ['$uibModalInstance', 'entity', 'RetailMappingCategory'];

    function RetailMappingCategoryDeleteController($uibModalInstance, entity, RetailMappingCategory) {
        var vm = this;
        vm.retailMappingCategory = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            RetailMappingCategory.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
