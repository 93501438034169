(function () {
    'use strict';

    angular
        .module('sceaApp')
        .controller('OutletDialogController', OutletDialogController);

    OutletDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'ParseLinks', 'AlertService', 'entity', 'Outlet', 'OutletSearch', 'Channel', 'OutletAccount', 'OutletType', 'User', 'Area', 'VisitCycle', 'UserSearch', 'AreaSearch', 'PopPairVersion', 'PopPairVersionSearch', 'leafletData'];

    function OutletDialogController($timeout, $scope, $stateParams, $uibModalInstance, ParseLinks, AlertService, entity, Outlet, OutletSearch, Channel, OutletAccount, OutletType, User, Area, VisitCycle, UserSearch, AreaSearch, PopPairVersion, PopPairVersionSearch, leafletData) {
        var vm = this;

        vm.outlet = entity;
        vm.channels = Channel.query();
        vm.outletaccounts = OutletAccount.query();
        vm.outlettypes = OutletType.query();
        vm.visitcycles = VisitCycle.query();
        vm.loadingUser = false;
        vm.loadingArea = false;
        vm.updateMarker = updateMarker;

        vm.getArea = getArea;
        vm.getPopPairVersion = getPopPairVersion;
        vm.getUser = getUser;
        vm.checkOutletCode = checkOutletCode;

        var drawControl, drawnItems;
        var marker = null;
        var baseMap = null;
        var control = null;

        if (vm.outlet.$promise) {
            vm.outlet.$promise.then(function (data) {
                if (data.promotorLogin != null) {
                    User.get({ login: data.promotorLogin }, function (promotor) {
                        vm.promotor = promotor;
                    });
                }

                if (data.vdoLogin != null) {
                    User.get({ login: data.vdoLogin }, function (vdo) {
                        vm.vdo = vdo;
                    });
                }

                if (data.rmSurveyorLogin != null) {
                    User.get({ login: data.rmSurveyorLogin }, function (rmSurveyor) {
                        vm.rmSurveyor = rmSurveyor;
                    });
                }

                if (data.areaId != null) {
                    Area.get({ id: data.areaId }, function (area) {
                        vm.outletArea = area;
                    });
                }

                if (data.popPairVersionId != null) {
                    PopPairVersion.get({ id: data.popPairVersionId }, function (popPairVersion) {
                        vm.popPairVersion = popPairVersion;
                    });
                }

                updateMarker();
            });
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
            fixMapOnModal();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sceaApp:outletUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;

            vm.outlet.promotorId = (vm.promotor) ? vm.promotor.id : null;
            vm.outlet.vdoId = (vm.vdo) ? vm.vdo.id : null;
            vm.outlet.rmSurveyorId = (vm.rmSurveyor) ? vm.rmSurveyor.id : null;
            vm.outlet.areaId = (vm.outletArea) ? vm.outletArea.id : null;
            vm.outlet.popPairVersionId = (vm.popPairVersion) ? vm.popPairVersion.id : null;

            if (vm.outlet.id !== null) {
                Outlet.update(vm.outlet, onSaveSuccess, onSaveError);
            } else {
                Outlet.save(vm.outlet, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };

        function getUser(role, value) {
            vm.loadingUser = true;
            var queryAndOrCriteria = {
                andCriteria: {
                    authorities: {
                        contains: role
                    }
                }
            };

            if (value) {
                queryAndOrCriteria = Object.assign(queryAndOrCriteria, {
                    orCriteria: {
                        id: {
                            equals: isNaN(value) ? '' : value
                        },
                        firstName: {
                            contains: value
                        }
                    }
                });
            }

            return User.andOrCriteria(queryAndOrCriteria).$promise.then(
                function (response) {
                    vm.loadingUser = false;
                    return response;
                },
                function (response) {
                    vm.loadingUser = false;
                    AlertService.error(response.data.message);
                }
            );
        }

        function getArea(value) {
            vm.loadingArea = true;
            return AreaSearch.query({
                query: 'areaName:*' + value + '* AND areaLevel.levelName:CITY'
            }).$promise.then(function (response) {
                vm.loadingArea = false;
                return response;
            }, function (response) {
                vm.loadingArea = false;
                AlertService.error(response.data.message);
            });
        };

        function getPopPairVersion(value) {
                vm.loadingPopPairVersion = true;
                return PopPairVersionSearch.query({
                    query: 'name:*' + value
                }).$promise.then(function (response) {
                    vm.loadingPopPairVersion = false;
                    return response;
                }, function (response) {
                    vm.loadingPopPairVersion = false;
                    AlertService.error(response.data.message);
                });
            };

        leafletData.getMap().then(function (map) {
            map.setView([-6.181, 106.821], 11);

            var klikpetaStreets = L.tileLayer('https://map1.klikpeta.com/klikpeta_tiles/{z}/{x}/{y}.png', {
                maxZoom: 20
            });

            klikpetaStreets.addTo(map);

            drawnItems = new L.FeatureGroup();

            map.addLayer(drawnItems);

            drawControl = new L.Control.Draw({
                position: 'topright',
                draw: {
                    polyline: false,
                    polygon: false,
                    rectangle: false,
                    circle: false,
                    marker: {
                        repeatMode: false,
                    }
                }
            });

            map.addControl(drawControl);
            map.on('draw:created', function (e) {
                marker = e.layer;
                drawnItems.addLayer(marker);

                var latLng = getLatLng(drawnItems);

                vm.outlet.latitude = latLng.lat;
                vm.outlet.longitude = latLng.lng;

                map.removeControl(drawControl);

                drawControl = new L.Control.Draw({
                    position: 'topright',
                    draw: false,
                    edit: {
                        featureGroup: drawnItems
                    }
                });

                map.addControl(drawControl);
            });
            map.on('draw:edited', function (e) {
                var latLng = getLatLng(drawnItems);

                vm.outlet.latitude = latLng.lat;
                vm.outlet.longitude = latLng.lng;
            });
            map.on('draw:deleted', function (e) {
                vm.outlet.latitude = null;
                vm.outlet.longitude = null;

                map.removeControl(drawControl);

                drawControl = new L.Control.Draw({
                    position: 'topright',
                    draw: {
                        polyline: false,
                        polygon: false,
                        rectangle: false,
                        circle: false,
                        marker: {
                            repeatMode: false
                        }
                    }
                });

                map.addControl(drawControl);
            });
            map.on('draw:drawstart', function (e) {
                vm.isDrawingMap = true;
            });
            map.on('draw:drawstop', function (e) {
                vm.isDrawingMap = false;
            });
            map.on('draw:editstart', function (e) {
                vm.isDrawingMap = true;
            });
            map.on('draw:editmove', function (e) {
                var latLng = getLatLng(drawnItems);

                vm.outlet.latitude = latLng.lat;
                vm.outlet.longitude = latLng.lng;
            });
            map.on('draw:editstop', function (e) {
                var latLng = getLatLng(drawnItems);

                vm.outlet.latitude = latLng.lat;
                vm.outlet.longitude = latLng.lng;

                vm.isDrawingMap = false;
            });
            map.on('draw:deletestart', function (e) {
                vm.isDrawingMap = true;
            });
            map.on('draw:deletestop', function (e) {
                vm.isDrawingMap = false;
            });
        });

        function getLatLng(drawnItems) {
            var shapes = [];

            drawnItems.eachLayer(function (layer) {
                if (layer instanceof L.Marker) {
                    shapes.push([layer.getLatLng()]);
                }
            });

            var latLng = shapes[0][0];
            return latLng;
        }

        function updateMarker() {
            if (vm.outlet.longitude != null && vm.outlet.latitude != null) {
                leafletData.getMap().then(function (map) {
                    if (marker != null) {
                        drawnItems.removeLayer(marker);
                    }

                    var latlng = L.latLng(vm.outlet.latitude, vm.outlet.longitude);
                    marker = L.marker(latlng, {}).addTo(map);

                    map.panTo(latlng);
                    drawnItems.addLayer(marker);

                    map.removeControl(drawControl);

                    drawControl = new L.Control.Draw({
                        position: 'topright',
                        draw: false,
                        edit: {
                            featureGroup: drawnItems
                        }
                    });

                    map.addControl(drawControl);
                });
            }
        }

        function fixMapOnModal() {
            leafletData.getMap().then(function (map) {
                map.invalidateSize();
            });
        }

        function checkOutletCode(code) {
            OutletSearch.query({
                query: 'outletCode:' + code
            }, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                if (vm.totalItems > 0) {
                    $scope.editForm.outletCode.$setValidity('unique', false);
                }
            }
            function onError(error) {
                console.log(error.data.message);
            }
        }
    }
})();
