(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('OtherJobStoreCompletenessDialogController', OtherJobStoreCompletenessDialogController);

    OtherJobStoreCompletenessDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'OtherJobStoreCompleteness', 'StoreCompleteness', 'OtherJob'];

    function OtherJobStoreCompletenessDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, OtherJobStoreCompleteness, StoreCompleteness, OtherJob) {
        var vm = this;
        vm.otherJobStoreCompleteness = entity;
        vm.storecompletenesses = StoreCompleteness.query();
        vm.otherjobs = OtherJob.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sceaApp:otherJobStoreCompletenessUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.otherJobStoreCompleteness.id !== null) {
                OtherJobStoreCompleteness.update(vm.otherJobStoreCompleteness, onSaveSuccess, onSaveError);
            } else {
                OtherJobStoreCompleteness.save(vm.otherJobStoreCompleteness, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
