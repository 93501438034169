(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('ProductDetailController', ProductDetailController);

    ProductDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Product', 'ProductCategory', 'LaunchPeriod', 'ProductInch'];

    function ProductDetailController($scope, $rootScope, $stateParams, entity, Product, ProductCategory, LaunchPeriod, ProductInch) {
        var vm = this;
        vm.product = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:productUpdate', function(event, result) {
            vm.product = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
