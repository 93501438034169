(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('PromotionDtlDeleteController',PromotionDtlDeleteController);

    PromotionDtlDeleteController.$inject = ['$uibModalInstance', 'promotion', 'entity', 'PromotionDtl'];

    function PromotionDtlDeleteController($uibModalInstance, promotion, entity, PromotionDtl) {
        var vm = this;

        vm.promotion = promotion;
        vm.promotionDtl = entity;

        if(vm.promotionDtl.$promise) {
            vm.promotionDtl.$promise.then(function(data) {
                if(checkExpiredPromotion(vm.promotion.validUntil)){
                    vm.clear();
                }
            });
        }

        function checkExpiredPromotion(params) {
            var checkExpired = moment(params).isBefore(moment());
            return (checkExpired) ? true : false;
        }

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            PromotionDtl.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
