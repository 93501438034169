(function() {
  'use strict';

  angular
    .module('sceaApp')
    .controller('EditManagerController', EditManagerController);

  EditManagerController.$inject = ['$scope', '$state', '$filter', 'AlertService', 'User', 'UserSearch'];

  function EditManagerController ($scope, $state, $filter, AlertService, User, UserSearch) {
	  var vm = this;
	  
	  vm.searchBy = 'manager';
	  vm.predicate = 'firstName';
	  vm.reverse = true;
	  vm.predicateSelected = 'firstName';
	  vm.reverseSelected = true;
	  vm.isGetUsers = false;
	  vm.dataSearch = [];
	  vm.selectedUsers = [];
	  
	  vm.clear = clear;
	  vm.getNewManager = getNewManager;
	  vm.getUser = getUser;
	  vm.moveUsers = moveUsers;
	  vm.search = search;
      
      vm.selectAll = selectAll;
	  vm.selectUser = selectUser;
	  
	  function search(searchQuery) {
		  var managerId = null;

		  vm.dataSearch = [];
		  
		  if(vm.searchBy == 'manager' && vm.currentUser) {
			  managerId = vm.currentUser.id;  
			  
			  getUsers(0, managerId);
		  }

		  if(vm.searchBy == 'user') {
			  vm.dataSearch.push(vm.currentUser);
		  }
	  }
	  
	  function getUsers(page, managerId) {
		  vm.isGetUsers = true;
		  
          User.query({
        	  page: page,
        	  managerId: managerId,
        	  roleName: 'PC'
          }, function(data, headers) {
        	  angular.forEach(data, function(user) {
        		  user.selected = true;
            	  
        		  vm.dataSearch.push(user);
        	  });

              var users = headers('X-Total-Count');
              var totalNow = vm.dataSearch.length;
              
              if(totalNow != users) {
                  page++;
                  getUsers(page, managerId);
              } else {
        		  vm.isGetUsers = false;
              }
          });
	  }
	  
	  function clear() {
		  vm.searchQuery = null;
		  vm.currentUser = null;
		  vm.dataSearch = [];
	  }

      function getUser(value) {
    	  vm.loadingUser = true;

    	  var roleName;
		  if(vm.searchBy == 'user') {
			  roleName = 'PC';
		  }
		  
    	  return UserSearch.query({
    		  query: value,
    		  roleName: roleName
          }).$promise.then(function(response){
        	  vm.loadingUser = false;

              return response;
          }, function(response){
        	  vm.loadingUser = false;
              AlertService.error(response.data.message);
          });
      }

      function getNewManager(value) {
    	  vm.loadingNewManager = true;
		  
    	  return UserSearch.query({
    		  query: value,
          }).$promise.then(function(response){
        	  vm.loadingNewManager = false;

              return response;
          }, function(response){
        	  vm.loadingNewManager = false;
              AlertService.error(response.data.message);
          });
      }
      
      function selectAll(selectAll) {
    	  var filters = ($filter('filter')(vm.dataSearch, {selected : !selectAll}, true));
    	  
    	  if(filters.length > 0) {
    		  angular.forEach(filters, function(user) {
    			  user.selected = selectAll;
    		  });
    	  }
      }
      
      function selectUser(user) {
		  user.selected = true;
		  
    	  vm.currentUser = user;
      }
      
      function moveUsers() {
    	  var filters1 = ($filter('filter')(vm.dataSearch, {selected : true}, true));

    	  if(filters1.length > 0) {
    		  angular.forEach(filters1, function(user) {
    			  if(vm.selectedUsers.length > 0) {
    				  var filters2 = ($filter('filter')(vm.selectedUsers, {id : user.id}, true));

    				  if(filters2.length == 0) {
    					  vm.selectedUsers.push(user);
    				  }
    			  } else {
    				  vm.selectedUsers.push(user);
    			  }
    		  });
    	  }
      }

      vm.save = save;
      
      function save() {
          vm.isSaving = true;
          
          var users = [];
          angular.forEach(vm.selectedUsers, function(user) {
        	  users.push(user.login);
          });
          
          var userManager = {
    		  managedByUser: vm.newManager.login,
    		  users: users
          }
          
          User.updateManager(userManager, onSaveSuccess, onSaveError);
      }

      var onSaveSuccess = function (result) {
          AlertService.success($filter('translate')('userManagement.updatedManager', {param: result.managedByUser}));
          
          vm.newManager = null;
          vm.selectedUsers = [];
          clear();
          
          vm.isSaving = false;
      }

      var onSaveError = function () {
          vm.isSaving = false;
      }
  }
})();