(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('TStoreCompletenessDetailController', TStoreCompletenessDetailController);

    TStoreCompletenessDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'TStoreCompleteness', 'TPop', 'StoreCompleteness'];

    function TStoreCompletenessDetailController($scope, $rootScope, $stateParams, entity, TStoreCompleteness, TPop, StoreCompleteness) {
        var vm = this;
        vm.tStoreCompleteness = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:tStoreCompletenessUpdate', function(event, result) {
            vm.tStoreCompleteness = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
