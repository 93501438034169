(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('PlanogramFixtureDetailController', PlanogramFixtureDetailController);

    PlanogramFixtureDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PlanogramFixture', 'Planogram', 'Fixtures'];

    function PlanogramFixtureDetailController($scope, $rootScope, $stateParams, entity, PlanogramFixture, Planogram, Fixtures) {
        var vm = this;
        vm.planogramFixture = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:planogramFixtureUpdate', function(event, result) {
            vm.planogramFixture = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
