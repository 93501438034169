(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('OutletpointDialogController', OutletpointDialogController);

    OutletpointDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity',
        'Outletpoint', 'Outlet', 'Droppoint', 'OutletSearch', 'DroppointSearch'];

    function OutletpointDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Outletpoint,
        Outlet, Droppoint, OutletSearch, DroppointSearch) {

        var vm = this;
        vm.outletpoint = entity;

        vm.searchingOutlet = false;
        vm.searchingDroppoint = false;
        vm.selectedOutlet;
        vm.selectedDroppoint;

        if (vm.outletpoint.$promise) {
            vm.outletpoint.$promise.then(function(outletpoint){
                if (outletpoint.outletId) {
                    Outlet.get({id: outletpoint.outletId}, function(outlet){
                        vm.selectedOutlet = outlet;
                    });
                }

                if (outletpoint.droppointId) {
                    Droppoint.get({id: outletpoint.droppointId}, function(droppoint){
                        vm.selectedDroppoint = droppoint;
                    });
                }
            });
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sceaApp:outletpointUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.outletpoint.id !== null) {
                Outletpoint.update(vm.outletpoint, onSaveSuccess, onSaveError);
            } else {
                Outletpoint.save(vm.outletpoint, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.$watch(angular.bind(this, function(){
            return this.selectedOutlet;
        }), function(newValue, oldValue){
            if (newValue){
                vm.outletpoint.outletId = newValue.id;
            } else {
                vm.outletpoint.outletId = null;
            }
        });

        $scope.$watch(angular.bind(this, function(){
            return this.selectedDroppoint;
        }), function(newValue, oldValue){
            if (newValue) {
                vm.outletpoint.droppointId = newValue.id;
            } else {
                vm.outletpoint.droppointId = null;
            }

        });

        vm.searchOutlet = function(keyword) {
            vm.searchingOutlet = true;
            return OutletSearch.query({query: keyword, filter: 'outletpoint-is-null'})
                .$promise.then(function(data){
                    vm.searchingOutlet = false;
                    return data;
                }, function(error){
                    vm.searchingOutlet = false;
                });
        }

        vm.searchDroppoint = function(keyword) {
            vm.searchingDroppoint = true;
            return DroppointSearch.query({query: keyword})
                .$promise.then(function(data){
                    vm.searchingDroppoint = false;
                    return data;
                }, function(error){
                    vm.searchingDroppoint = false;
                });
        }
    }
})();
