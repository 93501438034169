(function() {
    'use strict';

    angular.module('sceaApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('task', {
            parent: 'merchandiser-activity',
            url: '/merchandiser-activity/store-completion/tasks',
            data: {
                authorities: ['ROLE_ADMIN', 'SPV', 'VDO'],
                pageTitle: 'sceaApp.tasks.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/merchandiser-activity/task/tasks.html',
                    controller: 'TasksController',
                    controllerAs: 'vm'
                },
                'inbox@task': {
                    templateUrl: 'app/merchandiser-activity/task/inbox.html',
                    controller: 'InboxController',
                    controllerAs: 'vm'
                },
                'allTasks@task': {
                    templateUrl: 'app/merchandiser-activity/task/alltasks.html',
                    controller: 'AllTasksController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('global');
                	$translatePartialLoader.addPart('task');
                	$translatePartialLoader.addPart('attachment');
                    return $translate.refresh();
                }]
            }
        })
        .state('task-detail', {
            parent: 'merchandiser-activity',
            url: '/merchandiser-activity/store-completion/task/{id}/{status}',
            data: {
                authorities: ['ROLE_ADMIN', 'SPV', 'VDO'],
                pageTitle: 'sceaApp.task.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/merchandiser-activity/task/task-detail.html',
                    controller: 'TaskDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('task');
                	$translatePartialLoader.addPart('attachment');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Task', function($stateParams, Task) {
                	if($stateParams.status != 'historic'){
                		return Task.get({taskId: $stateParams.id});
                	} else {
                		return null;
                	}
                }]
            }
        });
    }
})();
