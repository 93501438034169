(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('ChannelDialogController', ChannelDialogController);

    ChannelDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Channel'];

    function ChannelDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Channel) {
        var vm = this;
        vm.channel = entity;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sceaApp:channelUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.channel.id !== null) {
                Channel.update(vm.channel, onSaveSuccess, onSaveError);
            } else {
                Channel.save(vm.channel, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
