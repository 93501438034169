(function() {
    'use strict';

    angular
        .module('sceaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('spv-call-plan-creation', {
            parent: 'admin',
            url: '/spv-call-plan-creation',
            data: {
                authorities: ['ROLE_ADMIN', 'ARCO', 'SPV'],
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/spv-call-plan-creation/spv-call-plan-creation.html',
                    controller: 'SpvCallPlanCreationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                	$translatePartialLoader.addPart('outlet');
                	$translatePartialLoader.addPart('calendar');
                	$translatePartialLoader.addPart('spvCallPlanCreation');
                	$translatePartialLoader.addPart('ticketOrder');
                	$translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('spv-call-plan-creation.outlet', {
            parent: 'spv-call-plan-creation',
            url: '/{employeeId}/week/{calendarId}',
            data: {
                authorities: ['ROLE_ADMIN', 'ARCO', 'SPV']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'TicketOrder', function($stateParams, $state, $uibModal, TicketOrder) {
                $uibModal.open({
                    templateUrl: 'app/admin/spv-call-plan-creation/spv-outlet-dialog.html',
                    controller: 'SpvOutletDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                    	outlets: ['TicketOrder', function(TicketOrder) {
                            return TicketOrder.queryOutlets({employeeId: $stateParams.employeeId, calendarId : $stateParams.calendarId});
                        }]
                    }
                }).result.then(function() {
                    $state.go('spv-call-plan-creation', null, { reload: true });
                }, function() {
                    $state.go('spv-call-plan-creation', null, { reload: true });
                });
            }]
        });
    }
})();
