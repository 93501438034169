(function() {
    'use strict';

    angular
        .module('sceaApp')
        .factory('RetailMappingSubCategorySearch', RetailMappingSubCategorySearch);

    RetailMappingSubCategorySearch.$inject = ['$resource'];

    function RetailMappingSubCategorySearch($resource) {
        var resourceUrl =  'api/_search/retail-mapping-sub-categories/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
