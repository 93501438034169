(function () {
    'use strict';

    angular
        .module('sceaApp')
        .directive('disableDecimalInput', disableDecimalInput);

    disableDecimalInput.$inject = [];

    function disableDecimalInput() {
        return {
            require: 'ngModel',
            link: function ($scope, $element, $attrs, ngModelCtrl) {
                function isNotAllowed(key) {
                    var minusCode = [110, 190];

                    return minusCode.includes(key);
                }

                $element.bind('keydown', function (event) {
                    var key = event.keyCode;
                    if (isNotAllowed(key))
                        event.preventDefault();
                });
            }
        }
    }
})();