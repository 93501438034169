(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('OutletEmployeeController', OutletEmployeeController);

    OutletEmployeeController.$inject = ['$rootScope', '$scope', '$state', '$uibModal', 'Outlet'];

    function OutletEmployeeController ($rootScope, $scope, $state, $uibModal, Outlet) {
        var vm = this;
        vm.outletsByEmployee = [];
        
		$rootScope.$watch('vdo', function(vdo) {
			if(vdo != null) {
		        vm.outletsByEmployee = Outlet.queryByEmployee({id: vdo.id});
			}
		});
		
        vm.openInfoDialog = function() {
            var modalInstance = $uibModal.open({
            	animation: $scope.animationsEnabled,
            	templateUrl: 'app/system-administration/visit-cycle/visit-cycle-info-dialog.html',
            	controller: 'VisitCycleInfoDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
            	size: "sm",
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    mainTranslatePartialLoader: ['$stateParams', '$translate', '$translatePartialLoader', 
                        function ($stateParams, $translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('visitCycle');
                        return $translate.refresh();
                    }]
                }
            });

            modalInstance.result.then(function() {
                $state.go('^');
            });   	
        }
    }
})();
