(function() {
    'use strict';
    angular
        .module('sceaApp')
        .factory('DynamicForm', DynamicForm);

    DynamicForm.$inject = ['$resource'];

    function DynamicForm ($resource) {

        return $resource(null, {}, {
        	'getBusinessUnit' : {
        		url: 'api/bussiness-units',
            	method: 'GET',
            	isArray: true 		
        	},
        	'getABusinessUnit' : {
        		url: 'api/bussiness-units/:id',
            	method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }	
        	},
        	'calculateFormula' : {
        		url: 'api/formula',
            	method: 'POST',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    var result = {};
                    result['value'] = data;
                    return result;
                }
        	}
        });
    }
})();
