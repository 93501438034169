(function() {
    'use strict';

    angular
      .module('sceaApp')
      .controller('OutletMapController', OutletMapController);

    OutletMapController.$inject = ['$scope', '$state', 'leafletData', 'Outlet'];

    function OutletMapController ($scope, $state, leafletData, Outlet) {
        var vm = this;
        var outletMap = null;
        var baseMap = null;
        var control = null;

        vm.init = init;
        vm.init();

        function init() {

            Outlet.getGeoJson({}, onSuccess, onError);
            function onSuccess(data) {

                    var geoJsonData = {
                        'type': "Feature Collection",
                        'features': data.features
                    }

                    var markers = L.markerClusterGroup();
                    var geoJsonLayer = L.geoJson(geoJsonData, {
                        onEachFeature: function(feature, layer) {
                            var coords = L.latLng(feature.geometry.coordinates);
                            var popupHover = L.popup({ closeOnClick: false })
                                .setContent(feature.properties.code + ", " + feature.properties.name + "<br>" + "Click marker for more information");
                            var popupClick = L.popup({ closeOnClick: false });
                            layer.on('mouseover', function() {
                                layer.bindPopup(popupHover).openPopup();
                                Outlet.get({id: feature.id}, onSuccess, onError);
                                function onSuccess(data) {
                                    popupClick.setContent(
                                        "Code: " + data.outletCode + "<br>" +
                                        "Name: " + data.outletName + "<br>" +
                                        "Address: " + data.outletAddress + "<br>" +
                                        "Latitude: " + data.latitude + "<br>" +
                                        "Longitude: " + data.longitude + "<br>" +
                                        "Facility: " + data.outletFacility + "<br>" +
                                        "PC Status: " + data.pcStatus + "<br>" +
                                        "Perfect Store Status: " + data.perfectStoreStatus + "<br>" +
                                        "RIS Status: " + data.risStatus + "<br>"
                                    );
                                }
                                function onError(error) {
                                    AlertService.error(error.data.message);
                                }
                            });
                            layer.on('click', function() {
                                layer.bindPopup(popupClick);
                            });
                        }
                    });

                    markers.addLayer(geoJsonLayer);

                    outletMap.addLayer(markers);

                    outletMap.fitBounds(markers.getBounds());

                    var searchControl = new L.Control.Search({
                        layer: geoJsonLayer,
                        propertyName: 'name',
                        marker: false,
                        moveToLocation: function(coords) {
                            outletMap.setView(coords, 15);
                        }
                    });

                    outletMap.addControl( searchControl );

            }

            function onError(error) {
                AlertService.error(error.data.message);
            }

            outletMap = L.map('outlet-map', {zoomControl: false}).setView([-1, 120], 5);
            outletMap.myLayers = [];

            var klikpetaStreets = L.tileLayer('https://map1.klikpeta.com/klikpeta_tiles/{z}/{x}/{y}.png',{
                maxZoom: 20
            });

        	var googleSat = L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',{
        	    maxZoom: 20,
        	    subdomains:['mt0','mt1','mt2','mt3']
        	});

        	klikpetaStreets.addTo(outletMap);

        	baseMap = {
        		'Klikpeta Streets': klikpetaStreets,
        	    'Google Satellite': googleSat
        	};

    	    var groupedOverlays = { 'Batas Wilayah Demarkasi': {} };

    	    outletMap.control = L.control.groupedLayers(baseMap, groupedOverlays).addTo(outletMap);

        }
    }
})();
