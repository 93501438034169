(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('FixturesDialogController', FixturesDialogController);

    FixturesDialogController.$inject = ['$timeout', '$scope', 'ParentData', '$uibModalInstance', 'entity', 'Fixtures', 'ProductCategory', 'FixturesSearch', 'hasChild'];

    function FixturesDialogController ($timeout, $scope, ParentData, $uibModalInstance, entity, Fixtures, ProductCategory, FixturesSearch, hasChild) {
        var vm = this;
        vm.fixture = entity;
        vm.productCategories = ProductCategory.getAllProductCategories({level: 2});
        vm.loadingFixture = false;
        vm.fixtureParent = null;
        vm.disableParent = hasChild == 'true';
        vm.parents = ParentData.getParentData();

        if (vm.fixture.$promise) {
        	vm.fixture.$promise.then(function(data) {
                vm.parents = vm.parents.filter(function(parent){
                    return parent.id != vm.fixture.id;
                });
        		vm.fixtureParent = {
					"id" : data.parentId,
					"fixtureName" : data.parentName
				}
			}, function(error) {
				console.log(error);
			});
		}

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sceaApp:fixturesUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if(vm.fixtureParent){
            	vm.fixture.parentId = vm.fixtureParent.id;
            }else{
            	vm.fixture.parentId = null;
            }
            if (vm.fixture.id !== null) {
                Fixtures.update(vm.fixture, onSaveSuccess, onSaveError);
            } else {
                Fixtures.save(vm.fixture, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
