(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('OtherJobBrandDialogController', OtherJobBrandDialogController);

    OtherJobBrandDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'OtherJobBrand', 'Brand', 'ProductInch', 'ProductCategory', 'OtherJob'];

    function OtherJobBrandDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, OtherJobBrand, Brand, ProductInch, ProductCategory, OtherJob) {
        var vm = this;
        vm.otherJobBrand = entity;
        vm.brands = Brand.query();
        vm.productinches = ProductInch.query();
        vm.productcategories = ProductCategory.query();
        vm.otherjobs = OtherJob.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sceaApp:otherJobBrandUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.otherJobBrand.id !== null) {
                OtherJobBrand.update(vm.otherJobBrand, onSaveSuccess, onSaveError);
            } else {
                OtherJobBrand.save(vm.otherJobBrand, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
