(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('TPopInternetDetailController', TPopInternetDetailController);

    TPopInternetDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'TPopInternet', 'TPop', 'Internet'];

    function TPopInternetDetailController($scope, $rootScope, $stateParams, entity, TPopInternet, TPop, Internet) {
        var vm = this;
        vm.tPopInternet = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:tPopInternetUpdate', function(event, result) {
            vm.tPopInternet = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
