(function() {
    'use strict';
    angular
        .module('sceaApp')
        .factory('OrderPop', OrderPop);

    OrderPop.$inject = ['$resource', 'DateUtils'];

    function OrderPop ($resource, DateUtils) {
        var resourceUrl =  'api/order-pops/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.requestDate = DateUtils.convertDateTimeFromServer(data.requestDate);
                    data.sentDate = DateUtils.convertDateTimeFromServer(data.sentDate);
                    data.deliveredDate = DateUtils.convertDateTimeFromServer(data.deliveredDate);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'queryOutlets': { 
            	url: 'api/order-pops/outlets',
            	method: 'GET', 
            	isArray: true
            }
        });
    }
})();
