(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('OtherJobBrandDeleteController',OtherJobBrandDeleteController);

    OtherJobBrandDeleteController.$inject = ['$uibModalInstance', 'entity', 'OtherJobBrand'];

    function OtherJobBrandDeleteController($uibModalInstance, entity, OtherJobBrand) {
        var vm = this;
        vm.otherJobBrand = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            OtherJobBrand.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
