(function() {
    'use strict';

    angular
    	.module('sceaApp')
    	.config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('call-plan-creation', {
            parent: 'merchandiser-activity',
            url: '/merchandiser-activity/call-plan-creation',
            data: {
                authorities: ['ROLE_ADMIN', 'VDO', 'VDR'],
                pageTitle: 'sceaApp.callPlanCreation.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/merchandiser-activity/call-plan-creation/call-plan-creations.html',
                    controller: 'CallPlanCreationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('outlet');
                	$translatePartialLoader.addPart('calendar');
                	$translatePartialLoader.addPart('callPlanCreation');
                	$translatePartialLoader.addPart('ticketOrder');
                	$translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('call-plan-creation.outlet', {
            parent: 'call-plan-creation',
            url: '/{vdoId}/{vdeId}/week/{calendarId}',
            data: {
                authorities: ['ROLE_ADMIN', 'VDO', 'VDR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'TicketOrder', function($stateParams, $state, $uibModal, TicketOrder) {
                $uibModal.open({
                    templateUrl: 'app/merchandiser-activity/call-plan-creation/vde-outlet-dialog.html',
                    controller: 'VdeOutletDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                    	outlets: ['TicketOrder', function(TicketOrder) {
                            return TicketOrder.queryOutlets({employeeId: $stateParams.vdeId, calendarId : $stateParams.calendarId});
                        }]
                    }
                }).result.then(function() {
                    $state.go('call-plan-creation', null, { reload: true });
                }, function() {
                    $state.go('call-plan-creation', null, { reload: true });
                });
            }]
        });
    }
})();
