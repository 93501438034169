(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('OutletRetailMappingDeleteController',OutletRetailMappingDeleteController);

    OutletRetailMappingDeleteController.$inject = ['$uibModalInstance', 'entity', 'OutletRetailMapping'];

    function OutletRetailMappingDeleteController($uibModalInstance, entity, OutletRetailMapping) {
        var vm = this;

        vm.outletRetailMapping = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            OutletRetailMapping.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
