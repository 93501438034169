(function() {
    'use strict';
    angular
        .module('sceaApp')
        .factory('Attachment', Attachment);

    Attachment.$inject = ['$resource', 'DateUtils'];

    function Attachment ($resource, DateUtils) {
        var resourceUrl =  'api/attachments/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.creationDate = DateUtils.convertDateTimeFromServer(data.creationDate);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'upload': { method:'POST' },
            'download': {
            	url: 'api/attachments/:id/binary',
                method: 'GET',
            	data: '',
                headers: {
                    "Content-Type": "application/json"
                },
                responseType: 'arraybuffer',
                transformResponse: function (data, headersGetter) {
                    var contentDisposition = headersGetter('Content-Disposition');
                    var filename = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition)[1];
                	var filetype = headersGetter('Content-Type');
                    var blob = new Blob([data], {type: filetype});
                    saveAs(blob, filename, true);
                    return true;
                }
            }
        });
    }
})();
