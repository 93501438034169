(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('TFileImageOtherJobDeleteController',TFileImageOtherJobDeleteController);

    TFileImageOtherJobDeleteController.$inject = ['$uibModalInstance', 'entity', 'TFileImageOtherJob'];

    function TFileImageOtherJobDeleteController($uibModalInstance, entity, TFileImageOtherJob) {
        var vm = this;
        vm.tFileImageOtherJob = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            TFileImageOtherJob.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
