(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('AdjustedAccumulationController', AdjustedAccumulationController);

    AdjustedAccumulationController.$inject = ['$scope', '$state', 'AdjustedAccumulation', 'AdjustedAccumulationSearch'];

    function AdjustedAccumulationController ($scope, $state, AdjustedAccumulation, AdjustedAccumulationSearch) {
        var vm = this;
        vm.adjustedAccumulations = [];
        vm.loadAll = function() {
            AdjustedAccumulation.query(function(result) {
                vm.adjustedAccumulations = result;
            });
        };

        vm.search = function () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            AdjustedAccumulationSearch.query({query: vm.searchQuery}, function(result) {
                vm.adjustedAccumulations = result;
            });
        };
        vm.loadAll();
        
    }
})();
