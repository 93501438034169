(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('InboxController', InboxController);

    InboxController.$inject = ['$scope', '$state', 'AlertService', 'Principal', 'SceaProcessInstance'];

    function InboxController($scope, $state, AlertService, Principal, SceaProcessInstance) {
        var vm = this;
        vm.inboxTasks = [];
        vm.page = 1;
        vm.size = 20;
        vm.start = 0;
		
        function getAccount() {
            Principal.identity().then(function(account) {
            	for(var i=0; i<account.authorities.length; i++) {
            		if(account.authorities[i] != 'ROLE_ADMIN') {
            			vm.role = account.authorities[i];
            			if(vm.role == 'VDO') {
            				getProjectId(vm.role);
            			} else {
                			getRuntimeTasks(vm.role, null);            				
            			}
            			break;
            		}
            	}
            });
        };
        getAccount();

        var getProjectId = function(role) {
        	var ids = '';
        	SceaProcessInstance.getProcessInstance(function(result) {
        		angular.forEach(result.data, function(project, key) {
            		if(key != 0) {
            			ids += ',';
            		}
        			ids += project.processInstanceId;
        			
            		if(result.data.length-1 == key) {
            			getRuntimeTasks(role, ids);
            		}
        		});
        	});
        }
        
        var getRuntimeTasks = function(role, projectId) {
        	if(vm.inboxTasks.size != null) {
        		vm.start = parseInt(vm.page) * parseInt(vm.tasks.size) - parseInt(vm.tasks.size);
        	}

			if(projectId == null) {
				SceaProcessInstance.getRuntimeTask({
	        		includeProcessVariables: true,
	        		candidateGroup: role,
	    			start: vm.start,
	    			size: vm.size
		    	}, onSuccess, onError);
			} else {
				SceaProcessInstance.getRuntimeTask({
	        		includeProcessVariables: true,
	        		processInstanceIds: projectId,
	    			start: vm.start,
	    			size: vm.size
		    	}, onSuccess, onError);				
			}

            function onSuccess(data) {
                vm.totalItems = data.total;
                vm.queryCount = vm.totalItems;
                vm.inboxTasks = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
		};
		
		vm.splitCategory = function(input) {
			if(input == null) return '';
			else {
				var string = input.split('-');
				return string[0];
			}
		};
		
		vm.splitCandidate = function(input) {
			if(input == null) return '';
			else {
				var string = input.split('-');
				return string[1];
			}
		};
		
		vm.getVariableValue = function(obj, params) {
			var result = '';
			angular.forEach(obj, function(value, key) {
				if(params == value.name) {
					if(params == 'project') {
						var businessUnit = JSON.parse(value.value);
						result = businessUnit.projectName;
					} else if(params == 'business_unit') {
						var businessUnit = JSON.parse(value.value);
						result = businessUnit.name;
					} else if(params == 'store') {
						var store = JSON.parse(value.value);
						result = store.outletName;
					} else {
						result = value.value;
					}
				}
			});
			return result;
		};
    }
})();
