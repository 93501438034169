(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('ProjectDialogController', ProjectDialogController);

    ProjectDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 
                                       'DataUtils', 'Deployment', 'ProcessDefinition', 'Project', 'Upload'];

    function ProjectDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, 
    		DataUtils, Deployment, ProcessDefinition, Project, Upload) {    	
        var vm = this;
        vm.project = entity;
        vm.deployAndGetProcessProgress = false;
        vm.deployError = true;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sceaApp:projectUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.project.id !== null) {
                Project.update(vm.project, onSaveSuccess, onSaveError);
            } else {
                Project.save(vm.project, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
            console.log($uibModalInstance);
            if(vm.deploymentId) {
            	Deployment.delete({id:vm.deploymentId})
            }
        };
        
        vm.nullValue = function(){
        	vm.project.processDefinitionId = null;
        }
        
        vm.deployBpmFile = function(file, errFiles) {
        	//vm.deployAndGetProcessProgress = true;
        	vm.bpm_file = file;
        	vm.errFile = errFiles && errFiles[0];
        	vm.deployError = true;
        	if(file) {
        		file.upload = Upload.upload({
        			url: 'bpm-rest/repository/deployments',
        			data: {file: file}
        		});
        		
        		file.upload.then(function(response){
        			$timeout(function() {
        				file.result = response.data;
        			});
        			vm.deploymentId = response.data.id;
        			ProcessDefinition.query({deploymentId: vm.deploymentId},function(result){
        				vm.project.processDefinitionId = result.data[0].id;
        				///vm.deployAndGetProcessProgress = false;
        			});
        		},function(response) {
        			if(response.status > 0) {
        				vm.deployError = false;
        				vm.bpm_file = null;
        				//vm.deployAndGetProcessProgress = false;
        				vm.errorMsg = response.data.exception;
//        				console.log(response);
        			}
        		}, function(evt) {
        			file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
        		});
        	}
        }
    }
})();
