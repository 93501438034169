(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('PlanogramDeleteController',PlanogramDeleteController);

    PlanogramDeleteController.$inject = ['$uibModalInstance', 'entity', 'Planogram'];

    function PlanogramDeleteController($uibModalInstance, entity, Planogram) {
        var vm = this;
        vm.planogram = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            Planogram.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
