(function() {
    'use strict';

    angular
        .module('sceaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('outlet', {
            parent: 'entity',
            url: '/outlet?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN', 'VDR'],
                pageTitle: 'sceaApp.outlet.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/outlet/outlets.html',
                    controller: 'OutletController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('outlet');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('outlet-detail', {
            parent: 'entity',
            url: '/outlet/{id}',
            data: {
                authorities: ['ROLE_ADMIN', 'VDR'],
                pageTitle: 'sceaApp.outlet.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/outlet/outlet-detail.html',
                    controller: 'OutletDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('outlet');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Outlet', function($stateParams, Outlet) {
                    return Outlet.get({id : $stateParams.id});
                }]
            }
        })
        .state('outlet.new', {
            parent: 'outlet',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN', 'VDR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/outlet/outlet-dialog.html',
                    controller: 'OutletDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                outletCode: null,
                                outletName: null,
                                outletAddress: null,
                                latitude: null,
                                longitude: null,
                                outletFacility: null,
                                pcStatus: null,
                                perfectStoreStatus: null,
                                risStatus: null,
                                deleted: false,
                                startVisitWeek: null,
                                id: null,
                                popPairVersionId: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('outlet', null, { reload: true });
                }, function() {
                    $state.go('outlet');
                });
            }]
        })
        .state('outlet.edit', {
            parent: 'outlet',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN', 'VDR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/outlet/outlet-dialog.html',
                    controller: 'OutletDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Outlet', function(Outlet) {
                            return Outlet.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('outlet', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('outlet.delete', {
            parent: 'outlet',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN', 'VDR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/outlet/outlet-delete-dialog.html',
                    controller: 'OutletDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Outlet', function(Outlet) {
                            return Outlet.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('outlet', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('outlet-map', {
            parent: 'outlet',
            url: '/map',
            data: {
                authorities: ['ROLE_ADMIN', 'VDR'],
                pageTitle: 'sceaApp.outlet.map.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/outlet/outlet-map.html',
                    controller: 'OutletMapController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('outlet');
                    return $translate.refresh();
                }]
            }
        })
        .state('outlet-vdo-import',{
            parent: 'entity',
            url: '/outlet-vdo-import',
            data: {
                authorities: ['ROLE_ADMIN', 'VDR'],
                pageTitle: 'sceaApp.outlet.import.vdoImport.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/outlet/outlet-vdo-import.html',
                    controller: 'OutletVdoImportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate','$translatePartialLoader', function($translate, $translatePartialLoader){
                    $translatePartialLoader.addPart('outlet');
                    return $translate.refresh();
                }],
                previousState: ['$state', function($state){
                    var currentStateData = {
                        name: $state.current.name || 'outlet',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('outlet-import',{
                    parent: 'entity',
                    url: '/outlet-import',
                    data: {
                        authorities: ['ROLE_ADMIN', 'VDR'],
                        pageTitle: 'sceaApp.outlet.import.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/outlet/outlet-import.html',
                            controller: 'OutletImportController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate','$translatePartialLoader', function($translate, $translatePartialLoader){
                            $translatePartialLoader.addPart('outlet');
                            return $translate.refresh();
                        }],
                        previousState: ['$state', function($state){
                            var currentStateData = {
                                name: $state.current.name || 'outlet',
                                params: $state.params,
                                url: $state.href($state.current.name, $state.params)
                            };
                            return currentStateData;
                        }]
                    }
                })
        .state('outlet-grade-import',{
                    parent: 'entity',
                    url: '/outlet-grade-import',
                    data: {
                        authorities: ['ROLE_ADMIN', 'VDR'],
                        pageTitle: 'sceaApp.outlet.import.gradeImport.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/outlet/outlet-grade-import.html',
                            controller: 'OutletGradeImportController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate','$translatePartialLoader', function($translate, $translatePartialLoader){
                            $translatePartialLoader.addPart('outlet');
                            return $translate.refresh();
                        }],
                        previousState: ['$state', function($state){
                            var currentStateData = {
                                name: $state.current.name || 'outlet',
                                params: $state.params,
                                url: $state.href($state.current.name, $state.params)
                            };
                            return currentStateData;
                        }]
                    }
                })
        .state('outlet-pop-pair-version-import',{
                    parent: 'entity',
                    url: '/outlet-pop-pair-version-import',
                    data: {
                        authorities: ['ROLE_ADMIN', 'VDR'],
                        pageTitle: 'sceaApp.outlet.import.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/outlet/outlet-pop-pair-version-import.html',
                            controller: 'OutletPopPairVersionImportController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate','$translatePartialLoader', function($translate, $translatePartialLoader){
                            $translatePartialLoader.addPart('outlet');
                            return $translate.refresh();
                        }],
                        previousState: ['$state', function($state){
                            var currentStateData = {
                                name: $state.current.name || 'outlet',
                                params: $state.params,
                                url: $state.href($state.current.name, $state.params)
                            };
                            return currentStateData;
                        }]
                    }
                });
    }

})();