(function() {
    'use strict';

    angular
        .module('sceaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('achievement-data-adjustment', {
            parent: 'system-administration',
            url: '/system-administration/achievement-data-adjustment',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'sceaApp.achievement.home.title'
            },
            views: {
                'content@': {
                	 templateUrl: 'app/system-administration/achievement/achievements.html',
                     controller: 'AchievementController',
                     controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('achievement');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('dashboard');
                    $translatePartialLoader.addPart('dashboardParams');
                    $translatePartialLoader.addPart('storeType');
                    $translatePartialLoader.addPart('callPlanType');
                    $translatePartialLoader.addPart('calendar');
                    $translatePartialLoader.addPart('adjustedAccumulation');
                    return $translate.refresh();
                }]
            }
        })
    }

})();
