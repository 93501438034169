(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('ApplicationSettingController', ApplicationSettingController);

    ApplicationSettingController.$inject = ['$scope', '$state', '$filter', 'ApplicationSetting'];

    function ApplicationSettingController ($scope, $state, $filter, ApplicationSetting) {
        var vm = this;

        vm.checkValidDate = checkValidDate;
        vm.updateOutletRadius = updateOutletRadius;
    	vm.updatePcFormSetting = updatePcFormSetting;
    	vm.updateBirtViewerAddress = updateBirtViewerAddress;
    	vm.updateActiveRetailMappingPeriodFrom = updateActiveRetailMappingPeriodFrom;
    	vm.updateActiveRetailMappingPeriodTo = updateActiveRetailMappingPeriodTo;

    	initPcFormSetting();
    	initOutletRadius();
    	initBirtViewerAddress();
    	initActiveRetailMappingPeriod();

    	function initPcFormSetting() {
    		ApplicationSetting.queryByKey({
    			key: 'PC_INPUT_FORM'
    		}, function(data) {
    			vm.pcFormSetting = data;
    			var result = data.value.split(",");

    			angular.forEach(result, function(value) {
    				if(value == 'POP') {
    			    	vm.pop = true;
    				}
    				if(value == 'FIXTURE') {
    					vm.fixture = true;
    				}
    				if(value == 'EQUIPMENT') {
    					vm.equipment = true;
    				}
    				if(value == 'INTERNET') {
    					vm.internet = true;
    				}
    				if(value == 'PHOTO') {
    					vm.photo = true;
    				}
    			});
    		});
    	}

    	function updatePcFormSetting() {
    		var values = '';

    		if(vm.pop) values += 'POP,';
    		if(vm.fixture) values += 'FIXTURE,';
    		if(vm.equipment) values += 'EQUIPMENT,';
    		if(vm.internet) values += 'INTERNET,';
    		if(vm.photo) values += 'PHOTO,';

    		values = values.substring(0, values.length-1);
    		vm.pcFormSetting.value = values;

    		if(values.length > 0) {
        		ApplicationSetting.update(vm.pcFormSetting);
    		} else {
    			initPcFormSetting();
    		}
    	}

    	function initOutletRadius() {
    		ApplicationSetting.queryByKey({
    			key: 'VISIT_VAL_BY_RAD_INMETER'
    		}, function(data) {
    			vm.outletRadius = data;
    			vm.radius = parseFloat(data.value);
    		});
    	}

    	function updateOutletRadius() {
    		vm.outletRadius.value = vm.radius.toString();

    		ApplicationSetting.update(vm.outletRadius);
    	}

    	function initActiveRetailMappingPeriod() {
    		ApplicationSetting.queryByKey({
    			key: 'ACTIVE_RETAIL_MAPPING_FROM'
    		}, function(data) {
    			vm.activeRetailMappingFrom = data;
    			vm.rmFrom = moment(data.value, 'DD/MM/YYYY').toDate();
    		});
            ApplicationSetting.queryByKey({
                 key: 'ACTIVE_RETAIL_MAPPING_TO'
            }, function(data) {
                 vm.activeRetailMappingTo = data;
                 vm.rmTo = moment(data.value, 'DD/MM/YYYY').toDate();
            });
    	}

        function updateActiveRetailMappingPeriodFrom() {
     		vm.activeRetailMappingFrom.value = moment(vm.rmFrom).format('DD/MM/YYYY');
     		ApplicationSetting.update(vm.activeRetailMappingFrom);
     	}

        function updateActiveRetailMappingPeriodTo() {
            vm.activeRetailMappingTo.value = moment(vm.rmTo).format('DD/MM/YYYY');
            ApplicationSetting.update(vm.activeRetailMappingTo);
        }

    	function initBirtViewerAddress() {
    		vm.invalidDate = false;
    		
    		ApplicationSetting.queryByKey({
    			key: 'BIRT_VIEWER_ADDRESS'
    		}, function(data) {
    			vm.birtViewerAddress = data;
    		}, function() {
    			vm.birtViewerAddress = {
    				id: null,
    				key: 'BIRT_VIEWER_ADDRESS',
    				value: null
    			}
    		});
    	}

		vm.datePickerOpenStatus = {};
		vm.datePickerOpenStatus.fromDate = false;
		vm.datePickerOpenStatus.toDate = false;

		vm.openCalendar = function(date) {
			vm.datePickerOpenStatus[date] = true;
		};
        
        function checkValidDate(datePicker) {
        	if(vm.rmFrom != null && vm.rmTo != null) {
            	var isValid = moment(vm.rmTo).isAfter(vm.rmFrom);
            	if(isValid) {
            		vm.invalidDate = false;
            		
            		if(datePicker == 'from') {
            			updateActiveRetailMappingPeriodFrom();
            		}
            		if(datePicker == 'to') {
            			updateActiveRetailMappingPeriodTo();
            		}
            	} else {
            		vm.invalidDate = true;
            	}        		
        	}
        }

    	function updateBirtViewerAddress() {
    		if(vm.birtViewerAddress.id !== null) {
    			ApplicationSetting.update(vm.birtViewerAddress);
    		} else {
    			ApplicationSetting.save(vm.birtViewerAddress);
    		}
    	}
    }
})();
