(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('EquipmentDialogController', EquipmentDialogController);

    EquipmentDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Equipment', 'EquipmentSearch'];

    function EquipmentDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Equipment, EquipmentSearch) {
        var vm = this;
        vm.equipment = entity;
        vm.getParent = getParent;
        vm.loadingParent = false;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });
        
        vm.onSelectParent = function(item, model, label) {
            vm.equipment.parentId = model.id;
            vm.equipment.parentName = model.equipmentName;
        }
        
        var onSaveSuccess = function (result) {
            $scope.$emit('sceaApp:equipmentUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            
            if (vm.equipment.id !== null) {
                Equipment.update(vm.equipment, onSaveSuccess, onSaveError);
            } else {
                Equipment.save(vm.equipment, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        
        function getParent(value) {
			vm.loadingParent = true;
			return EquipmentSearch.query({
				query : '*' + value + '*'
			}).$promise.then(function(response) {
				vm.loadingParent = false;
				return response;
			}, function(response) {
				vm.loadingParent = false;
				AlertService.error(response.data.message);
			});
		}
    }
})();
