(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('VdeOutletDialogController', VdeOutletDialogController);

    VdeOutletDialogController.$inject = ['$scope', '$state', '$stateParams', '$uibModalInstance', '$uibModal', 'outlets', 'Calendar', 'Outlet', 'TicketOrder'];

    function VdeOutletDialogController ($scope, $state, $stateParams, $uibModalInstance, $uibModal, outlets, Calendar, Outlet, TicketOrder) {
        var vm = this;

        vm.outlets = outlets;
    	vm.reverse = true;
    	vm.predicate = 'outletCode';

        vm.addOutlet = addOutlet;
    	vm.clear = clear;
        vm.deleteOutlet = deleteOutlet;

        function addOutlet() {
            var modalInstance = $uibModal.open({
                templateUrl:
                    "app/merchandiser-activity/call-plan-creation/vde-outlet-add-dialog.html",
                controller: "VdeOutletAddDialogController",
                controllerAs: "vm",
                backdrop: "static",
                size: "md",
                resolve: {
                    outlets: [
                        "Outlet",
                        function (Outlet) {
                            return Outlet.queryByVdo({
                                id: $stateParams.vdeId
                            }).$promise.then(function (outlets) {
                                if (outlets && outlets.length) {
                                    return outlets.map(function (o) {
                                        return Object.assign(o, {
                                            searchField: {
                                                name: o.outletName,
                                                code: o.outletCode
                                            },
                                        });
                                    });
                                } else {
                                    return [];
                                }
                            });
                        }
                    ]
                }
            });

            modalInstance.result.then(function(result) {}, function() {
            	vm.outlets = TicketOrder.queryOutlets({
            		employeeId: $stateParams.vdeId,
            		calendarId : $stateParams.calendarId
            	});
            });
        }

        function deleteOutlet(outlet) {
        	TicketOrder.delete({id: outlet.ticketOrderId}, function(data) {
        		TicketOrder.queryOutlets({
        			employeeId: $stateParams.vdeId,
        			calendarId : $stateParams.calendarId
        		}, function(data) {
        	        vm.outlets = data;
        		});
        	});
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
