(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('VdoCallplanDetailController', VdoCallplanDetailController);

        VdoCallplanDetailController.$inject = ['$scope', '$state', 'filterParams', 'Principal', 'User', 'Calendar', 'Dashboard', '$uibModal', 'CalendarSearch'];

    function VdoCallplanDetailController ($scope, $state, filterParams, Principal, User, Calendar, Dashboard, $uibModal, CalendarSearch) {
        var vm = this;

        vm.back = back;
        vm.updateParams = updateParams;
        vm.updateWeeks = updateWeeks;
        vm.openOutletDialog = openOutletDialog;

		function init() {
            getAccount();

            vm.predicate = '-achievement';
            vm.calendar = {
                year: filterParams.year,
                month: filterParams.month,
                week: filterParams.week
            }

            vm.years = Calendar.queryYears();
            vm.months = Calendar.queryMonths();
            vm.weeks = Calendar.queryWeeks({
                year: vm.calendar.year,
                month: vm.calendar.month
            });
		}
        init();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.user = User.get({login: account.login});

                if(vm.user.$promise) {
                    vm.user.$promise.then(function(data) {
                        getVdoCallplan(data, vm.calendar);
                    });
                }
            });
        }

        function updateWeeks(year, month) {
            vm.weeks = Calendar.queryWeeks({
                year: year,
                month: month
            }, function(data) {
                vm.calendar.week = String(data[0]);

                updateParams(vm.calendar);
            });
        }

        function updateParams(calendar) {
            $state.go('vdo-callplan-detail', {
                week: calendar.week,
                month: calendar.month,
                year: calendar.year,
                showChart: filterParams.showChart,
                vdr: filterParams.vdr,
                vdoCoo: filterParams.vdoCoo,
                vdo: filterParams.vdo
            });
        }

		function getVdoCallplan(user, calendar) {
            if(calendar.year && calendar.week) {
                Dashboard.getAsmVdeCallplan({
                    userId: user.id,
                    year: calendar.year,
                    week: calendar.week
                }, function(data) {
                    var sumActual = 0;
                    var sumTarget = 0;
                    var sumAchievement = 0;

                    angular.forEach(data, function(eachData, key) {
                        (eachData.roleName == 'VDE') ? eachData.roleName = 'VDO' : null;

                        eachData.achievement = eachData.achievements.actual / eachData.achievements.target * 100;
                        if(isNaN(eachData.achievement)) {
                            eachData.achievement = 0;
                        }

                        sumActual += eachData.achievements.actual;
                        sumTarget += eachData.achievements.target;
                        sumAchievement += eachData.achievement;
                    });

                    data.sumActual = sumActual;
                    data.sumTarget = sumTarget;
                    data.sumAchievement = data.sumActual/data.sumTarget * 100;
                    // data.sumAchievement = sumAchievement / data.length;

                    if(isNaN(data.sumAchievementTotal)) {
                        data.sumAchievementTotal = 0;
                    }

                    vm.vdoSummaries = data;
                });
            }
        }

        function back(calendar) {
            $state.go('home', {
                week: calendar.week,
                month: calendar.month,
                year: calendar.year,
                showChart: filterParams.showChart,
                vdr: filterParams.vdr,
                vdoCoo: filterParams.vdoCoo,
                vdo: filterParams.vdo
            });
        }

        function openOutletDialog(employeeId) {
            var outletDialog = $uibModal.open({
                templateUrl:
                    "app/home/dashboard-asm/modal/call-plan-outlet-dialog.html",
                controller: "CallPlanOutletDialogController",
                controllerAs: "vm",
                backdrop: "static",
                size: "md",
                resolve: {
                    calendar: function () {
                        var query = "week:" + vm.calendar.week + " AND " + "month:" + vm.calendar.month + " AND " + "year:" + vm.calendar.year;
                        return CalendarSearch.query({
                            query: query,
                            sort: "_score,desc",
                            size: 1,
                        }).$promise.then(
                            function (calendars) {
                                return calendars[0];
                            },
                            function () {
                                return null;
                            }
                        );
                    },
                    employeeId: function () {
                        return employeeId;
                    }
                },
            });
        }
    }
})();
