(function(){
    'use strict';
    angular
        .module('sceaApp')
        .factory('UserGroup', UserGroup);

    UserGroup.$inject = ['$resource'];

    function UserGroup ($resource) {
        var resourceUrl = 'api/groups/:id';

        return $resource ( resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });
    }
})();
