(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('PromotionDtlDialogController', PromotionDtlDialogController);

    PromotionDtlDialogController.$inject = ['$location', '$timeout', '$scope', '$stateParams', '$uibModalInstance', 'AlertService', 'promotion', 'entity', 'PromotionDtl', 'Outlet', 'OutletSearch', 'PromoMaterial', 'PromoMaterialSearch'];

    function PromotionDtlDialogController ($location, $timeout, $scope, $stateParams, $uibModalInstance, AlertService, promotion, entity, PromotionDtl, Outlet, OutletSearch, PromoMaterial, PromoMaterialSearch) {
        var vm = this;

        vm.clear = clear;
        vm.getPromoMaterial = getPromoMaterial;
        vm.getOutlet = getOutlet;

        (function() {
            vm.promotion = promotion;
            vm.promotionDtl = entity;
            
            if(vm.promotion.$promise){
                vm.promotion.$promise.then(function(data) {
                        if(checkExpiredPromotion(data.validUntil)){
                            vm.clear();
                        } else {
                    }
                });
            }

            if(vm.promotionDtl.$promise) {
                vm.promotionDtl.$promise.then(function(data) {
                    if(checkExpiredPromotion(vm.promotion.validUntil)){
                        vm.clear();
                    }

                    if(data.promotionId != null) {
                        PromoMaterial.get({id: data.promoMaterialId}, function(promoMaterial) {
                            vm.promoMaterial = promoMaterial;
                        });
                    }
                    
                    if(data.outletId != null) {
                        Outlet.get({id: data.outletId}, function(outlet) {
                            vm.outlet = outlet;
                        });
                    }
                });
            }
            
        })();

        function checkExpiredPromotion(params) {
            var checkExpired = moment(params).isBefore(moment());
            return (checkExpired) ? true : false;
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sceaApp:promotionDtlUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;

            if(vm.promoMaterial) {
            	vm.promotionDtl.promoMaterialId = vm.promoMaterial.id;
                vm.promotionDtl.promoMaterialName = vm.promoMaterial.name;
            }

            if(vm.outlet) {
            	vm.promotionDtl.outletId = vm.outlet.id;
            	vm.promotionDtl.outletOutletName = vm.outlet.outletName;
            }

            if (vm.promotionDtl.id !== null) {
                PromotionDtl.update(vm.promotionDtl, onSaveSuccess, onSaveError);
            } else {
                PromotionDtl.save(vm.promotionDtl, onSaveSuccess, onSaveError);
            }
        };
        
        function clear() {
            $uibModalInstance.dismiss('cancel');
        };

        function getPromoMaterial(value) {
        	vm.loadingPromoMaterial = true;
            return PromoMaterialSearch.query({
                query: '*'+value+'*'
            }).$promise.then(function(response){
            	vm.loadingPromoMaterial = false;
                return response;
            }, function(response){
            	vm.loadingPromoMaterial = false;
                AlertService.error(response.data.message);
            });
        }
        
        function getOutlet(value) {
        	vm.loadingOutlet = true;
            return OutletSearch.query({
                query: '*'+value+'*'
            }).$promise.then(function(response){
            	vm.loadingOutlet = false;
                return response;
            }, function(response){
            	vm.loadingOutlet = false;
                AlertService.error(response.data.message);
            });
        }
    }
})();
