(function() {
    'use strict';

    angular
        .module('sceaApp')
        .factory('VDETaskPlanningSearch', VDETaskPlanningSearch);

    VDETaskPlanningSearch.$inject = ['$resource'];

    function VDETaskPlanningSearch($resource) {
        var resourceUrl =  'api/_search/v-de-task-plannings/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
