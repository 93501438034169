(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('InternetConnectionDeleteController',InternetConnectionDeleteController);

    InternetConnectionDeleteController.$inject = ['$uibModalInstance', 'entity', 'InternetConnection'];

    function InternetConnectionDeleteController($uibModalInstance, entity, InternetConnection) {
        var vm = this;
        vm.internetConnection = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            InternetConnection.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
