(function() {
    'use strict';

    angular
        .module('sceaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('application-setting', {
            parent: 'admin',
            url: '/application-setting',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'sceaApp.applicationSetting.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/application-setting/application-settings.html',
                    controller: 'ApplicationSettingController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('applicationSetting');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        });
    }

})();
