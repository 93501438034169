(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('ReportPopDashboardController', ReportPopDashboardController);

    ReportPopDashboardController.$inject = ['$uibModal', 'Dashboard', 'OutletAccount', 'OutletType', 'VisitCycle', 'Area', 'ProductCategory'];

    function ReportPopDashboardController ($uibModal, Dashboard, OutletAccount, OutletType, VisitCycle, Area, ProductCategory) {
        var vm = this;

        vm.getBranchs = getBranchs;
        vm.changeResultGraph = changeResultGraph;
        vm.detailDashboardDialog = detailDashboardDialog;
        
        vm.branch = {
            name : 'ALL',
            type : 'Region',
            compliance : 0,
        }
        vm.outlets = []
        vm.area = {}
        vm.selectedRegion = null;
        vm.selectedBranch = null;
        vm.selectedAccount = null;
        vm.selectedStoryType = null;
        vm.selectedGrade = null;
        vm.selectedDIV = null;
        vm.onlyRISOutlet = true;

        vm.accounts = OutletAccount.query();
        vm.storyType = OutletType.query();
        vm.grades = VisitCycle.query();
        vm.div = ProductCategory.getAllProductCategories({level: 1});

        
        onInit();


        function onInit() {
            vm.area = {
                regions: Area.queryByLevelId({areaLevelId : 1},
                    function(result) {
                        if(vm.area.region != null) {
                            vm.getBranchs(vm.area.region.id);
                        }
                }),
                branchs: [],

            }

            vm.dashboardInit = Dashboard.popDashboard({
                regionId: 0,
                accountId: 0,
                outletTypeId: 0,
                divId: 0,
                risStatus: true,
                gradeId: 0,
                branchId : 0
            }, _onSuccess, _onError)

        }
        
        function getBranchs(regionId) {
			vm.selectedBranch = null;
			vm.selectedCity = null;
			if(regionId) {
			    vm.area.branchs = Area.queryByParentIdAndLevelId({parentId: regionId, areaLevelId : 2});
			}
        }

        function changeResultGraph() {
            vm.dashboardInit.$cancelRequest();
            if (vm.newDashboard) {
                vm.newDashboard.$cancelRequest();
            }

            vm.newDashboard = Dashboard.popDashboard({
                regionId: vm.selectedRegion ? vm.selectedRegion.id : 0,
                accountId: vm.selectedAccount ? vm.selectedAccount.id : 0,
                outletTypeId: vm.selectedStoryType ? vm.selectedStoryType.id : 0,
                divId: vm.selectedDIV ? vm.selectedDIV.id : 0,
                risStatus: vm.onlyRISOutlet,
                gradeId: vm.selectedGrade ? vm.selectedGrade.id : 0,
                branchId : vm.selectedBranch ? vm.selectedBranch.id : 0

            }, _onSuccess, _onError)

        }

        function detailDashboardDialog(outlet) {
            var id = outlet.id,
                div = vm.selectedDIV ? vm.selectedDIV.id : 0

            $uibModal.open({
                templateUrl: 'app/report/report-pop/dashboard/report-pop-dashboard-detail.html',
                controller: 'ReportPopDashboardDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    outlet : outlet,
                    entity: ['Dashboard', function(Dashboard) {
                        return Dashboard.popDashboardDetail({
                            id : id,
                            divId : div
                        }).$promise;
                    }],
                }
            }).result.then(function(data) {

            }, function(error) {

            });
        }

        function _onSuccess(data) {
            vm.branch = {
                name : vm.selectedBranch ? vm.selectedBranch.areaName : vm.selectedRegion ? vm.selectedRegion.areaName : 'ALL',
                type : vm.selectedBranch ? "Branch" : "Region",
                compliance : data.areaCompliance.compliance,
            }
            vm.outlets = data.outletCompliance.map(function (outlet) {
                return {
                    id: outlet.id,
                    name: outlet.name,
                    bar :  outlet.compliance,
                }
            });

        }

        function _onError(error) {
        }

    }
})();
