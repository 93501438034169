(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('OtherJobStoreCompletenessDeleteController',OtherJobStoreCompletenessDeleteController);

    OtherJobStoreCompletenessDeleteController.$inject = ['$uibModalInstance', 'entity', 'OtherJobStoreCompleteness'];

    function OtherJobStoreCompletenessDeleteController($uibModalInstance, entity, OtherJobStoreCompleteness) {
        var vm = this;
        vm.otherJobStoreCompleteness = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            OtherJobStoreCompleteness.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
