(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('VisitAuditDetailController', VisitAuditDetailController);

    VisitAuditDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'VisitAudit', 'User', 'TPop', 'Calendar', 'TTicketOrder'];

    function VisitAuditDetailController($scope, $rootScope, $stateParams, entity, VisitAudit, User, TPop, Calendar, TTicketOrder) {
        var vm = this;
        vm.visitAudit = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:visitAuditUpdate', function(event, result) {
            vm.visitAudit = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
