(function () {
    'use strict';
    angular
        .module('sceaApp')
        .factory('Fixtures', Fixtures);

    Fixtures.$inject = ['$resource'];

    function Fixtures($resource) {
        var resourceUrl = 'api/fixtures/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method: 'PUT' },
            'exportAsCsv': {
                url: 'api/planograms/fixture/csv',
                method: 'GET',
                transformResponse: function (data, headersGetter) {
                    var filename = "fixtures.csv";
                    var blob = new Blob([data], { type: "text/csv;charset=UTF-8" });
                    saveAs(blob, filename, true);
                    return true;
                }
            }
        });
    }
})();
