(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('StoreCompletenessDialogController', StoreCompletenessDialogController);

    StoreCompletenessDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'StoreCompleteness', 'StoreCompletenessSearch'];

    function StoreCompletenessDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, StoreCompleteness, StoreCompletenessSearch) {
        var vm = this;
        vm.storeCompleteness = entity;
        vm.getParent = getParent;
        vm.loadingParent = false;
        //vm.storecompletenesses = StoreCompleteness.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });
        
        vm.onSelectParent = function(item, model, label) {
            vm.storeCompleteness.parentId = model.id;
            vm.storeCompleteness.parentDescription = model.description;
        }

        var onSaveSuccess = function (result) {
            $scope.$emit('sceaApp:storeCompletenessUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.storeCompleteness.id !== null) {
                StoreCompleteness.update(vm.storeCompleteness, onSaveSuccess, onSaveError);
            } else {
                StoreCompleteness.save(vm.storeCompleteness, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        
        function getParent(value) {
			vm.loadingParent = true;
			return StoreCompletenessSearch.query({
				query : '*' + value + '*'
			}).$promise.then(function(response) {
				vm.loadingParent = false;
				return response;
			}, function(response) {
				vm.loadingParent = false;
				AlertService.error(response.data.message);
			});
		}
    }
})();
