(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('DashboardDetailController', DashboardDetailController);

    DashboardDetailController.$inject = ['$scope', '$rootScope', 'entity'];

    function DashboardDetailController($scope, $rootScope, entity) {
        var vm = this;
        vm.dashboardSetting = entity;

        var unsubscribe = $rootScope.$on('sceaApp:dashboardSettingUpdate', function(event, result) {
            vm.dashboardSetting = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
