(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('SelloutDialogController', SelloutDialogController);

    SelloutDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Sellout', 'Outlet', 'ProductCategory', 'OutletSearch'];

    function SelloutDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Sellout, Outlet, ProductCategory, OutletSearch) {
        var vm = this;
        vm.sellout = entity;
        vm.outlets = Outlet.query();
        vm.loadingOutlet = false;
        vm.getOutlet = getOutlet;
        vm.productcategories = ProductCategory.getAllProductCategories({level:2});
        vm.outlet = null;
        
        if (vm.sellout.$promise) {
        	vm.sellout.$promise.then(function(data) {
        		vm.outlet = {
					"id" : data.outletId,
					"outletName" : data.outletOutletName
				}
        		if(vm.outlet == null){
        			vm.outlet = null;
	       		}
			}, function(error) {
				console.log(error);
			});
		}
        
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sceaApp:selloutUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if(vm.outlet){
            	vm.sellout.outletId = vm.outlet.id;
            }else{
            	vm.sellout.outletId = null;
            }	
            if (vm.sellout.id !== null) {
                Sellout.update(vm.sellout, onSaveSuccess, onSaveError);
            } else {
                Sellout.save(vm.sellout, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        
        function getOutlet(value) {
        	vm.loadingOutlet = true;
            return OutletSearch.query({
            	query: '*'+value+'*'
            }).$promise.then(function(response){
            	vm.loadingOutlet = false;
                return response;
            }, function(response){
            	vm.loadingOutlet = false;
                AlertService.error(response.data.message);
            });
        };
    }
})();
