(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('TicketOrderDialogController', TicketOrderDialogController);

    TicketOrderDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'TicketOrder', 'User', 'Outlet', 'TicketResult', 'Calendar'];

    function TicketOrderDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, TicketOrder, User, Outlet, TicketResult, Calendar) {
        var vm = this;
        vm.ticketOrder = entity;
        vm.employees = User.query();
        vm.outlets = Outlet.query();
        vm.ticketresults = TicketResult.query();
        vm.calendars = Calendar.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sceaApp:ticketOrderUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.ticketOrder.id !== null) {
                TicketOrder.update(vm.ticketOrder, onSaveSuccess, onSaveError);
            } else {
                TicketOrder.save(vm.ticketOrder, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.targetDate = false;
        vm.datePickerOpenStatus.expiredDate = false;
        vm.datePickerOpenStatus.periodStart = false;
        vm.datePickerOpenStatus.periodEnd = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
