(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('InternetDetailController', InternetDetailController);

    InternetDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Internet', 'InternetConnection'];

    function InternetDetailController($scope, $rootScope, $stateParams, entity, Internet, InternetConnection) {
        var vm = this;
        vm.internet = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:internetUpdate', function(event, result) {
            vm.internet = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
