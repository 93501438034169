(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('PlanogramProductDetailController', PlanogramProductDetailController);

    PlanogramProductDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PlanogramProduct', 'Planogram', 'Fixtures', 'Product'];

    function PlanogramProductDetailController($scope, $rootScope, $stateParams, entity, PlanogramProduct, Planogram, Fixtures, Product) {
        var vm = this;
        vm.planogramProduct = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:planogramProductUpdate', function(event, result) {
            vm.planogramProduct = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
