(function() {
	'use strict';

	angular
		.module('sceaApp').controller('IncentiveParamsDialogController', IncentiveParamsDialogController);

		IncentiveParamsDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'DashboardParams', 'Calendar'];

	function IncentiveParamsDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, DashboardParams, Calendar) {
		var vm = this;
		vm.dashboardParams = entity;
		
		vm.options = {
			years: [],
			months: [],
			weeks: [],
			dataType: { type: null },
			callPlan: { status: null, max: null },
			pop: { status: null, max: null },
			equipment: { status: null, max: null },
			fixture: { status: null, max: null },
			internet: { status: null, max: null },
			totalValue: null,
			adjustedData: { status: null }
		}		

		vm.init = function() {
			getYears();
			getMonths();
			getWeeks(vm.dashboardParams.year);

			if(vm.dashboardParams.month == null
					|| vm.dashboardParams.month == 0) {
				vm.options.dataType.type = 'week';
			} else {
				vm.options.dataType.type = 'month';
			}
			if(vm.dashboardParams.month != null) {
				vm.dashboardParams.month = vm.dashboardParams.month.toString();
			}
			if(vm.dashboardParams.callPlan != 0) {
				vm.options.callPlan.status = true;
			}
			vm.options.callPlan.max = vm.dashboardParams.callPlan;
			if(vm.dashboardParams.pop != 0) {
				vm.options.pop.status = true;
			}
			vm.options.pop.max = vm.dashboardParams.pop;
			if(vm.dashboardParams.equipment != 0) {
				vm.options.equipment.status = true;
			}
			vm.options.equipment.max = vm.dashboardParams.equipment;
			if(vm.dashboardParams.fixture != 0) {
				vm.options.fixture.status = true;
			}
			vm.options.fixture.max = vm.dashboardParams.fixture;
			if(vm.dashboardParams.internet != 0) {
				vm.options.internet.status = true;
			}
			vm.options.internet.max = vm.dashboardParams.internet;
			vm.options.totalValue = 100;
			vm.options.adjustedData.status = true;
		}
		
		vm.changeDataType = function() {
        	if(vm.options.dataType.type == 'month'){
	    		angular.element('.form-group:eq(1) > div > select').focus();
	    	} else if(vm.options.dataType.type == 'week'){
	    		angular.element('.form-group:eq(2) > div > select').focus();
	        }
		}
		
		vm.changePortion = function() {
			validationInputValue();
			vm.options.totalValue = setTotalValue();
			setMaxValue(vm.options.totalValue);
		}

		vm.setFocus = function(value, key) {
			if(value == true) {
				angular.element('.form-group:eq(' + key + ') > div > div > input').focus();
			}
		}

        vm.save = function () {
			vm.isSaving = true;

			vm.dashboardParams.month = parseInt(vm.dashboardParams.month);
			if(vm.options.dataType.type == 'month') {
				vm.dashboardParams.week = null;
			} else if(vm.options.dataType.type == 'week') {
				vm.dashboardParams.month = null;
			}

			DashboardParams.update(vm.dashboardParams, onSaveSuccess, onSaveError);
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

		function getYears() {
			Calendar.queryYears(function(result) {		
				vm.options.years = result;
			});
		}
		
		function getMonths() {
			Calendar.queryMonths(function(result) {		
				vm.options.months = result;
				if(vm.dashboardParams.month == null) {
					vm.dashboardParams.month = vm.options.months[0].toString();
				}
			});
		}
		
		function getWeeks(year) {
			Calendar.queryWeeks({year: year}, function(result) {
				vm.options.weeks = result;
				if(vm.dashboardParams.week == null) {
					vm.dashboardParams.week = vm.options.weeks[0];
				}
			});
		}
		
		function validationInputValue() {
			if(vm.dashboardParams.callPlan == null
				|| vm.dashboardParams.callPlan > vm.options.callPlan.max) {
				vm.dashboardParams.callPlan = vm.options.callPlan.max;
			}
			if(vm.options.callPlan.status == false) {
				vm.dashboardParams.callPlan = 0;
			}
			
			if(vm.dashboardParams.pop < 0 || vm.dashboardParams.pop == null
					|| vm.dashboardParams.pop > vm.options.pop.max) {
				vm.dashboardParams.pop = vm.options.pop.max;
			}
			if(vm.options.pop.status == false) {
				vm.dashboardParams.pop = 0;
			}
			if(vm.dashboardParams.equipment < 0
					|| vm.dashboardParams.equipment == null
					|| vm.dashboardParams.equipment > vm.options.equipment.max) {
				vm.dashboardParams.equipment = vm.options.equipment.max;
			}
			if(vm.options.equipment.status == false) {
				vm.dashboardParams.equipment = 0;
			}
			if(vm.dashboardParams.fixture < 0
					|| vm.dashboardParams.fixture == null
					|| vm.dashboardParams.fixture > vm.options.fixture.max) {
				vm.dashboardParams.fixture = vm.options.fixture.max;
			}
			if(vm.options.fixture.status == false) {
				vm.dashboardParams.fixture = 0;
			}
			if(vm.dashboardParams.internet < 0
					|| vm.dashboardParams.internet == null
					|| vm.dashboardParams.internet > vm.options.internet.max) {
				vm.dashboardParams.internet = vm.options.internet.max;
			}
			if(vm.options.internet.status == false) {
				vm.dashboardParams.internet = 0;
			}
		}
		
		function setTotalValue() {
			var totalValue = 0;
			if(vm.options.callPlan.status	 == true) {
				totalValue = totalValue + vm.dashboardParams.callPlan;
			}
			if(vm.options.pop.status == true) {
				totalValue = totalValue + vm.dashboardParams.pop;
			}
			if(vm.options.equipment.status == true) {
				totalValue = totalValue+ vm.dashboardParams.equipment;
			}
			if(vm.options.fixture.status == true) {
				totalValue = totalValue + vm.dashboardParams.fixture;
			}
			if(vm.options.internet.status == true) {
				totalValue = totalValue + vm.dashboardParams.internet;
			}
			return totalValue;
		}
		
		function setMaxValue(totalValue) {
			var maxValue = 100;
			vm.options.callPlan.max = maxValue - vm.options.totalValue + vm.dashboardParams.callPlan;
			vm.options.pop.max = maxValue - vm.options.totalValue + vm.dashboardParams.pop;
			vm.options.equipment.max = maxValue - vm.options.totalValue + vm.dashboardParams.equipment;
			vm.options.fixture.max = maxValue - vm.options.totalValue + vm.dashboardParams.fixture;
			vm.options.internet.max = maxValue - vm.options.totalValue + vm.dashboardParams.internet;
		}

        $timeout(function (){
            angular.element('.form-group:eq(0) > div > select').focus();
        });
        
		function onSaveSuccess(result) {
            $scope.$emit('sceaApp:dashboardParamsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
		}
		
		function onSaveError(result) {
            vm.init();
            vm.isSaving = false;
		}
	}
})();
