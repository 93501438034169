(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('PopComplianceDetailController', PopComplianceDetailController);

    PopComplianceDetailController.$inject = ['$scope', '$state', 'filterParams', 'Principal', 'User', 'Calendar', 'Dashboard',
        'years', 'months', 'weeks','currentUser', 'vdrList', 'vdoList', 'vdeList'];

    function PopComplianceDetailController ($scope, $state, filterParams, Principal, User, Calendar, Dashboard,
        years, months, weeks, currentUser, vdrList, vdoList, vdeList) {
        var vm = this;

        vm.back = back;
        vm.updateWeeks = updateWeeks;
        vm.transition = transition;
        vm.onVdrChange = onVdrChange;
        vm.onVdoChange = onVdoChange;
        vm.onVdeChange = onVdeChange;

        vm.showChart = filterParams.showChart === 'true';
        vm.isAdmin = currentUser ? currentUser.authorities.includes('ROLE_ADMIN') : false;
        vm.isVdr = currentUser ? currentUser.authorities.includes('VDR') : false;
        vm.isVdo = currentUser ? currentUser.authorities.includes('VDO') : false;
        vm.isVde = currentUser ? currentUser.authorities.includes('VDE') : false;
        vm.showData = vm.showData = 'total';

        vm.vdr = vdrList;
        vm.vdo = vdoList;
        vm.vde = vdeList;
        vm.weeks = weeks;
        vm.months = months;
        vm.years = years;
        vm.currentUser = currentUser;
        vm.calendar = {
            year: filterParams.year,
            month: filterParams.month,
            week: filterParams.week,
        }
        vm.selectedRole = {
            vdr: filterParams.vdr ? vm.vdr.find(function(vdr){return vdr.id == filterParams.vdr}) : null,
            vdo: filterParams.vdoCoo ? vm.vdo.find(function(vdoCoo){return vdoCoo.id == filterParams.vdoCoo}) : null,
            vde: filterParams.vdo ? vm.vde.find(function(vdo){return vdo.id == filterParams.vdo}) : null,
        }
        vm.predicate = 'actualAv';
        vm.reverse = false;

        if (!filterParams.vdo && !filterParams.vdoCoo && !filterParams.vdo) {
            if (vm.isVde) {
                User.getInternal({ roleName: 'VDE', managerId: currentUser.managerId }, function (vde) {
                    vm.vde = vde
                    vm.selectedRole.vde = vm.vde.find(function(vde){return vde.id == currentUser.id})
                });

                User.getInternal({ roleName: 'VDO'}, function (vdo) {
                    vm.vdo = vdo
                    vm.selectedRole.vdo = vm.vdo.find(function(vdo){return vdo.id == currentUser.managerId});
                    vm.selectedRole.vdr = vm.vdr.find(function(vdr){return vdr.id == vm.selectedRole.vdo.managerId});
                });
            } else if (vm.isVdo) {
                vm.selectedRole.vdr = vm.vdr.find(function(vdr){return vdr.id == currentUser.managerId});

                User.getInternal({ roleName: 'VDO', managerId: currentUser.managerId }, function (vdo) {
                    vm.vdo = vdo
                    vm.selectedRole.vdo = vm.vdo.find(function(vdo){return vdo.id == currentUser.id})
                });

                User.getInternal({ roleName: 'VDE', managerId: currentUser.id }, function (vde) {
                    vm.vde = vde
                    vm.selectedRole.vdo = vm.vdo.find(function(vdo){return vdo.id == currentUser.id})
                });
            } else if (vm.isVdr) {
                vm.selectedRole.vdr = vm.vdr.find(function(vdr){return vdr.id == currentUser.id});
            }
        }

        loadAll();

		function loadAll() {
            var userId;

            if (filterParams.vdo){
                userId = filterParams.vdo
            } else if (filterParams.vdoCoo) {
                userId = filterParams.vdoCoo
            } else if (filterParams.vdr) {
                userId = filterParams.vdr
            } else {
                userId = vm.currentUser.id
            }

            if (!userId || !filterParams.week || !filterParams.year) {
                return;
            }

            Dashboard.getAsmPopCompliance({
                userId: userId,
                year: filterParams.year,
                week: filterParams.week
            }, function(data) {
                vm.popCompliance = data.map(function(eachData){
                    return {
                        employeeName: eachData.employeeName,
                        actualAv : eachData.achievements.actual_av,
                        targetAv : eachData.achievements.target_av,
                        achievementAv : (eachData.achievements.actual_av && eachData.achievements.target_av) ? eachData.achievements.actual_av / eachData.achievements.target_av * 100 : 0,
                        actualHa : eachData.achievements.actual_ha,
                        targetHa : eachData.achievements.target_ha,
                        achievementHa : (eachData.achievements.actual_ha && eachData.achievements.target_ha) ? eachData.achievements.actual_ha / eachData.achievements.target_ha * 100 : 0,
                        actualCe : eachData.achievements.actual_total,
                        targetCe : eachData.achievements.target_total,
                        achievementCe : (eachData.achievements.actual_total && eachData.achievements.target_total) ? eachData.achievements.actual_total / eachData.achievements.target_total * 100 : 0,
                    }
                });

                vm.total = {
                    actualAv: sumArrByProp(vm.popCompliance, 'actualAv'),
                    targetAv: sumArrByProp(vm.popCompliance, 'targetAv'),
                    achievementAv: avgArrAchievementByProp(vm.popCompliance, 'achievementAv'),
                    actualHa: sumArrByProp(vm.popCompliance, 'actualHa'),
                    targetHa: sumArrByProp(vm.popCompliance, 'targetHa'),
                    achievementHa: avgArrAchievementByProp(vm.popCompliance, 'achievementHa'),
                    actualCe: sumArrByProp(vm.popCompliance, 'actualCe'),
                    targetCe: sumArrByProp(vm.popCompliance, 'targetCe'),
                    achievementCe: avgArrAchievementByProp(vm.popCompliance, 'achievementCe'),
                }
            });

            function sumArrByProp(arr, prop) {
                var sum = arr.reduce(function(acc, current){
                    return acc += current[prop];
                }, 0);
                return sum;
            }

            function avgArrAchievementByProp(arr, prop) {
                var filter;

                if (prop === 'achievementAv') {
                    filter = 'targetAv';
                } else if(prop === 'achievementHa') {
                    filter = 'targetHa';
                } else if(prop === 'achievementCe') {
                    filter = 'targetCe';
                } else {
                    return;
                }

                var nonZeroTarget = arr.filter(function(each){
                    return each[filter];
                });

                var sum = nonZeroTarget.reduce(function(acc, current){
                    return acc += current[prop];
                }, 0);

                var avg = sum / nonZeroTarget.length;

                return isNaN(avg) ? 0 : avg;
            }
		}

        function updateWeeks() {
            vm.weeks = Calendar.queryWeeks({
                year: vm.calendar.year,
                month: vm.calendar.month
            }, function (weeks) {
                vm.calendar.week = weeks.length ? weeks[0].toString() : null;
                vm.transition();
            });
        }

        function back() {
            $state.go('home', {
                showChart: vm.showChart,
                week: vm.calendar.week,
                month: vm.calendar.month,
                year: vm.calendar.year,
                vdr: vm.selectedRole.vdr ? vm.selectedRole.vdr.id : null,
                vdoCoo: vm.selectedRole.vdo ? vm.selectedRole.vdo.id : null,
                vdo: vm.selectedRole.vde ? vm.selectedRole.vde.id : null
            });
        }

        // event change role
        function onVdrChange() {
            vm.selectedRole.vdo = null;
            vm.selectedRole.vde = null;
            vm.transition();
        }

        function onVdoChange() {
            vm.selectedRole.vde = null;
            vm.transition();
        }

        function onVdeChange() {
            vm.transition();
        }

        function transition() {
            $state.go('pop-compliance-detail', {
                showChart: vm.showChart,
                week: vm.calendar.week,
                month: vm.calendar.month,
                year: vm.calendar.year,
                vdr: vm.selectedRole.vdr ? vm.selectedRole.vdr.id : null,
                vdoCoo: vm.selectedRole.vdo ? vm.selectedRole.vdo.id : null,
                vdo: vm.selectedRole.vde ? vm.selectedRole.vde.id : null
            });
        }
    }
})();
