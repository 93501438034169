(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('RetailMappingSubCategoryDetailController', RetailMappingSubCategoryDetailController);

    RetailMappingSubCategoryDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'RetailMappingSubCategory', 'RetailMappingCategory'];

    function RetailMappingSubCategoryDetailController($scope, $rootScope, $stateParams, entity, RetailMappingSubCategory, RetailMappingCategory) {
        var vm = this;
        vm.retailMappingSubCategory = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:retailMappingSubCategoryUpdate', function(event, result) {
            vm.retailMappingSubCategory = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
