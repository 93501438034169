(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('VdoAuditRowDataController', VdoAuditRowDataController);

		VdoAuditRowDataController.$inject = ['$scope', '$state', '$sce', 'ApplicationSetting'];

    function VdoAuditRowDataController ($scope, $state, $sce, ApplicationSetting) {
        var vm = this;

    	function init() {
    		ApplicationSetting.queryByKey({
    			key: 'BIRT_VIEWER_ADDRESS'
    		}, function(data) {
    			var address = data.value;
    				address += '?__report=vdo-audit/VdoAuditRowData.rptdesign';

    			vm.birtViewerAddress = $sce.trustAsResourceUrl(address);
    		});
    	}
    	init();
    }
})();
