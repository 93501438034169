(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('TPopEquipmentDetailController', TPopEquipmentDetailController);

    TPopEquipmentDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'TPopEquipment', 'TPop', 'Equipment'];

    function TPopEquipmentDetailController($scope, $rootScope, $stateParams, entity, TPopEquipment, TPop, Equipment) {
        var vm = this;
        vm.tPopEquipment = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:tPopEquipmentUpdate', function(event, result) {
            vm.tPopEquipment = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
