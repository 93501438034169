(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('OutletAccountDetailController', OutletAccountDetailController);

    OutletAccountDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'OutletAccount'];

    function OutletAccountDetailController($scope, $rootScope, $stateParams, entity, OutletAccount) {
        var vm = this;
        vm.outletAccount = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:outletAccountUpdate', function(event, result) {
            vm.outletAccount = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
