(function() {
    'use strict';

    angular
        .module('sceaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('visit-audit', {
            parent: 'report',
            url: '/visit-audit?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'sceaApp.visitAudit.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/report/visit-audit/visit-audits.html',
                    controller: 'VisitAuditController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('visitAudit');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('visit-audit.detail', {
            parent: 'visit-audit',
            url: '/{id}/detail',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/report/visit-audit/visit-audit-dialog.html',
                    controller: 'VisitAuditDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['VisitAudit', function(VisitAudit) {
                            return VisitAudit.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('visit-audit', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
