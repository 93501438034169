(function() {
    'use strict';
    angular
        .module('sceaApp')
        .factory('Dashboard', Dashboard);

    Dashboard.$inject = ['$resource'];

    function Dashboard ($resource) {
        var resourceUrl =  'api/dashboard/';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'popDashboard': { 
            	method:'GET',
            	url: 'api/dashboard/pop-dashboard',
                cancellable: true
            },
            'popDashboardDetail': { 
            	method:'GET',
            	url: 'api/dashboard/pop-dashboard/:id',
                isArray: true
            },
            'exportExcel': { 
            	method:'GET',
            	url: 'api/dashboard/export-excel',
            	data: '',
                headers: {
                    "Content-Type": "application/json"
                },
                responseType: 'arraybuffer',
                transformResponse: function (data, headersGetter) {
                    var contentDisposition = headersGetter('Content-Disposition');
                    var filename = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition)[1];
                    var blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"});
                    saveAs(blob, filename, true);
                    return true;
                }
            },
            'getAsmSummaries': {
            	url: 'api/dashboard/total',
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getAsmVdeCallplan': {
            	url: 'api/dashboard/vde-callplan',
                method: 'GET',
                isArray: true
            },
            'getAsmPopCompliance': {
            	url: 'api/dashboard/pop-compliance',
                method: 'GET',
                isArray: true
            },
            'getAsmPopTracking': {
            	url: 'api/dashboard/pop-movement',
                method: 'GET',
                isArray: true
            },
            'getAsmDropPointStockLevel': {
            	url: 'api/dashboard/total?category=DPSTOCKLEVEL',
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getAsmChart': {
                url: 'api/dashboard/total-weekly',
                method: 'GET',
                isArray: true
            }
        });
    }
})();
