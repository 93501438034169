(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('TicketOrderDetailController', TicketOrderDetailController);

    TicketOrderDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'TicketOrder', 'Outlet', 'TicketResult', 'Calendar'];

    function TicketOrderDetailController($scope, $rootScope, $stateParams, entity, TicketOrder, Outlet, TicketResult, Calendar) {
        var vm = this;
        vm.ticketOrder = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:ticketOrderUpdate', function(event, result) {
            vm.ticketOrder = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
