(function() {
	'use strict';

	angular.module('sceaApp').controller('UserManagementDialogController',
			UserManagementDialogController);

	UserManagementDialogController.$inject = [ '$stateParams', '$uibModalInstance', 'entity', 'User', 'JhiLanguageService', 'UserSearch' ];

	function UserManagementDialogController($stateParams, $uibModalInstance, entity, User, JhiLanguageService, UserSearch) {
		var vm = this;

		vm.profiles = [ 'ROLE_ADMIN', 'ARCO', 'SPV', 'PC', 'VDR', 'VDO', 'VDE' ];
		vm.authorities = null;
		vm.clear = clear;
		vm.languages = null;
		vm.save = save;
		vm.user = entity;
		vm.getEmployee = getEmployee;

		vm.loadingEmployee = false;
        vm.managerOpt = null;
        vm.areaOpt = null;

        if (vm.user.$promise) {
        	 vm.user.$promise.then(function(data) {
        		 vm.user.authorities = data.authorities[0];

        		 vm.managerOpt = {
        			id : data.managerId,
        			firstName : data.managerName
        		 }

        		 if(vm.managerOpt.id == null){
        			 vm.managerOpt = null;
        		 }
			}, function(error) {
				console.log(error);
			});
		}

		JhiLanguageService.getAll().then(function(languages) {
			vm.languages = languages;
		});

		function clear() {
			$uibModalInstance.dismiss('cancel');
		}

		function onSaveSuccess(result) {
			vm.isSaving = false;
			$uibModalInstance.close(result);
		}

		function onSaveError() {
			vm.isSaving = false;
		}

		function save() {
			vm.isSaving = true;
			
			if(vm.user.authorities.length == 0){
			    vm.user.authorities = null;
			}else{
			    vm.user.authorities = [vm.user.authorities];
			}

            if(vm.managerOpt){
            	vm.user.managerId = vm.managerOpt.id;
            }else{
            	vm.user.managerId = null;
            }

			if (vm.user.id !== null) {
				User.update(vm.user, onSaveSuccess, onSaveError);
			} else {
				User.save(vm.user, onSaveSuccess, onSaveError);
			}
		}

		function getEmployee(value) {
			vm.loadingEmployee = true;
			return UserSearch.query({
				query : '*' + value + '*'
			}).$promise.then(function(response) {
				vm.loadingEmployee = false;
				return response;
			}, function(response) {
				vm.loadingEmployee = false;
				AlertService.error(response.data.message);
			});
		}
	}
})();
