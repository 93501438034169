(function() {
    'use strict';
    angular
        .module('sceaApp')
        .factory('Area', Area);

    Area.$inject = ['$resource'];

    function Area ($resource) {
        var resourceUrl =  'api/areas/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'queryByLevelId':{
            	url:'api/areas/area-levels/:areaLevelId',
            	method:'GET',
            	isArray:true},
            'queryByParentIdAndLevelId':{
                url:'api/areas/parent/:parentId/area-levels/:areaLevelId',
                method:'GET',
                isArray:true}
        });
    }
})();
