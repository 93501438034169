(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('StoreCompletenessDetailController', StoreCompletenessDetailController);

    StoreCompletenessDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'StoreCompleteness'];

    function StoreCompletenessDetailController($scope, $rootScope, $stateParams, entity, StoreCompleteness) {
        var vm = this;
        vm.storeCompleteness = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:storeCompletenessUpdate', function(event, result) {
            vm.storeCompleteness = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
