(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('OutletPopPairVersionImportController', OutletPopPairVersionImportController);

    OutletPopPairVersionImportController.$inject = ['$scope', '$state', '$timeout', 'DataUtils', 'Upload', 'previousState'];

    function OutletPopPairVersionImportController($scope, $state, $timeout, DataUtils, Upload, previousState) {
        var vm = this;

        vm.previousState = previousState.name;
        vm.data = {}

        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.setData = setData;
        vm.upload = upload;

        function setData(file) {
            if (file) {
                vm.file = file;
                vm.isUploading = false;
                vm.isUploadFinished = false;

                vm.data.dataContentType = file.type;
                vm.data.fileName = file.name;
                vm.data.size = file.size;
            }
        }

        function upload(file) {
            vm.isUploading = true;

            Upload.upload({
                url: 'api/outlets/pop-pair-version-mapping/import',
                data: {file: file}
            }).then(function(response){
                $timeout(function(){
                    file.result = 'Success ' + response.config.data.file.name + 'uploaded. Response: ' + response.data;
                    vm.logs = false;
                });
            }, function(response){
                vm.logs = response.data;

                if (response.status > 0) {
                    file.error = response.status + ': ' + response.data;
                }
            }, function(evt){
                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                file.progress = 'Progress: ' + progressPercentage + '%';
            });

            vm.isUploadFinished = true;
        }
    }
})();