(function() {
    'use strict';
    angular
        .module('sceaApp')
        .factory('ApiKey', ApiKey);

    ApiKey.$inject = ['$resource', '$http'];

    function ApiKey ($resource, $http) {
        var resourceUrl =  'api/api-keys/:id';

        var res = $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });

        return {
            query: res.query,
            get: res.get,
            update: res.update,
            delete: res.delete,
            generateToken: function() {
                return $http.get('api/api-keys/token-generation');
            }
        };
    }
})();
