(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('OutletRetailMappingDialogController', OutletRetailMappingDialogController);

    OutletRetailMappingDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'AlertService', 'OutletRetailMapping', 'Channel', 'User', 'UserSearch'];

    function OutletRetailMappingDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, AlertService, OutletRetailMapping, Channel, User, UserSearch) {
        var vm = this;

        vm.clear = clear;
        vm.getUser = getUser;
        vm.save = save;

        vm.outletRetailMapping = entity;
        vm.channels = Channel.query();

        if (vm.outletRetailMapping.$promise) {
            vm.outletRetailMapping.$promise.then(function(data) {
                if(data.retailMappingSurveyorLogin != null) {
                    User.get({login: data.retailMappingSurveyorLogin}, function(surveyor) {
                        vm.surveyor = surveyor;   
                    });
                }
            });
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;

            if(vm.surveyor) {
                vm.outletRetailMapping.retailMappingSurveyorId = vm.surveyor.id;
                vm.outletRetailMapping.retailMappingSurveyorLogin = vm.surveyor.login;
            }

            if (vm.outletRetailMapping.id !== null) {
                OutletRetailMapping.update(vm.outletRetailMapping, onSaveSuccess, onSaveError);
            } else {
                OutletRetailMapping.save(vm.outletRetailMapping, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('sceaApp:outletRetailMappingUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function getUser(role, value) {
            vm.loadingUser = true;

            return UserSearch.query({
                roleName: role,
                query: value
            }).$promise.then(function(response){
                vm.loadingUser = false;
                return response;
            }, function(response){
                vm.loadingUser = false;
                AlertService.error(response.data.message);
            });
        }
    }
})();
