(function() {
    'use_strict';

    angular
        .module('sceaApp')
        .controller('EquipmentExportController', EquipmentExportController);

    EquipmentExportController.$inject = ['$scope', '$state', '$q', 'Calendar', 'ProductCategory', 'Equipment'];

    function EquipmentExportController($scope, $state, $q, Calendar, ProductCategory, Equipment) {
        var vm = this;

        vm.getWeeks = getWeeks;
        vm.exportAsCsv = exportAsCsv;

        (function (){

            vm.isExporting = false;

            // model input
            vm.exportRequest = {
                year: null,
                month: null,
                week: null
            }

            // options data for year
            Calendar.queryYears(function(data) {
                vm.years = data;
            });

            // options data for month
            Calendar.queryMonths(function(data) {
                vm.months = data;
            });

        })();

        // a method to get weeks option data
        function getWeeks(year, month){
            if(year && month){
                Calendar.queryWeeks({
                    year: year,
                    month: month
                }, function(data){
                    vm.weeks = data;
                });
            } else {
                vm.exportRequest.month = null;
                vm.exportRequest.week = null;
            }
        }

        // a mehtod to export data to csv
        function exportAsCsv(){
            vm.isExporting = true;
            Equipment.exportAsCsv(vm.exportRequest, onSuccess, onError);
        }

        function onSuccess(data, headers) {
            vm.isExporting = false;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }
    }
})();