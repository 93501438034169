(function() {
    'use strict';
    angular
        .module('sceaApp')
        .factory('ApplicationSetting', ApplicationSetting);

    ApplicationSetting.$inject = ['$resource'];

    function ApplicationSetting ($resource) {
        var resourceUrl =  'api/application-settings/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'queryByKey': {
            	url: 'api/application-settings/:key',
            	method: 'GET'
            }
        });
    }
})();
