(function() {
	'use strict';

	angular.module('sceaApp').controller('InternetQaDialogController',
			InternetQaDialogController);

	InternetQaDialogController.$inject = [ '$timeout', '$scope',
			'$stateParams', '$uibModalInstance', 'entity', 'InternetQa',
			'InternetAnswerSearch', 'InternetSearch' ];

	function InternetQaDialogController($timeout, $scope, $stateParams,
			$uibModalInstance, entity, InternetQa, InternetAnswerSearch,
			InternetSearch) {
		var vm = this;
		vm.internetQa = entity;
		vm.getInternet = getInternet
		vm.loadingInternet = false;
		vm.getAnswer = getAnswer
		vm.loadingAnswer = false;

		//        vm.internetanswers = InternetAnswer.query();

		$timeout(function() {
			angular.element('.form-group:eq(1)>input').focus();
		});

		vm.onSelectInternet = function(item, model, label) {
			vm.internetQa.internetId = model.id;
			vm.internetQa.internetInternetName = model.internetName;
		}

		vm.onSelectAnswer = function(item, model, label) {
			vm.internetQa.answerId = model.id;
			vm.internetQa.answerAnswerName = model.answerName;
		}

		var onSaveSuccess = function(result) {
			$scope.$emit('sceaApp:internetQaUpdate', result);
			$uibModalInstance.close(result);
			vm.isSaving = false;
		};

		var onSaveError = function() {
			vm.isSaving = false;
		};

		vm.save = function() {
			vm.isSaving = true;
			if (vm.internetQa.id !== null) {
				InternetQa.update(vm.internetQa, onSaveSuccess, onSaveError);
			} else {
				InternetQa.save(vm.internetQa, onSaveSuccess, onSaveError);
			}
		};

		vm.clear = function() {
			$uibModalInstance.dismiss('cancel');
		};

		function getInternet(value) {
			vm.loadingInternet = true;
			return InternetSearch.query({
				query : '*' + value + '*'
			}).$promise.then(function(response) {
				vm.loadingInternet = false;
				return response;
			}, function(response) {
				vm.loadingInternet = false;
				AlertService.error(response.data.message);
			});
		}

		function getAnswer(value) {
			vm.loadingAnswer = true;
			return InternetAnswerSearch.query({
				query : '*' + value + '*'
			}).$promise.then(function(response) {
				vm.loadingAnswer = false;
				return response;
			}, function(response) {
				vm.loadingAnswer = false;
				AlertService.error(response.data.message);
			});
		}
	}
})();
