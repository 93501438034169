(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('ProductCategoryDialogController', ProductCategoryDialogController);

    ProductCategoryDialogController.$inject = ['$timeout', '$q', '$scope', 'level', 'child', '$uibModalInstance', 'entity', 'ProductCategory', 'ProductCategorySearch', 'AlertService'];

    function ProductCategoryDialogController ($timeout, $q, $scope, level, child, $uibModalInstance, entity, ProductCategory, ProductCategorySearch, AlertService) {
        var vm = this;
        vm.productCategory = entity;
        vm.productcategories = [];
        vm.getProductCategory = getProductCategory;
        vm.loadingProductCategory = false;
        vm.parent = null;
        vm.level = level;
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sceaApp:productCategoryUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.$onInit = function () {

            // TODO set granchild if has child
            if (child) {
                child.forEach(function (data) {
                    data.child.$promise.then(function (tos) {
                        data.hasGrandChild = tos.length || 0
                    })
                });
            }


            if (vm.productCategory.$promise) {
                vm.productCategory.$promise.then(function(data) {
                    vm.parent = {
                        "id" : data.parentId,
                        "categoryName" : data.parentName
                    }
                }, function(error) {
                    console.log(error);
                });
            }

            function filterCurrentId(each) {
                return each.id != vm.productCategory.id
            }

            // TODO set data for available search. actually use getProductCategory method
            if (level) {
                if (level == 1) {
                    if (child.length == 0) {
                        var res = _getChild([1,2]).then(function (data) {
                            vm.productcategories = data.flat(2).filter(filterCurrentId);
                        });
                    }else if(child.length > 0){
                        var hasGrandChild = child.some(function (some) {
                            return some.hasGrandChild > 0
                        });
                        if (hasGrandChild) {
                            var res = _getChild([1]).then(function (data) {
                                vm.productcategories = data.flat(2).filter(filterCurrentId);
                            });
                        }
                    }
                }else if(level == 2){
                    if (child.length == 0) {
                        var res = _getChild([1,2]).then(function (data) {
                            vm.productcategories = data.flat(2).filter(filterCurrentId);
                        });
                    }else{
                        var res = _getChild([1]).then(function (data) {
                            vm.productcategories = data.flat(2).filter(filterCurrentId);
                        });
                    }
                }else {
                    var res = _getChild([1,2]).then(function (data) {
                        vm.productcategories = data.flat(2).filter(filterCurrentId);
                    });
                }
            }else {
                var res = _getChild([1,2]).then(function (data) {
                    vm.productcategories = data.flat(2).filter(filterCurrentId);
                });
            }
        }

        vm.save = function () {
            vm.isSaving = true;
            if(vm.parent){
            	vm.productCategory.parentId = vm.parent.id;
            }else{
            	vm.productCategory.parentId = null;
            }	

            if (vm.productCategory.id !== null) {
                ProductCategory.update(vm.productCategory, onSaveSuccess, onSaveError);
            } else {
                ProductCategory.save(vm.productCategory, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        
        function getProductCategory(value) {
        	vm.loadingProductCategory = true;
            return ProductCategorySearch.query({
            	query: '*'+value+'*'
            }).$promise.then(function(response){
            	vm.loadingProductCategory = false;
                return response;
            }, function(response){
            	vm.loadingProductCategory = false;
                AlertService.error(response.data.message);
            });
        };

        // private function

        /**
         * get child from api
         * @param {Array} level number for loop level
         * @returns Array list childs
         */
        function _getChild(level) {
            var childs = [];
            level.forEach(function (lv) {
                var data = ProductCategory.getAllProductCategories({level : lv}).$promise;
                childs = [].concat(childs, data);
            });
            return $q.all(childs)
        }
    }
})();
