(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('RetailMappingCategoryDetailController', RetailMappingCategoryDetailController);

    RetailMappingCategoryDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'RetailMappingCategory'];

    function RetailMappingCategoryDetailController($scope, $rootScope, $stateParams, entity, RetailMappingCategory) {
        var vm = this;
        vm.retailMappingCategory = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:retailMappingCategoryUpdate', function(event, result) {
            vm.retailMappingCategory = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
