(function() {
  'use strict';

  angular
      .module('sceaApp')
      .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('request-status-pop-report', {
      parent: 'report',
      url: '/report/request-status-pop-report',
      data: {
        authorities: ['ROLE_ADMIN'],
        pageTitle: 'sceaApp.requestStatusPopReport.home.title'
      },
      views: {
        'content@': {
          templateUrl: 'app/report/request-status-pop-report/request-status-pop-report.html',
          controller: 'RequestStatusPopReportController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,asc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('requestStatusPopReport');
          $translatePartialLoader.addPart('orderPop');
          $translatePartialLoader.addPart('requestStatus');
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    })
  }
})();
