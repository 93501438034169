(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('OrderPopDeleteController',OrderPopDeleteController);

    OrderPopDeleteController.$inject = ['$uibModalInstance', 'entity', 'OrderPop'];

    function OrderPopDeleteController($uibModalInstance, entity, OrderPop) {
        var vm = this;
        vm.orderPop = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            OrderPop.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
