(function() {
    'use strict';

    angular
        .module('sceaApp')
        .factory('LaunchPeriodSearch', LaunchPeriodSearch);

    LaunchPeriodSearch.$inject = ['$resource'];

    function LaunchPeriodSearch($resource) {
        var resourceUrl =  'api/_search/launch-periods/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
