(function () {
    'use strict';

    angular
        .module('sceaApp')
        .controller('PopPairVersionFormController', PopPairVersionFormController);

    PopPairVersionFormController.$inject = ['$scope', '$state', '$filter', 'AlertService', 'ParseLinks', 'pagingParams', 'paginationConstants', 'entity', 'PopPairVersion', 'ProductCategory', 'Product', 'ProductSearch', 'PopSearch'];

    function PopPairVersionFormController($scope, $state, $filter, AlertService, ParseLinks, pagingParams, paginationConstants, entity, PopPairVersion, ProductCategory, Product, ProductSearch, PopSearch) {
        var vm = this;

        vm.clearSearchProduct = clearSearchProduct;
        vm.getAllProduct = getAllProduct;
        vm.getPop = getPop;
        vm.loadPageProduct = loadPageProduct;
        vm.savePopPairVersion = savePopPairVersion;
        vm.searchProduct = searchProduct;
        vm.selectPop = selectPop;
        vm.selectProduct = selectProduct;
        vm.updateCategory = updateCategory;
        vm.updatePopList = updatePopList;

        (function () {
            vm.popPairVersion = entity;
            vm.page = 1;
            vm.predicate = pagingParams.predicate;
            vm.reverse = pagingParams.ascending;
            vm.searchProductQuery = pagingParams.searchProduct;
            vm.currentSearch = pagingParams.search;

            vm.disableEdit = (vm.popPairVersion.id) ? true : false;

            getProductCategory();
        })();

        function getProductCategory() {
            ProductCategory.getAllProductCategories({
                level: 2
            }, function (data) {
                vm.categories = data;
                vm.category = data[0];

                updateCategory(vm.category);
            });
        }

        function updateCategory(category) {
            ProductCategory.queryChild({
                id: category.id
            }, function (data) {
                vm.queryCategory = '(';

                angular.forEach(data, function (categoryChild, key) {
                    vm.queryCategory += 'productCategory.id:' + categoryChild.id;

                    if (key != data.length - 1) {
                        vm.queryCategory += ' OR ';
                    }
                });

                vm.queryCategory += ')';

                getAllProduct();
            });
        }

        function getAllProduct() {
            vm.product = null;

            if (vm.currentSearch) {
                var querySplit = vm.currentSearch.split(/-|\//);
                var queryProductCode = '';

                if (querySplit.length > 0) {
                    queryProductCode += '(';

                    angular.forEach(querySplit, function (eachSplit, index) {
                        queryProductCode += eachSplit;

                        if (index !== querySplit.length - 1) {
                            queryProductCode += ' AND ';
                        }
                    });

                    queryProductCode += '*)';
                } else {
                    queryProductCode = vm.currentSearch;
                }

                ProductSearch.query({
                    query: 'productCode:' + queryProductCode + ' '
                            + 'AND deleted:false AND ' + vm.queryCategory,
                    page: vm.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Product.query({
                    categoryParentId: vm.category.id,
                    page: vm.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.products = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPageProduct(page) {
            vm.page = page;
            vm.getAllProduct();
        }

        function searchProduct(searchQuery) {
            if (!searchQuery) {
                return vm.clearSearchProduct();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.getAllProduct();
        }

        function clearSearchProduct() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.searchProductQuery = null;
            vm.getAllProduct();
        }

        function selectProduct(product) {
            vm.product = product;

            if (vm.currentProduct !== vm.product) {
                getPops(product);
                vm.currentProduct = product;
            }
        }

        function getPops(product) {
            vm.isSaving = true;

            vm.pops = {
                table1: { title: 'normal', list: [] },
                table2: { title: 'conditional1', list: [] },
                table3: { title: 'conditional2', list: [] },
                table4: { title: 'conditional3', list: [] }
            }

            PopPairVersion.getProductPops({
                popPairVersionId: vm.popPairVersion.id,
                productId: product.id
            }, function (data) {
                angular.forEach(data, function (pop) {
                    if (pop.conditional1) {
                        vm.pops.table2.list.push(pop);
                    } else if (pop.conditional2) {
                        vm.pops.table3.list.push(pop);
                    } else if (pop.conditional3) {
                        vm.pops.table4.list.push(pop);
                    } else {
                        vm.pops.table1.list.push(pop);
                    }
                });

                for (var key in vm.pops) {
                    vm.pops[key].list = $filter('orderBy')(vm.pops[key].list, 'id', false);
                }

                vm.isSaving = false;
            }, function () {
                vm.isSaving = false;
            });
        }

        function getPop(value) {
            vm.loadingPop = true;

            var querySplit = value.split(/-|\//);
            var query = '';

            if (querySplit.length > 0) {
                query += '(';

                angular.forEach(querySplit, function (eachSplit, index) {
                    query += eachSplit;

                    if (index !== querySplit.length - 1) {
                        query += ' AND ';
                    }
                });

                query += '*)';
            } else {
                query = value;
            }

            return PopSearch.query({
                query: 'popName:' + query + ' AND deleted:false'
            }).$promise.then(function (response) {
                vm.loadingPop = false;

                var pops = [];
                var length = response.length;
                for (var i = 0; i < length; i++) {
                    if (response[i].id != 1 && response[i].id != 2) {
                        var unique = true;

                        for (var key in vm.pops) {
                            var filters = $filter('filter')(vm.pops[key].list, {
                                id: {
                                    pop: {
                                        id: response[i].id,
                                        popName: response[i].popName,
                                        productCategory: response[i].productCategory
                                    }
                                }
                            });

                            if (filters.length > 0) {
                                unique = false;
                            }
                        }

                        if (unique) {
                            pops.push(response[i]);
                        }
                    }
                }
                pops = $filter('orderBy')(pops, 'id');

                return pops;
            }, function (response) {
                vm.loadingPop = false;
                AlertService.error(response.data.message);
            });
        }

        function selectPop(pop) {
            vm.isSaving = true;
            vm.pop = null;

            var popPost = {
                conditional1: false,
                conditional2: false,
                conditional3: false,
                id: {
                    pop: pop
                }
            };
            var pops = vm.pops.table1.list;

            pops.push(popPost);

            PopPairVersion.updateProductPops({
                popPairVersionId: vm.popPairVersion.id,
                productId: vm.product.id
            }, pops, function (data) {
                vm.isSaving = false;
            }, function () {
                vm.isSaving = false;
            });
        }

        function updatePopList(tableContent) {
            vm.isSaving = true;

            var selectedPop = tableContent.list
                .find(function(pop) { return pop.moved === true; });
            
            var index = tableContent.list.indexOf(selectedPop);

            tableContent.list.splice(index, 1);

            var pops = [];

            for (var property in vm.pops) {
                angular.forEach(vm.pops[property].list, function (pop) {
                    pop.conditional1 = false;
                    pop.conditional2 = false;
                    pop.conditional3 = false;

                    if(pop[vm.pops[property].title] !== undefined) {
                        pop[vm.pops[property].title] = true;
                    }

                    pops.push(pop);
                });
            }

            PopPairVersion.updateProductPops({
                popPairVersionId: vm.popPairVersion.id,
                productId: vm.product.id
            }, pops, function (data) {
                vm.isSaving = false;
            }, function () {
                vm.isSaving = false;
            });
        }

        function savePopPairVersion() {
            vm.isSaving = true;
            if (vm.popPairVersion.id !== null) {
                PopPairVersion.update(vm.popPairVersion, onSaveSuccess, onSaveError);
            } else {
                PopPairVersion.save(vm.popPairVersion, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $state.go('pop-pair-version.edit', { id: result.id });
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
