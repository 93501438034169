(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('OutletRetailMappingDetailController', OutletRetailMappingDetailController);

    OutletRetailMappingDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'OutletRetailMapping', 'Channel', 'User'];

    function OutletRetailMappingDetailController($scope, $rootScope, $stateParams, previousState, entity, OutletRetailMapping, Channel, User) {
        var vm = this;

        vm.outletRetailMapping = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('sceaApp:outletRetailMappingUpdate', function(event, result) {
            vm.outletRetailMapping = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
