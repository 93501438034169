(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('MetabaseDashboardController', MetabaseDashboardController);

    MetabaseDashboardController.$inject = ['DashboardSetting', '$sce', 'AlertService', 'currentUser', 'pagingParams'];

    function MetabaseDashboardController (DashboardSetting, $sce, AlertService, currentUser, pagingParams) {
        var vm = this;

        vm.account = currentUser;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.isAdmin = vm.account.authorities.includes('ROLE_ADMIN');

        vm.loadAll = loadAll;
        vm.selectDashboard = selectDashboard;
        vm.isLoading = true;

        vm.loadAll();

        function loadAll () {
            DashboardSetting.metabaseEmbeddedUrl({
                page: 0,
                size: 100,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data) {
                vm.isLoading = false;
                vm.metabaseResources = data;

                vm.metabaseResources.map(function(d){
                    var saveUrl = {
                        safeUrl: $sce.trustAsResourceUrl(metabaseParameter(d.metabaseEmbeddedUrl))
                    };

                    return Object.assign(d, saveUrl);
                });
                vm.selectedDashboard = vm.metabaseResources[0];
                vm.selectedUrl = vm.selectedDashboard.safeUrl;
            }
            function onError(error) {
                vm.isLoading = false;
                AlertService.error(error.data.message);
            }
        }

        function selectDashboard(dashboardResource) {
            vm.selectedDashboard = dashboardResource;
            vm.selectedUrl = vm.selectedDashboard.safeUrl;
        }

        function metabaseParameter(url) {
            if (vm.isAdmin) {
                return url;
            }

            return url + '?user_login=' + vm.account.login + '#hide_parameters=user_login';
        }
    }
})();
