(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('RetailMappingPhotoCategoryDetailController', RetailMappingPhotoCategoryDetailController);

    RetailMappingPhotoCategoryDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'RetailMappingPhotoCategory'];

    function RetailMappingPhotoCategoryDetailController($scope, $rootScope, $stateParams, entity, RetailMappingPhotoCategory) {
        var vm = this;
        vm.retailMappingPhotoCategory = entity;
        
        var unsubscribe = $rootScope.$on('sceaApp:retailMappingPhotoCategoryUpdate', function(event, result) {
            vm.retailMappingPhotoCategory = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
