(function() {
    'use strict';

    angular
        .module('sceaApp')
        .controller('TaskDetailController', TaskDetailController);

    TaskDetailController.$inject = ['$scope', '$state', '$stateParams', '$timeout', '$filter', 'entity',
    	'AlertService', 'Attachment', 'DataUtils', 'DynamicForm', 'Form', 'History', 'Outlet', 'OutletSearch', 'Principal', 'ProcessInstance', 'Project', 'ProjectColumn', 'SceaProcessInstance', 'Task'];

    function TaskDetailController ($scope, $state, $stateParams, $timeout, $filter, entity,
    	AlertService, Attachment, DataUtils, DynamicForm, Form, History, Outlet, OutletSearch, Principal, ProcessInstance, Project, ProjectColumn, SceaProcessInstance, Task) {
    	
        var vm = this;
        vm.task = entity;
        vm.taskDetailed = {};
        vm.historicTasks = {};
        vm.form = [];
        vm.formDataHistoric = [];
        
        vm.taskId = $stateParams.id;
        vm.statusForm = $stateParams.status;
        
        vm.isHistoric = false;
        vm.isApprove = false;

        vm.tasks = [];
        vm.size = 100;

        vm.getOutlet = getOutlet;
        vm.init = init;
        vm.init();
        
        var idAttachmentFiles = [];
        var idALongVariables = [];        
        var indexBusinessUnit = 0;
        var indexOutlet = 0;
		
        function getAccount() {
            Principal.identity().then(function(account) {
            	for(var i=0; i<account.authorities.length; i++) {
            		if(account.authorities[i] != 'ROLE_ADMIN') {
            			vm.role = account.authorities[i];
            			if(vm.role == 'VDO') {
            	        	SceaProcessInstance.getProcessInstance(function(result) {
            	        		if(result.length > 0) {
                    	        	vm.task.$promise.then(function(task){
                    	        		var flag = false;
                    	        		angular.forEach(result.data, function(data, key) {
                    	        			if(task.processInstanceId == data.processInstanceId) {
                    	        				flag = true;
                    	        			}
                    	        			
                    	        			if(result.data.length-1 == key) {
                    	        				if(!flag) {
                    	        	                $state.go('task');                	        					
                    	        				}
                    	        			}
                    	        		});
                    	        	});            	        			
            	        		} else {
    	        	                $state.go('task');             	        			
            	        		}
            	        	});
            			} else {
            				init();
            			}
            			break;
            		}
            	}
            });
        };
        getAccount();
        
        function init() {
        	if(vm.statusForm == 'edit') {
                userValidation();
                
        		Task.get({
            		includeProcessVariables: true,
        			taskId: $stateParams.id
        		}, onSuccess, onError);
        	} else if(vm.statusForm == 'detail') {
        		Task.get({
            		includeProcessVariables: true,
        			taskId: $stateParams.id
        		}, onSuccess, onError);
        	} else if(vm.statusForm == 'historic') {
        		vm.isHistoric = true;
        		
        		History.getHistoricTask({
            		includeProcessVariables: true,
            		taskId: vm.taskId
            	}, onSuccess, onError);
        	} else {
                $state.go('task-detail', { id: vm.taskId, status: 'detail' });        		
        	}
        	
        	function onSuccess(result) {
            	if(vm.statusForm == 'historic') {
                	vm.task = result.data[0];
                	vm.getTasksList();
                	
                	for(var i=0; i<vm.task.variables.length; i++) {
                		if(vm.task.variables[i].name == 'project') {
                			getProjectColumn(vm.task.variables[i].id);                			
                			break;
                		}
                	}
            	} else {
            		vm.task = result;
            		getFormRunningTask();
            		vm.getTasksList();
            		
            		Task.getVariable({
            			taskId: vm.taskId, 
            			variableName: 'project'
            		}, function(result) {            			
            			var strings = result.value;
    					var json = JSON.stringify(eval("(" + strings + ")"));
    					var obj = JSON.parse(json);
    					
                    	getProjectColumn(obj.id);
            		});
            	}
        	}

            function onError(error) {
                AlertService.error(error.data.message);                	
            }
        }
        
        vm.getTasksList = function() {        	
    		History.getHistoricTask({
    			processInstanceId: vm.task.processInstanceId,
        		includeProcessVariables: true,
        		sort: 'startTime',
        		order: 'desc',
    			size: vm.size
    		}, function(result) {
                vm.tasks = result;
    		}, function() {
                AlertService.error(error.data.message);
    		});
        }
        
        function getProjectColumn(id) {
   			ProjectColumn.get({
   				projectId: id
  			}, function(result) {
  				angular.forEach(result, function(variable, key) {
		    		vm.form[variable.columnId] = {};
		 			vm.form[variable.columnId].name = variable.columnName;
		    		vm.form[variable.columnId].alias = variable.columnAlias;
		    		vm.form[variable.columnId].type = variable.columnType;

	    			if(result.length-1 == key) {
	    				getHistoricTasks();
	    				
	    				if(vm.statusForm == 'historic') {
		    				getFormHistoricTask();	    					
	    				}
	    			}
	  			});
	  		});
        }
        
        function getFormRunningTask() {
            vm.businessUnit = DynamicForm.getBusinessUnit();
            vm.loadingOutlet = false;
            vm.attachment = [];
            
            Form.get({
           		taskId: vm.taskId
           	}, onSuccess, onError);

            function onSuccess(result) {
           		vm.taskDetailed = result;
           		
           		generateFormValue(vm.taskDetailed);
            }

            function onError(error) {
                AlertService.error(error.data.message);                	
            }
        }
    	
    	function getFormHistoricTask() {
    		History.getHistoricDetail({
        		taskId: vm.taskId
        	}, function(result) {
            	vm.taskDetailed.formProperties = [];
            	
            	angular.forEach(result.data, function(detail, key) {
            		var obj = {};
            		obj.type = vm.form[detail.propertyId].type;
            		obj.name = vm.form[detail.propertyId].name;
            		obj.alias = vm.form[detail.propertyId].alias;
            		obj.value = detail.propertyValue;
            		obj.writable = false;
            		
            		vm.taskDetailed.formProperties.push(obj);
					
					if(result.data.length-1 == key) {
		           		generateFormValue(vm.taskDetailed);
					}
            	});
        	});                		
    	}
    	
    	function getHistoricTasks() {
        	History.getHistoricTask({
        		includeProcessVariables: true,
        		processInstanceId: vm.task.processInstanceId,
        		taskDeleteReasonLike: 'completed',
        		sort: 'startTime',
        		order: 'desc',
        		size: vm.size
        	}, function(result) {
        		vm.historicTasks = result;
        		
        		angular.forEach(result.data, function(historicTask, keyData) {
            		vm.historicTasks.data[keyData].formProperties = [];
                	
            		History.getHistoricDetail({
                		taskId: historicTask.id
                	}, function(result) {
                		if(result.data.length > 0) {
                        	angular.forEach(result.data, function(detail, key) {
                        		var obj = {};
                        		obj.type = vm.form[detail.propertyId].type;
                        		obj.name = vm.form[detail.propertyId].name;
                        		obj.alias = vm.form[detail.propertyId].alias;
                        		obj.value = detail.propertyValue;
                        		obj.writable = false;
                        		
                        		vm.historicTasks.data[keyData].formProperties.push(obj);
                        		
                        		if(result.data.length-1 == key) {
                	           		generateFormValue(vm.historicTasks.data[keyData]);
                        		}
                        	});
                		}  		
                	});

            		if(result.data.length-1 == keyData) {
            			History.get({
            				processInstanceId: historicTask.processInstanceId
            			}, function(result) {
            				vm.historicTasks.data.push(result);
            				
            				vm.historicTasks.data[vm.historicTasks.data.length-1].category = 'Proposal-VDO';
            				vm.historicTasks.data[vm.historicTasks.data.length-1].deleteReason = 'completed';
            				vm.historicTasks.data[vm.historicTasks.data.length-1].endTime = result.startTime;
                    		vm.historicTasks.data[vm.historicTasks.data.length-1].formProperties = [];
            				
                    		History.getHistoricDetail({
                    			processInstanceId: historicTask.processInstanceId
                        	}, function(result) {
                            	angular.forEach(result.data, function(detail, key) {
                            		var obj = {};
                            		obj.type = vm.form[detail.propertyId].type;
                            		obj.name = vm.form[detail.propertyId].name;
                            		obj.alias = vm.form[detail.propertyId].alias;
                            		obj.value = detail.propertyValue;
                            		obj.writable = false;
                            		
                            		vm.historicTasks.data[vm.historicTasks.data.length-1].formProperties.push(obj);
                            		
                            		if(result.data.length-1 == key) {
                    	           		generateFormValue(vm.historicTasks.data[vm.historicTasks.data.length-1]);
                            		}
                            	});
                        	});

            			});
            		}
        		});
        	});
    	}

        function generateFormValue(objParams) {        	
        	angular.forEach(objParams.formProperties, function(variable, keyVariable) {
        		if(variable.name == 'Approval') {
        	        vm.isApprove = true;
        	        if(variable.value == 'approve') {
            			objParams.approved = 'Approved';
            		} else {
            			objParams.approved = 'Rejected';            			
            		}
        		}
        		if(variable.type == 'boolean') {
        			if(variable.value == null) {
            			objParams.formProperties[keyVariable].value = false;
        			} else {
            			objParams.formProperties[keyVariable].value = (variable.value === 'true');        				
        			}
        		}
        		if(variable.type == 'long' || variable.type == 'double') {
        			objParams.formProperties[keyVariable].value = parseInt(variable.value);
        			idALongVariables.push(keyVariable);
        			if(isNaN(objParams.formProperties[keyVariable].value)) {
        				objParams.formProperties[keyVariable].value = null;
        			}
        		}
        		if(variable.type == 'date' && variable.value != null) {
        			variable.value = new Date(variable.value);
					objParams.formProperties[keyVariable].value = $filter('date')(variable.value, 'medium');	
        		}
        		if(variable.type == 'scea_project') {
        			if(objParams.formProperties[keyVariable].value != null) {
            			Project.get({id: objParams.formProperties[keyVariable].value}, function(result) {
            				objParams.formProperties[keyVariable].value = result.projectName;
            			});
            		}
        		}
        		if(variable.type == 'scea_file') {
        			if(variable.writable) {
            			idAttachmentFiles.push(keyVariable);
            			vm.attachment[keyVariable] = {};            				
        			}
        			if(objParams.formProperties[keyVariable].value != null) {
        				Attachment.get({id: objParams.formProperties[keyVariable].value}, function(result) {
        					objParams.formProperties[keyVariable].value = result;
        				});
        			} 
        		}
        		if(variable.type == 'scea_business_unit') {
        			indexBusinessUnit = keyVariable;
					objParams.formProperties[keyVariable].idValue = variable.value;     
        			DynamicForm.getABusinessUnit({id: variable.value}, function(result) {  
    					objParams.formProperties[keyVariable].value = result.name;        				
        			})
        		}
        		if(variable.type == 'scea_outlet') {
        			indexOutlet = keyVariable;
        			if(objParams.formProperties[keyVariable].value != null) {
    					objParams.formProperties[keyVariable].idValue = parseInt(variable.value);  
            			Outlet.get({id: objParams.formProperties[keyVariable].value}, function(result) {
            				objParams.formProperties[keyVariable].value = result.outletName;
            			});
            		}
        		}        		
        		if(vm.statusForm == 'detail') {
        			objParams.formProperties[keyVariable].writable = false;
        		}
        	});
        }
        
        function userValidation() {
        	Task.get({taskId: $stateParams.id}, function(result) {
            	var category = result.category;
				var strings = category.split('-');
				var role = strings[1];
	    		
	            Principal.identity().then(function(account) {
	            	var isValid = false;
	            	for(var i=0; i<account.authorities.length; i++) {
	            		if(account.authorities[i] == role) {
	            			isValid = true;
	            			getFormRunningTask();
	            			break;
	            		}
	            		if(account.authorities.length-1 == i && isValid == false){
	                        $state.go('task-detail', { id: vm.taskId, status: 'detail' });
	            		}
	            	}
	            });
        	});
        }
        
        function getOutlet(value) {
        	vm.loadingOutlet = true;
            return OutletSearch.query({
                query: '*'+value+'*'
            }).$promise.then(function(response){
            	vm.loadingOutlet = false;
                return response;
            }, function(response){
            	vm.loadingOutlet = false;
                AlertService.error(response.data.message);
            });
        }
        
        vm.closeProject = function() {
        	ProcessInstance.deleteProcessInstance({
        		processInstanceId: vm.task.processInstanceId
        	}, function(result) {
                $state.go('task');
        	});
        }
        
        vm.setContent = function ($file, attachment) {
            if($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        attachment.content = base64Data;
                        attachment.name = $file.name;
                        attachment.size = $file.size;
                        attachment.contentContentType = $file.type;
                    });
                });
            }
        };

        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;
        
        vm.openDatePicker = function(obj) {
            obj.opened = true;
        };
        
        vm.download = function(file) {
        	if(file.id != null) {
        		Attachment.download({id: file.id});
        	}
        }
       
        vm.calculate = function(index) {
        	var obj = {};
        	if(vm.taskDetailed.formProperties[indexBusinessUnit].value != null) {
            	obj.bizUnitId = parseInt(vm.taskDetailed.formProperties[indexBusinessUnit].idValue);
            	
            	if(vm.taskDetailed.formProperties[indexOutlet].value != null) {
            		obj.outletId = vm.taskDetailed.formProperties[indexOutlet].idValue;
            		
                	obj.fieldName = vm.taskDetailed.formProperties[index].id;
                	obj.variables = {};

                	angular.forEach(idALongVariables, function(idALongVariable, key) {
                		var property = vm.taskDetailed.formProperties[idALongVariable].id;
                		var value = vm.taskDetailed.formProperties[idALongVariable].value;
                		
                		obj.variables[property] = value;

                		if(idALongVariables.length-1 == key) {
                			DynamicForm.calculateFormula(obj, function(result) {
                        		vm.taskDetailed.formProperties[index].value = result.value;
                        	});
                		}
                	});
            	} else {
                    AlertService.error('Store is empty');
            	}
        	} else {
                AlertService.error('Business Unit is empty');
        	}
        }
        
        vm.save = function(params) {
        	vm.isSaving = true;
        	
        	if(idAttachmentFiles.length == 0) {
    			submitTask(params);        		
        	} else {
	        	angular.forEach(idAttachmentFiles, function(idAttachmentFile, key) {        		
	        		if(vm.attachment[idAttachmentFile].content != null) {
			        	var obj = {};
			        	obj.content = vm.attachment[idAttachmentFile].content;
			        	obj.fileName = vm.attachment[idAttachmentFile].name;
			        	obj.contentContentType = vm.attachment[idAttachmentFile].contentContentType;
			        	obj.creationDate = new Date();
			        	obj.size = vm.attachment[idAttachmentFile].size;
			        	
			        	Attachment.upload(obj, function(result) {
			        		setAttachmentFile(result, key);
			        	});
	        		}
	        	});
        	}
        	
        	function setAttachmentFile(obj, key) {
        		var id = idAttachmentFiles[key];
				vm.taskDetailed.formProperties[id].value = obj;
				
				if(idAttachmentFiles.length-1 == key) {
        			submitTask(params);
        		}
        	}
        };
        
        var submitTask = function(params) {
            var obj = {};
            obj.variables = [];
            
            angular.forEach(vm.taskDetailed.formProperties, function(variable, key) {
        		if(variable.writable == true){
                var property = {}
                property = variable;

        		if(variable.type == 'scea_outlet' && variable.value != null) {
        			property.value = variable.value.id;
        		}
        		if(variable.type == 'date' && variable.value != null) {
        			property.value = $filter('date')(variable.value, variable.datePattern);
        		}
        		if(variable.type == 'scea_file' && variable.value == null) {
        			var file = vm.attachment[key];

		        	var files = {};
		        	files.content = file.content;
		        	files.fileName = file.name;
		        	files.contentContentType = file.contentContentType;
		        	files.creationDate = new Date();
		        	files.size = file.size;
		        	
		        	Attachment.upload(files, function(result) {
	        			property.value = result.id;
		        	});
        		}
        		if(variable.type == 'scea_file' && variable.value != null) {
        			property.value = variable.value.id;
        		}
        		if(variable.name == 'Approval') {
        			property.value = params;
        		}
        		
        		obj.variables.push(property);
        		}
        		if(vm.taskDetailed.formProperties.length-1 == key) {
        			generateWritable(obj.variables);
        		}
        	});
            
        	function generateWritable(variables) {
        		var obj = {};
        		
        		obj.taskId = vm.taskId;
        		obj.properties = [];

            	angular.forEach(variables, function(variable, key) {
            		if(variable.writable == true){
            			obj.properties.push(variable);
            		}
            		
            		if(variables.length-1 == key) {
            			Form.save(obj, onSaveSuccess, onSaveError);
            		}
            	});
        	}
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('sceaApp:taskUpdate', result);
            vm.isSaving = false;
            $state.go('task', null, { reload: true });
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };
		
		vm.splitCategory = function(input) {
			if(input == null) return '';
			else {
				var string = input.split('-');
				return string[0];
			}
		};
		
		vm.splitCandidate = function(input) {
			if(input == null) return '';
			else {
				var string = input.split('-');
				return string[1];
			}
		};
		
		vm.getVariableValue = function(obj, params) {
			var result = '';
			angular.forEach(obj, function(value, key) {
				if(params == value.name) {
					if(params == 'project') {
						var businessUnit = JSON.parse(value.value);
						result = businessUnit.projectName;
					} else if(params == 'business_unit') {
						var businessUnit = JSON.parse(value.value);
						result = businessUnit.name;
					} else if(params == 'store') {
						var store = JSON.parse(value.value);
						result = store.outletName;
					} else {
						result = value.value;
					}
				}
			});
			return result;
		};
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });
    }
})();
