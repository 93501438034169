(function() {
    'use strict';

    angular
        .module('sceaApp')
        .filter('percentage', percentage);

    percentage.$inject = ['$filter'];

    function percentage($filter) {
        return percentageFilter;

        function percentageFilter (input, decimals) {
            return $filter('number')(input * 100, decimals) + '%';
        }
    }
})();